import { GET_TESTIMONIALS} from "config/services";
import axios from "utils/axios";

class TestimonialsService {
  
  testimonials = (id) =>
    new Promise((resolve, reject) => {
      axios
        .get( `${GET_TESTIMONIALS}`)
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error?.response?.payload);
        });
    });

  
}

const testimonialsService = new TestimonialsService();

export default testimonialsService;
