import { SEARCH_URL } from "config/services";
import axios from "utils/axios";

class SearchService {
  // search = (searchKeyWord) =>
  //   new Promise((resolve, reject) => {
  //     axios
  //       .get(SEARCH_URL, {searchKeyWord})
  //       .then((response) => {
  //         resolve(response.data);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         reject(error?.response?.payload);
  //       });
  //   });

  search = (searchKeyWord, from, to, filterStr, startPage) =>
    new Promise((resolve, reject) => {
      // console.log("searchService:search", searchKeyWord, filterStr, startPage);

      let searchUrl = `${SEARCH_URL}?q=${searchKeyWord}`;

      
      if (from !== undefined && to !== undefined) {
      searchUrl += `&price_range={"from":${from},"to":${to}}`;
    }

      if (filterStr) {
        searchUrl += `&filterParam=${filterStr}`;
      }
      if (startPage) {
        const startNumber = (startPage - 1) * 60;
        searchUrl += `&start=${startNumber}`;
      }

      axios
        .get(searchUrl, { searchKeyWord })
        .then((response) => {
          // console.log("searchService:search: Response", response.data);

          if (response?.data?.error) {
            console.log("searchService:search:", response?.error, response?.data);
            reject(response?.data?.message);
          } else {
            resolve(response?.data?.data);
          }
        })
        .catch((error) => {
          if (error?.response) {
            reject(error?.response?.message);
          }

          reject(error);
        });
    });
}

const searchService = new SearchService();

export default searchService;
