import React, { useEffect, useState } from "react";
import {
  CardContent,
  CardMedia,
  Grid,
  Card,
  Typography,
  Button,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { deleteAddressAction } from "actions/addressAction";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function AddressInfo({ address }) {
  const [addresses, setAddresses] = useState(null);
  const dispatch = useDispatch();
  const addressIndex = address?.addressIndex;
  const deleteAddress = () => {
    dispatch(deleteAddressAction(addressIndex));
  };

  const location = useLocation();
  const isprofilePage = location.pathname === "/profile";

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (index) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    
  };
  useEffect(() => {}, [address]);
  return (
    <Card sx={{width:300, mb:2}}>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography variant="h6">{address?.addressType}</Typography>
            <Typography>{address?.addressLine1}</Typography>
            <Typography>{address?.addressLine2}</Typography>
            <Typography>
              {address?.city}, {address?.state}
            </Typography>
            <Typography>
              {address?.country}, {address?.pincode}
            </Typography>
          </Box>
          <Box>
            {isprofilePage && (
              <IconButton aria-label="delete" onClick={handleClickOpen}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this Address
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" onClick={handleClose}>
            No
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={deleteAddress}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      </CardContent>
    </Card>
  );
}

export default AddressInfo;
