import React, { useState } from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { Typography, TextField, Grid, Button, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { addReviewForProduct } from "../../actions/AddReviewAction";
import { IMAGE_BASE_URL } from "config";
import ImgUploader from './ImgUploader';
const ProductReview = ({ orderId, productId, productDetails, handleClose}) => {
  const [rating, setRating] = useState(2);
  const [selectedImages, setSelectedImages] = useState([]);
  const [review, setReview] = useState("");
  const dispatch = useDispatch();
  // const imageURL = selectedImages?.map((imgs) => {
  //   return URL.createObjectURL(imgs);
  // });


  // const [image, setImage] = useState([]);

  const formData = new FormData();
formData.append('rating', rating);
formData.append('review', review);
formData.append('orderId', orderId);
formData.append('productId', productId);
// formData.append('imageURL', selectedImages[0]);


selectedImages.forEach((image, index) => {
  formData.append(`imageURL`, image);
});


  const sendReviewForProduct = () => {
    dispatch(addReviewForProduct(formData ));
    handleClose();
    // console.log("rating", rating,review, orderId, productId,  );
  };

  console.log("selectedImages", selectedImages)
  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12} md={12}>
        <Typography component="legend" variant="h4" sx={{ mt: 2, mb: 2 }}>
          Order Review
        </Typography>
        <Divider />

        {productDetails.map((list) => (
          <Grid container sx={{p:2}}> 
            <Grid item md={4}>
              <Box sx={{ display: "flex", alignItems: "top" }}>
                <img src={IMAGE_BASE_URL + "/" + list.images} width="80%" />
              </Box>
            </Grid>
            <Grid item md={8}>
              <Box sx={{ display: "flex", alignItems: "top" }}>
             <Typography variant="h5">{list.productName}</Typography>
              </Box>
            </Grid>
          </Grid>
        ))}

        <Typography>How would you rate it?</Typography>
        <Rating
          sx={{ mt: 2 }}
          name="simple-controlled"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <TextField
          fullWidth
          id="outlined-multiline-static"
          label="Please Write ur feedback"
          onChange={(e) => setReview(e.target.value)}
          multiline
          rows={4}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
      >
        <ImgUploader   selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}/>
      </Grid>

      <Grid item xs={12} md={12}>
        <Button variant="contained" fullWidth onClick={sendReviewForProduct}>
          {" "}
          Submit
        </Button>
      </Grid>

      
    </Grid>
  );
};

export default ProductReview;
