import React, { useState } from "react";
import { Grid, Button, Card, TextField, Typography, Box } from "@mui/material";
import Container from "@mui/material/Container";
import { Formik } from "formik";
import * as Yup from "yup";
import { CheckBox } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

const AddressForm = ({ ...rest }) => {

  const address = useSelector((state) => state.address.address);
  const [showAllAddress, stShowAllAddress ] = useState(1)

  return (
    <Container maxWidth="lg" sx={{ p: 5 }}>
    
    <Card sx={{ p: 2 }} cardTitle="Address :">
    <Box sx={{mb:2}}>
    <Typography>Address :</Typography>
    </Box>
            <Formik
              initialValues={{
                name: "",
                mobile: "",
                email: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                country: "",
                pinCode: "",
                addressType: "",
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().max(80).required("Please enter Name"),

                mobile: Yup.string()
                  .max(10)
                  .required("Please enter mobile number"),

                email: Yup.string()
                  .email()
                  .required("Please enter mobile number"),

                pinCode: Yup.string().max(80).required("Please enter pin code"),

                locality: Yup.string()
                  .max(300)
                  .required("Please enter locality"),

                addressLine1: Yup.string()
                  .max(300)
                  .required("Please enter address"),

                addressLine2: Yup.string()
                  .max(300)
                  .required("Please enter address"),

                city: Yup.string().max(300).required("Please enter city"),

                state: Yup.string().max(300).required("Please enter state"),

                country: Yup.string().max(300).required("Please enter country"),

                addressType: Yup.string()
                  .max(300)
                  .required("Please enter addressType"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  // await dispatch(addAddressAction(values));
                } catch (error) {
                  // console.log("Form: error", error);

                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit} {...rest}>
                  {address.map((list) => (
                    <>
                      {list?.addresses.slice(0, `${showAllAddress}`).map((addressList) => (
                        <Grid container spacing={2} sx={{mb:4}}>
                          <Grid item xs={12}>
                            <TextField
                              size="small"
                              name="name"
                              id="name"
                              label="Name"
                              type="name"
                              value={addressList.name}
                              variant="outlined"
                              fullWidth
                              onBlur={handleBlur}
                              onChange={handleChange}
                              inputProps={{ maxLength: 80 }}
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                            size="small"
                              name="mobile"
                              id="mobile"
                              label="Mobile"
                              type="mobile"
                              value={addressList.mobile}
                              variant="outlined"
                              fullWidth
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(touched.mobile && errors.mobile)}
                              helperText={touched.mobile && errors.mobile}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                            size="small"
                              name="email"
                              id="email"
                              label="email"
                              type="email"
                              value={addressList.email}
                              variant="outlined"
                              fullWidth
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                            size="small"
                              name="addressLine1"
                              id="addressLine1"
                              label="Address Line 1"
                              type="addressLine1"
                              value={addressList.addressLine1}
                              variant="outlined"
                              fullWidth
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(
                                touched.addressLine1 && errors.addressLine1
                              )}
                              helperText={
                                touched.addressLine1 && errors.addressLine1
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                            size="small"
                              name="addressLine2"
                              id="addressLine2"
                              label="Address Line 2"
                              type="addressLine2"
                              value={addressList.addressLine2}
                              variant="outlined"
                              fullWidth
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(
                                touched.addressLine2 && errors.addressLine2
                              )}
                              helperText={
                                touched.addressLine2 && errors.addressLine2
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                            size="small"
                              name="city"
                              id="city"
                              label="City"
                              type="city"
                              value={addressList.city}
                              variant="outlined"
                              fullWidth
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(touched.city && errors.city)}
                              helperText={touched.city && errors.city}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                            size="small"
                              name="state"
                              id="state"
                              label="State"
                              type="state"
                              value={addressList.state}
                              variant="outlined"
                              fullWidth
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(touched.state && errors.state)}
                              helperText={touched.state && errors.state}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                            size="small"
                              name="country"
                              id="country"
                              label="Country"
                              type="country"
                              value={addressList.country}
                              variant="outlined"
                              fullWidth
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(touched.country && errors.country)}
                              helperText={touched.country && errors.country}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                            size="small"
                              name="pinCode"
                              id="pinCode"
                              label="Pin Code"
                              type="pinCode"
                              value={addressList.pinCode}
                              variant="outlined"
                              fullWidth
                              onBlur={handleBlur}
                              onChange={handleChange}
                              inputProps={{ maxLength: 80 }}
                              error={Boolean(touched.pinCode && errors.pinCode)}
                              helperText={touched.pinCode && errors.pinCode}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                            size="small"
                              name="addressType"
                              id="addressType"
                              label="Address Type"
                              type="addressType"
                              value={addressList.addressType}
                              variant="outlined"
                              fullWidth
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(
                                touched.addressType && errors.addressType
                              )}
                              helperText={
                                touched.addressType && errors.addressType
                              }
                            />
                          </Grid>

                          <Grid
                            item
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            {/* <Button
                              color="secondary"
                              disabled={isSubmitting}
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              Save
                            </Button> */}
                          </Grid>
                        </Grid>
                        
                      ))}
                    </>
                  ))}
                </form>
              )}
            </Formik>
          </Card>
    
    </Container>
  );
};

export default AddressForm;
