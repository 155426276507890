import React, { useState } from "react";


import NotificationDrawer from "./NotificationDrawer";
const Notifications = () => {
  return (
    <>
      <NotificationDrawer />
    </>
  );
};

export default Notifications;
