import React, { useEffect, useState } from "react";

import { Dashboard, Main as Main } from "layouts";
import { Grid, Card, Box, Typography, Button, Divider } from "@mui/material";
// import { getMyOrderDetailsAction } from "actions/orderAction";
import { useDispatch, useSelector } from "react-redux";
import { GET_ORDER_DETAILS } from "config/services";
import Container from "@mui/material/Container";
import axios from "utils/axios";
import { moneyFormatter } from "../../utils/formatUtils";
import { DataGrid } from "@mui/x-data-grid";
import { dateFormatter } from "utils/formatUtils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

function OrderDetails() {
  const navigate = useNavigate();
  const myAllOrders = useSelector((state) => state?.order?.myOrders);
  const invoiceDetails = useSelector((state) => state.invoice.invoicesList);

  // const myAllOrdersGridData = useSelector((state) => state?.order?.myOrders);
  // console.log("myAllOrdersGridData", myAllOrdersGridData)

  let params = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    fetchOrderDetail(params.invoiceId);
    // fetchOrderDetail("1102");
  }, []);

  useEffect(() => {}, [myAllOrders]);

  const [orderDetails, setOrderDetails] = useState("");
  const [productDetails, setProductDetails] = useState("");

  function fetchOrderDetail(id) {
    axios.get(`${GET_ORDER_DETAILS}/${id}`).then((response) => {
      if (response?.data?.data) {
        setOrderDetails(response.data.data);
        setProductDetails(response.data.data.items);
      } else {
        setOrderDetails([]);
      }
    });
  }

  const columns = [
    { field: "_id", headerName: "Order Id", width: 90 },
    {
      field: "productName",
      headerName: "Product Name",
      width: 150,
      editable: true,
    },
    {
      field: "productSKU",
      headerName: "Product SKU",
      width: 150,
      editable: true,
    },
    {
      field: "price",
      headerName: "price",
      width: 150,
      editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
      editable: true,
    },
    {
      field: "total",
      headerName: "Total",
      width: 150,
      editable: true,
    },
  ];

  const openPDF = () => {
    
    navigate(`/invoice-pdf`);
  };
  return (
    <Dashboard>
      {invoiceDetails.map((items, index) => (
        <Container
          maxWidth="md"
          className="center-modal"
          sx={{
            mb: 5,
            mt: 10,
            flexDirection: "column",
            backgroundColor: "#f5f8fc",
          }}
        >
        <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{ display: "flex", justifyContent: "end" }}
      >
        <Button variant="outlined" onClick={openPDF}>
          Download PDF
        </Button>
      </Grid>
          <Grid container spacing={4} sx={{ p: 3, mt: 0 }}>
            <Grid item xs={12} sm={12} md={7}>
              <Card sx={{ mb: 2, p: 4 }} >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="subtitle1">INVOICE :{index + 1}</Typography>
                    <Divider/>
                  </Grid>
                  
                  <Grid item xs={6} md={4}>
                    <Typography variant="subtitle2">Name :</Typography>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Typography>
                      {items?.billTo?.address?.name}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <Typography variant="subtitle2">Bill To :</Typography>
                  </Grid>

                  <Grid item xs={6} md={8}>
                    <Typography>
                      {items?.billTo?.address?.addressLine1} {", "}
                      {items?.billTo?.address?.addressLine2} {", "}
                      {items?.billTo?.address?.city} {", "}
                      {items?.billTo?.address?.country} {", "}
                      {items?.billTo?.address?.pinCode} <br />
                      {items?.billTo?.address?.email} <br />
                      {items?.billTo?.address?.mobile}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <Typography variant="subtitle2">Ship To :</Typography>
                  </Grid>

                  <Grid item xs={6} md={8}>
                    <Typography>
                      {items?.shipTo?.address?.addressLine1} {", "}
                      {items?.shipTo?.address?.addressLine2} {", "}
                      {items?.shipTo?.address?.city} {", "}
                      {items?.shipTo?.address?.country} {", "}
                      {items?.shipTo?.address?.pinCode} <br />
                      {items?.shipTo?.address?.email} <br />
                      {items?.shipTo?.address?.mobile}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Card sx={{ mb: 2, p: 4 }}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={6}>
                    <Typography variant="subtitle2">OrderId :</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography>{items?.orderId}</Typography>
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <Typography variant="subtitle2">Order Date :</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography>
                      {dateFormatter(items?.updatedAt)}
                    </Typography>
                  </Grid>

                  {/* <Grid item xs={6} md={6}>
                    <Typography variant="subtitle2">Order State :</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography>{orderDetails?.status}</Typography>
                  </Grid> */}
                </Grid>
              </Card>

              {/* <Card sx={{ mb: 2, p: 4 }}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={6}>
                    <Typography variant="subtitle2">
                      Payment Status :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography>{orderDetails?.status}</Typography>
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <Typography variant="subtitle2">
                      Payment Method :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography>{orderDetails?.paymentGateway}</Typography>
                  </Grid>
                </Grid>
              </Card> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Card sx={{ p: 2 }}>
                <Box sx={{ height: 300, width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row._id}
                    rows={items?.orderItems}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5]}
                    // checkboxSelection
                    disableRowSelectionOnClick
                  />
                </Box>
              </Card>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Card sx={{ mb: 2, p: 1, width: 400 }}>
                <Table>
                  {/* <TableHd>
                  <TableRow>
                    <TableCell>Dessert (100g serving)</TableCell>
                    <TableCell align="left">Calories</TableCell>
                    <TableCell align="left">Fat&nbsp;(g)</TableCell>
                    <TableCell align="left">Carbs&nbsp;(g)</TableCell>
                    <TableCell align="left">Protein&nbsp;(g)</TableCell>
                  </TableRow>
                </TableHd> */}
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">Sub Total </TableCell>
                      <TableCell align="right">
                        {moneyFormatter(items?.paymentDetails?.orderAmount)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">CGST </TableCell>
                      <TableCell align="right">
                        {moneyFormatter(items?.paymentDetails?.gst?.cgst)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">IGST </TableCell>
                      <TableCell align="right">
                      {moneyFormatter(items?.paymentDetails?.gst?.igst)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">SGST </TableCell>
                      <TableCell align="right">
                      {moneyFormatter(items?.paymentDetails?.gst?.sgst)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <b>Total Amount </b>
                      </TableCell>
                      <TableCell align="right">
                        <b>{moneyFormatter(items?.paymentDetails?.totalAmount)}</b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Grid>
        </Container>
      ))}
    </Dashboard>
  );
}

export default OrderDetails;
