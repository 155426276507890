import React, {useState}from "react";
import {
  CardMedia,
  Box,
  Typography,
  Card,
  Grid,
  CardContent,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { IMAGE_BASE_URL } from "config";

const HeroSection = ({title, heroImage, bannerURLsImg}) => {
  
  const theme = useTheme();
  const [imageError, setImageError] = useState(false);
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

 console.log("bannerURLsImg0", bannerURLsImg);
const imageHeight = isExtraSmallScreen
    ? "200px"
    : isSmallScreen
    ? "300px"
    : isMediumScreen
    ? "400px"
    : isLargeScreen
    ? "500px"
    : "100vh";
  return (
    <Box>
      <Card sx={{ maxWidth: "100%", borderRadius: "0px" , backgroundColor: bannerURLsImg == IMAGE_BASE_URL + "/" || undefined || "" || [] ? "primary.main": ""}}>
        <CardMedia
          component="image"         
        sx={{
  height: imageHeight,
  backgroundSize: "cover",
  backgroundPosition: "center",
}}
          //image={heroImage}
          image={IMAGE_BASE_URL + "/" + bannerURLsImg}
          alt={title}
        >
          <CardContent>
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography variant="h1" sx={{ color: bannerURLsImg? "#ffffff" : "#000000" }}>
                  {bannerURLsImg ? "" : title }
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </CardMedia>
      </Card>
    </Box>
  );
};

export default HeroSection;

// import React, {useState}from "react";
// import {
//   Box,
//   Typography,
//   Card,
//   Grid,
//   CardContent,
//   CardMedia,
//   useMediaQuery,
//   useTheme,
// } from "@mui/material";
// import { IMAGE_BASE_URL } from "config";

// const HeroSection = ({ title, heroImage, bannerURLsImg }) => {
//   const theme = useTheme();
//   const [imageError, setImageError] = useState(false);
//   const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
//   const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
//   const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

//   const getBackgroundColor = () => {
//     if (!bannerURLsImg || bannerURLsImg === IMAGE_BASE_URL + "/" || bannerURLsImg.length === 0) {
//       return "primary.main";
//     }
//     return "";
//   };

//   const imageHeight = isExtraSmallScreen
//     ? "200px"
//     : isSmallScreen
//     ? "300px"
//     : isMediumScreen
//     ? "400px"
//     : isLargeScreen
//     ? "500px"
//     : "100vh";

//   return (
//     <>
//       <Card
//         sx={{
//           maxWidth: "100%",
//           borderRadius: "0px",
//           backgroundColor: getBackgroundColor(),
//           position: "relative",
//         }}
//       >
//         {bannerURLsImg && bannerURLsImg !== IMAGE_BASE_URL + "/" && (
//           <CardMedia
//             component="img"
//             sx={{
//               height: imageHeight,
//               backgroundSize: "cover",
//               backgroundPosition: "center",
//             }}
//             image={IMAGE_BASE_URL + "/" + bannerURLsImg}
//             // onError={(e) => e.target.style.display = 'none'}
//             // alt={title}
//           />
//         )}
//         <CardContent
//           sx={{
//             position: bannerURLsImg ? "absolute" : "relative",
//             top: bannerURLsImg ? "50%" : "auto",
//             left: bannerURLsImg ? "50%" : "auto",
//             transform: bannerURLsImg ? "translate(-50%, -50%)" : "none",
//             textAlign: "center",
//             color: bannerURLsImg ? "#ffffff" : "#000000",
//           }}
//         >
//           <Grid
//             container
//             direction="column"
//             justifyContent="center"
//             alignItems="center"
//           >
//             <Grid item xs={12}>
//               <Typography variant="h1">
//                 {bannerURLsImg ?"": title}
//               </Typography>
//             </Grid>
//           </Grid>
//         </CardContent>
//       </Card>
//     </>
//   );
// };

// export default HeroSection;

