//Auth Services
export const SIGNIN_EMAIL_PASS_URL = "/api/auth/signin";
export const SIGNIN_MOBILE_REQ_OTP_URL = "/api/auth/signin/send-otp";
export const SIGNIN_MOBILE_VERIFY_OTP_URL = "/api/auth/signin/validate-otp";

export const SIGNIN_SILENT_URL = "api/auth/getUserProfile"; //"/api/auth/info";
export const SIGNOUT_URL = "/api/auth/signout";

export const SIGNUP_URL = "/api/auth/signup";
export const SIGNUP_MOBILE_URL = "/api/auth/signup/send-otp";
export const SIGNUP_MOBILE_VERIFY_OTP_URL = "/api/auth/signup/validate-otp";

export const SEARCH_URL = "/api/search/alldata";
export const CATEGORY_URL = "/api/catalog/category";


export const DELETE_CART = "/api/search/alldata";

// export const ADD_TO_CART_URL = "/api/cart/v2" old 
export const ADD_TO_CART_URL = "/api/cart";
export const GET_CART_URL = "/api/cart";
export const DELETE_CART_URL = "/api/cart";

export const ADD_ADDRESS_URL = "/api/auth/yourAddress";
export const GET_ADDRESS_URL = "/api/auth/yourAddress";
export const DELETE_ADDRESS_URL = "/api/auth/yourAddress";


export const PREVIEW_ORDER_URL = "/api/order/payable/amount"
export const PLACE_ORDER_URL = "/api/order/v2";
export const PAYMENT_SUCCESS = "/api/order";

export const GET_MY_ORDERS = "api/order/all";
export const GET_ORDER_DETAILS = "/api/order";
export const PAY_U_URL = "https://test.payu.in/_payment"

export const FETCH_CUSTOMER_DETAILS_URL = "/api/customer/details";
export const FETCH_PAYMENT_DETAILS_URL = "/api/payment/credentials";
export const FETCH_BANNERS_URL = "/api/customer/bannerURLS";
//export const FETCH_POLICY_URL = "/api/customer/privacypolicy";
export const FETCH_POLICY_URL = "/api/customer/all-policy";
export const FETCH_NEWS_URL = "/api/customer/info/whatsnews";
export const FETCH_GREETINGS_URL = "/api/customer/info/greetings";
export const FETCH_ABOUT_US_URL = "/api/customer/about";
export const FETCH_CATEGORIES_URL = "/api/catalog/category";
export const FETCH_PRODUCT_BY_ID_URL = "/api/catalog/product";
export const GET_QUOTE_LIST_URL = 'api/catalog/quotation';
export const GET_QUOTE_BY_ID_URL = 'api/catalog/quotation';
export const GET_PRODUCT_DETAILS = '/api/catalog/product';
export const GET_TESTIMONIALS = '/api/customer/testimony';
export const ADD_REVIEW_URL = '/api/order/review';
export const ADD_PRODUCT_REVIEW_URL = '/api/catalog/product/review';


export const FETCH_PRODUCT_BY_SKU_URL = "/api/catalog/product/sku"
export const FETCH_RECENT_PRODUCTS_URL = "/api/catalog/products/recent";
// export const FETCH_CATEGORY_PRODUCTS_URL = "/api/catalog/products/categoryId";
export const FETCH_CATEGORY_PRODUCTS_URL = "/api/catalog/products/by/category-name";
export const ADD_FAVORITE_URL = "/api/favourite";
export const REMOVE_FAVORITE_URL = "/api/favourite";
export const GET_FAVORITE_URL = "/api/favourite";
export const GET_INVOICE_DETAILS = "/api/invoice";
export const GET_ALL_INVOICE_URL = "/api/admin/invoice/all";
export const PAYMENT_STATUS_API = "/api/order";
export const EDIT_PROFILE_URL =  "/api/auth/updateUserProfile";
export const GET_QUOTE_BY_USER_URL = "/api/catalog/quotation/request"

// GET {{azure_url}}/api/catalog/products/by/category-name?pageNo=1&limit=1&name=Healthy Oils %26 Ghee

