import React, {useState} from "react";
import PropTypes from 'prop-types';

import { CopyRight } from "components";
import { TopBar, Footer} from './components';
import { Box, Grid } from '@mui/material';
import {NavBar} from "../../layouts/Dashboard/components";
// import {SocialMediaBar} from "./components/SocialMediaBar";
import CategoryBar from "./components/TopBar/CategoryBar";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Main = ({ children }) => {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const [isDisplayMenuBar, setIsDisplayMenuBar] = useState(false);

    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box>
            {/* <SocialMediaBar sx={{mt:15}}/> */}
            <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <NavBar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
            />
            </Box>
            {isDisplayMenuBar && 
                <CategoryBar/>
            }
            <Grid sx={{height: 'calc(100% - 64px)', mt: isMobileView ? (isDisplayMenuBar === false ? 16 : 22)  : (isDisplayMenuBar === false ? 10 : 16)}}>        
                <main>
                    {children}
                </main>
            </Grid>
            <Grid alignItems="flex-end">
                <Footer />
            </Grid>
            <Grid alignItems="flex-end">
                <CopyRight />
            </Grid>
        </Box>
    )
}

Main.propTypes = {
    children: PropTypes.node,
};

export default Main;