import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardContent, Divider, Typography, Grid, CardHeader } from "@mui/material";
import CartItemCard from "./CartItemCard";


function ItemSection({ cartItems }) {
  const dispatch = useDispatch();

  useEffect(() => { }, [cartItems]);

  return (
    <Card sx={{ boxShadow: 4, borderRadius: 2, color: "#443A4F" }}>
      <CardContent>
        <Typography fontWeight="bold" fontSize="16px" textAlign="start">
          Purchase Items List
        </Typography>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          {
            cartItems?.map((item) => (
              <Grid item >
                <CartItemCard itemDetails={item} />
              </Grid>
            ))
          }
        </Grid>
      </CardContent>

      <Divider />
    </Card>
  );
};

export default ItemSection;
