import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useSelector } from "react-redux";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  SvgIcon,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Menu as MenuIcon } from "react-feather";
import Logo from "components/Logo";
import { THEMES } from "config";
import Account from "./Account";
import { REACT_APP_NAME } from "config";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: "none",
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();

  const app = useSelector((state) => state.app);
  const account = useSelector((state) => state.auth);
  const appName = app.appName ? app.appName : REACT_APP_NAME;
  const appLogo = app.logoUrl;

  useEffect(() => {}, [app]);
  return (
    <AppBar
      // className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          {account?.user ? (
            <IconButton
              //className={classes.menuButton}
              color="inherit"
              onClick={onMobileNavOpen}
            >
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          ) : (
            ""
          )}
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo height="60px" logoUrl={appLogo} />
          </RouterLink>
        </Hidden>
        <Typography variant="Title" color="contrastText">
          {appName}
        </Typography>
        <Box ml={2} flexGrow={1} />
        <Account />
        {/*<Settings /> */}
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
