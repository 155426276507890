import React from "react";

import Box from "@mui/material/Box";
import { Tab } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";

import CategoryCard from "./CategoryCard";

function CategorySlider({ categories }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // let updatedTabs = [...categories];
  // while (updatedTabs.length < 8) {
  //   updatedTabs.push(`No image`);
  // }
  // let updatedTabs = [...categories];
  // while (updatedTabs.length < 8) {
  //   updatedTabs.push(`No image`);
  // }

  return (
    <Box sx={{ mt: 1 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={true}
        aria-label="scrollable auto tabs example"
        allowScrollButtonsMobile={true}
        centered={true}
        indicatorColor="none"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 },
          },
          // "& button:hover": { backgroundColor: "none" },
        }}
      >
        {categories?.map((item) => (
          <Tab
            disableRipple={true}
            label={
              <CategoryCard
                image={item.imageUrl}
                title={item.name}
                price={item.price}
                categoryId={item.categoryId}
                item={item}
              />
            }
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default CategorySlider;
