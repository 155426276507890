
// import { async } from "q";
import paymentService from "services/paymentService";
// import { NewCertificateInfo } from "interface/certificateIf";

import { NewOrder, PaymentInfo } from "interface/orderIf";

export const SUBSCRIBE_PLAN = "@subscribe/plan";

// TODO_SP: Remove this later
export const PLACE_ORDER = "@order/place-order";
export const PLACE_ORDER_SUCCESS = "@order/place-order-success";
export const PLACE_ORDER_FAILURE = "@order/place-order-failure";


export const SUBSCRIBE_CREATE = "@subscribe/create";
export const SUBSCRIBE_SUCCESS = "@subscribe/success";
export const SUBSCRIBE_FAILURE = "@subscribe/failure";
export const CREATE_ORDER = "@order/create";
export const CREATE_ORDER_SUCCESS = "@order/create-success";
export const CREATE_ORDER_FAILURE = "@order/create-failure";

export const INSTANT_PAYMENT = "@instantPayment/payment";
export const INSTANT_PAYMENT_SUCCESS = "@instantPayment/payment-success";
export const INSTANT_PAYMENT_FAILURE = "@instantPayment/payment-failure";

export const MY_ORDER = "@myOrder/my-order";
export const MY_ORDER_SUCCESS = "@myOrder/my-order-success";
export const MY_ORDER_FAILURE = "@myOrder/my-order-failure";

export const CANCEL_ORDER = "@order/cancel-order";

export const PAY_U = "@payu/pay-u";
export const PAY_U_SUCCESS = "@payu/pay-u-success";
export const PAY_U_FAILURE = "@payu/pay-u-failure";

export function subscribeToPlan(plan) {
  return async (dispatch) => {
    // console.log("subscribeToPlan() ", plan);
    dispatch({
      type: SUBSCRIBE_PLAN,
      payload: plan,
    });
  };
}







// TODO_SP : Remove this code below once the above function is working
export function placeOrderAction(centerContact, shipTo, billTo, description, redirectUrl) {
  return async (dispatch) => {
    // const centerContact, shipTo, billTo, orderDesc = new centerContact, shipTo, billTo, orderDesc(plan, info, supplierId);
    try {
      dispatch({
        type: PLACE_ORDER,
        payload: {
          order: centerContact,
          shipTo,
          billTo,
          description,
          redirectUrl
        },
      });
      const orderData = await paymentService.placeOrder(
        centerContact,
        shipTo,
        billTo,
        description,
        redirectUrl
      );

      console.log("placeOrderAction: ", orderData);
      dispatch({
        type: PLACE_ORDER_SUCCESS,
        payload: orderData,
      });
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: PLACE_ORDER_FAILURE });
      throw error;
    }
  };
}

export function cancelOrderAction() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CANCEL_ORDER,
      });
    } catch (error) {}
  };
}

export function paymentSuccessAction(info) {
  return async (dispatch) => {
    // const paymentInfo = new PaymentInfo(info);
    // const paymentInfo ={
    //   razorpayOrderId: info.razorpay_order_id,
    //   razorpayPaymentId: info.razorpay_payment_id,
    //   razorpaySignature: info.razorpay_signature,
    // }

    const razorpayOrderId = info.razorpay_order_id;
    const razorpayPaymentId = info.razorpay_payment_id;
    const razorpaySignature = info.razorpay_signature;

    try {
      dispatch({
        type: INSTANT_PAYMENT,
        payload: {
          razorpayOrderId,
          razorpayPaymentId,
          razorpaySignature,
        },
      });

      const instantPaymentData = await paymentService.paymentSuccess(
        razorpayOrderId,
        razorpayPaymentId,
        razorpaySignature
      );
      dispatch({
        type: INSTANT_PAYMENT_SUCCESS,
        payload: instantPaymentData,
      });

      // const payload = {
      //   payload: {
      //     // subscribedBy: order.email,
      //     // subscriptionId: order.payload.createOrderData.planId,
      //     // validity: order.payload.createOrderData.validity,
      //     // addedBy: order.email,

      //     // amount: order.payload.createOrderData.paymentAmount,
      //     // orderId: order.payload.createOrderData.orderId,

      //     razorpayOrderId: order.payload.response.razorpay_order_id,
      //     razorpayPaymentId: order.payload.response.razorpay_payment_id,
      //     razorpaySignature: order.payload.response.razorpay_signature,
      //   },
      // };
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: INSTANT_PAYMENT_FAILURE });
      throw error;
    }
  };
}

export function getMyOrdersAction() {

  return async (dispatch) => {
    try {
      dispatch({
        type: MY_ORDER,
      });

      const myOrders = await paymentService.myOrders();

      dispatch({
        type : MY_ORDER_SUCCESS,
        payload: myOrders,
      })
    } catch (error) {
      dispatch({
        type : MY_ORDER_FAILURE
      })
      throw error;
    }
  };
}

// export function paymentFailure() {
//   return async (dispatch) => {
//     try {
//       // dispatch({
//       //   type: INSTANT_PAYMENT,
//       // })
//     } catch (error) {

//     }
//   };
// }
export function PayUAction(key, txnid, productinfo , amount, email, firstname, phone, surl, furl, hash) {

  return async (dispatch) => {
    try {
      dispatch({
        type: PAY_U,
      });

      const payU = await paymentService.payU(key, txnid, productinfo , amount, email, firstname, phone, surl, furl, hash);

      dispatch({
        type : PAY_U_SUCCESS,
        payload: payU,
      })
    } catch (error) {
      dispatch({
        type : PAY_U_FAILURE
      })
      throw error;
    }
  };
}