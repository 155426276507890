

class PaymentDetails {
    constructor(info) {
        this.customerId = info.customerId;
        this.paymentMode = info.paymentMode;   // "ONLINE" or "OFFLINE"
        this.paymentGateway = info.gatewayType; // This indicates Payment G/w
        this.paymentServiceUrl = info.paymentServiceUrl;
        this.paymentCredentialId = info.paymentCredentialId;
        this.paymentGWPayKeyId = info.gatewayPayKeyId;
        this.paymentGWPayKeySecret = info.gatewayPayKeySecret;
        this.paymentGWPayKeyIndex = info.keyIndex;
        this.paymentGWAccessCode = info.accessCode;
    }
}

export default { PaymentDetails };
export { PaymentDetails };
