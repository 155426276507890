import React, { useEffect, useState } from "react";
import { Grid, Typography, Card, Button, Checkbox, Box } from "@mui/material";
import Container from "@mui/material/Container";
import { Link, redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { moneyFormatter } from "../../utils/formatUtils";
import { useNavigate } from "react-router";
import {
  subscribeToPlan,
  paymentSuccessAction,
  placeOrderAction,
} from "../../actions/subscribeAction";

import {
  RZP_KEY,
  RZP_NAME,
  RZP_DESC,
  RZP_LOGO,
  RZP_COLOR,
  RZP_CURRENCY,
} from "../../config/index";
// const orderData = [
//   { name: "Sub Total", prise: "1000" },
//   { name: "Shipping", prise: "50" },
//   { name: "GST", prise: "100" },
// ];

const ReviewOrderCheckout = () => {
  const cartItems = useSelector((state) => state?.cart?.items);
  const subtotal = useSelector((state) => state?.order?.orderData?.data?.order?.orderAmount);
  const address = useSelector((state) => state.address.address);
  const orderId = useSelector((state) => state?.order?.orderData?.data?.order?.paymentOrderId);
  const cgst = useSelector((state) => state?.order?.orderData?.data?.order?.gst?.cgst);
  const igst = useSelector((state) => state?.order?.orderData?.data?.order?.gst?.igst);
  const sgst = useSelector((state) => state?.order?.orderData?.data?.order?.gst?.sgst);
  const totalAmountWithGst = useSelector((state) => state?.order?.orderData?.data?.order?.totalAmount);
  const redirectOnPaymentSuccess =  useSelector((state) => state?.order?.redirectOnPaymentSuccess);
  const navigate = useNavigate();

// console.log("redirectOnPaymentSuccess", redirectOnPaymentSuccess)
  const total = useSelector((state) => state?.order?.orderData);
  const [redirect, setRedirect] = useState(false)
  const dispatch = useDispatch();
  // console.log("orderData **************", )


// console.log("totalAmountWithGst", totalAmountWithGst)
const amountInPaise = totalAmountWithGst * 100;
// console.log("amountInPaise **************", amountInPaise )
  useEffect(()=>{
   
  },[orderId, amountInPaise])





  const options = {
    key: RZP_KEY,
    name: RZP_NAME,
    description: RZP_DESC,
    image: RZP_LOGO,
    theme: {
      color: RZP_COLOR,
    },
    currency: RZP_CURRENCY,
    amount: amountInPaise,
    
    order_id: orderId,
    // notes: {
    //   // plan_id: order?.planId,
    //   plan_desc: draftOrder?.orderDesc,
    //   // plan_name: order?.planName,
    //   plan_cost: draftOrder?.totalAmount,
    //   plan_validity: draftOrder?.validity,
    // },
    prefill: {
      name: "",
      email: "",
      contact: "",
    },
    handler: (response) => {
      // const status = "paid";
      // alert(response.razorpay_order_id)
      // alert(response.razorpay_signature);
      // alert(response.razorpay_payment_id);
      dispatch(paymentSuccessAction(response));
      if(redirectOnPaymentSuccess == true){
        navigate('/payment-success')
      } 
    }}
  

  useEffect(()=>{
    
  },[options])

 


  const centerContact = {
    id: 3,
    name: "SH Service Center",
    email: "center@gmail.com",
    city: "Bangalore",
    state: "Karnataka",
    region: "EAST",
  };
  const shipTo = {
    address: {
      name: "Manju P",
      addressLine1: "BTM layout",
      addressLine2: "madiwala",
      city: "Mysore",
      state: "Karnataka",
      country: "'India'",
      pinCode: "560-089",
      mobile: "1234567867",
      email: "shipTo@test.com",
    },
  };

  const billTo = {
    address: {
      name: "'Neeraj'",
      addressLine1: "'salt lake'",
      addressLine2: "Big bazar",
      city: "Kolkata",
      state: "Chandigarh",
      country: "India",
      pinCode: "560 079",
      mobile: "1231234543",
      email: "billTo@test.com",
    },
  };
  const description = "Pehala Order";

  async function subscribePlanHandl() {
    try {
      dispatch(placeOrderAction(centerContact, shipTo, billTo, description));
      // subscribePlanHandle()
      // navigate("/prepare-order");
      openRazorpay();
    } catch (error) {
      // console.log("error", error.response.status);
    }
  }

  // eslint-disable-next-line no-undef
  const rzp1 = new Razorpay(options);

  const openRazorpay = (e) => {
    rzp1.open();
    // e.preventDefault();
    // if (draftOrder?.totalAmount > 0 && draftOrder.orderId) {
    //   rzp1.open();
    //   e.preventDefault();
    // }
  };



  return (
    // <Grid container spacing={2}>
    //   <Grid item xs={12} sm={12} md={12}>
    //     <Card sx={{ p: 4, backgroundColor: "#ADD8E6" }}>
    //       <Card sx={{ p: 5 }}>
    //         <Grid container spacing={2}>
    //           <Grid item xs={8} sm={8} md={8}>
    //             <Typography variant="h5"></Typography>
    //           </Grid>
    //           <Grid
    //             item
    //             xs={12}
    //             sm={12}
    //             md={12}
    //             sx={{ borderBottom: "1px solid gray", pb: 1 }}
    //           >
    //             <Typography variant="h4">Order Summary</Typography>
    //           </Grid>

    //           <Grid
    //             item
    //             xs={12}
    //             sm={12}
    //             md={6}
    //             sx={{ borderBottom: "1px solid gray", pb: 1 }}
    //           >
    //             <Typography variant="h5" sx={{ fontWeight: "bold" }}>
    //               productName
    //             </Typography>
    //           </Grid>

    //           <Grid
    //             item
    //             xs={12}
    //             sm={12}
    //             md={3}
    //             sx={{
    //               borderBottom: "1px solid gray",
    //               pb: 1,
    //               display: "flex",
    //               justifyContent: "end",
    //             }}
    //           >
    //             <Typography variant="h5" sx={{ fontWeight: "bold" }}>
    //               quantity
    //             </Typography>
    //           </Grid>

    //           <Grid
    //             item
    //             xs={12}
    //             sm={12}
    //             md={3}
    //             sx={{
    //               borderBottom: "1px solid gray",
    //               pb: 1,
    //               display: "flex",
    //               justifyContent: "end",
    //             }}
    //           >
    //             <Typography variant="h5" sx={{ fontWeight: "bold" }}>
    //               price
    //             </Typography>
    //           </Grid>

    //           {cartItems?.map((items) => (
    //             <Grid container sx={{ pl: 2, mt: 1 }}>
    //               <Grid item xs={8} sm={8} md={3}>
    //                 <Typography>{items.productName} </Typography>
    //               </Grid>
    //               <Grid
    //                 item
    //                 xs={4}
    //                 sm={4}
    //                 md={6}
    //                 sx={{ display: "flex", justifyContent: "end" }}
    //               >
    //                 <Typography>{items.quantity}</Typography>
    //               </Grid>

    //               <Grid
    //                 item
    //                 xs={4}
    //                 sm={4}
    //                 md={3}
    //                 sx={{ display: "flex", justifyContent: "end" }}
    //               >
    //                 <Typography>{items.price}</Typography>
    //               </Grid>
    //             </Grid>
    //           ))}
    //           <Grid
    //             item
    //             xs={12}
    //             sm={12}
    //             md={12}
    //             sx={{ borderBottom: "1px solid black" }}
    //           ></Grid>

              

    //           <Grid item xs={8} sm={8} md={8}>
    //             <Typography variant="h6">sub Total </Typography>
    //           </Grid>
    //           <Grid
    //             item
    //             xs={4}
    //             sm={4}
    //             md={4}
    //             sx={{ display: "flex", justifyContent: "end" }}
    //           >
    //             <Typography variant="h6">{moneyFormatter(subtotal)}</Typography>
    //           </Grid>
              

              

    //           <Grid item xs={8} sm={8} md={8}>
    //             <Typography variant="h6"> cgst</Typography>
    //           </Grid>
    //           <Grid
    //             item
    //             xs={4}
    //             sm={4}
    //             md={4}
    //             sx={{ display: "flex", justifyContent: "end" }}
    //           >
    //             <Typography variant="h6">{moneyFormatter(cgst)}</Typography>
    //           </Grid>

    //           <Grid item xs={8} sm={8} md={8}>
    //             <Typography variant="h6"> igst</Typography>
    //           </Grid>
    //           <Grid
    //             item
    //             xs={4}
    //             sm={4}
    //             md={4}
    //             sx={{ display: "flex", justifyContent: "end" }}
    //           >
    //             <Typography variant="h6">{moneyFormatter(igst)}</Typography>
    //           </Grid>


    //           <Grid item xs={8} sm={8} md={8}>
    //             <Typography variant="h6"> sgst</Typography>
    //           </Grid>
    //           <Grid
    //             item
    //             xs={4}
    //             sm={4}
    //             md={4}
    //             sx={{ display: "flex", justifyContent: "end" }}
    //           >
    //             <Typography variant="h6">{moneyFormatter(sgst)}</Typography>
    //           </Grid>
    //           <Grid item xs={8} sm={8} md={8}>
    //             <Typography variant="h6">Estimated Total </Typography>
    //           </Grid>
    //           <Grid
    //             item
    //             xs={4}
    //             sm={4}
    //             md={4}
    //             sx={{ display: "flex", justifyContent: "end" }}
    //           >
    //             <Typography variant="h6">{moneyFormatter(totalAmountWithGst)}</Typography>
    //           </Grid>
    //           <Grid
    //             item
    //             xs={12}
    //             sm={12}
    //             md={12}
    //             sx={{ borderBottom: "1px solid black" }}
    //           ></Grid>

    //           <Grid item xs={12} sm={12} md={12}>
    //             <Button
    //               onClick={subscribePlanHandl}
    //               fullWidth
    //               variant="contained"
    //               sx={{ borderRadius: 2 }}
    //             >
    //               Process to payment
    //             </Button>
    //           </Grid>
    //         </Grid>
    //       </Card>
    //     </Card>
    //   </Grid>
    // </Grid>
    <Button
                  onClick={subscribePlanHandl}
                  fullWidth
                  variant="contained"
                  sx={{ borderRadius: 2 }}
                >
                  Process to payment
                </Button>
  );
};

export default ReviewOrderCheckout;
