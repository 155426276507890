import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";

import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,  
} from "@mui/material";

import { signInMobile } from "actions/authActions";

function SignInMobileForm({ className, onSubmitSuccess, ...rest }) {
  const dispatch = useDispatch();

  const [policyChecked, setChecked] = React.useState(true);

  const handlePolicyCheck = (event) => {
    setChecked(event.target.checked);
  };

const removeUserExist=()=>{
  window.localStorage.removeItem('userExist');
}

  return (
    <Formik
      initialValues={{
        mobile: "",
      }}
      validationSchema={Yup.object().shape({
        mobile: Yup.string()
          .matches(
            /^[6-9][0-9]{9}/,
            "Please enter valid 10 digit mobile number"
          )
          .min(10)
          .max(10)
          .required("Please Enter your mobile"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(signInMobile(values.mobile));
          onSubmitSuccess();
          
        } catch (error) {
          // console.log("Form: error", error);

          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Grid container spacing={4} maxWidth="sm">
            <Grid item xs={12}>
              <TextField
                name="mobile"
                id="mobile"
                label="Mobile"
                value={values.mobile}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ maxLength: 10 }}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ mt: 2 }}
              onClick={removeUserExist}
            >
              <Button
                disabled={isSubmitting || !policyChecked}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ borderRadius: 30 }}
              >
                Send OTP
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

SignInMobileForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

SignInMobileForm.default = {
  onSubmitSuccess: () => { },
};

export default SignInMobileForm;
