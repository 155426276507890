import produce from "immer";
import { GET_FAVORITE_SUCCESS, ADD_FAVORITE_SUCCESS, REMOVE_FAVORITE_SUCCESS} from "../actions/favoriteAction";
const initialState = {
  favourites:[]
};

const favorite = (state = initialState, action) => {
  
  switch (action.type) {
      
      case GET_FAVORITE_SUCCESS :{
        const {favourite} = action.payload;
        console.log("favorite ", action.payload);
        return produce(state, (draft) => {
          draft.favourites = favourite?.length > 0 ? favourite[0]?.items : [] ;
          console.log("favourite reducer",favourite[0]?.items)
        });
      }
      break;
      
       case ADD_FAVORITE_SUCCESS :{
        const {favourite} = action.payload;
        return produce(state, (draft) => {
          draft.favourites = favourite?.items ? favourite?.items : [];
        });
      }
      break;
      case REMOVE_FAVORITE_SUCCESS :{
        const {favouriteItem} = action.payload;
        return produce(state, (draft) => {
          draft.favourites = favouriteItem?.items ? favouriteItem?.items : [];
          
        });
      }
      break;
    default:
      return state;
  }
};
export default favorite;
