import React, {useEffect}from "react";
import { Card, CardContent, Divider, Typography, Grid } from "@mui/material";
import { moneyFormatter } from "utils/formatUtils"

import {useSelector} from "react-redux";



function Summary({ itemCount, subTotal, discount, totalAmount, orderPaymentSummary }) {
const isShowProductPrice = useSelector((state)=> state.app.showProductPrice);
var paymentSummaryData = localStorage.getItem("checkout");

const Data = JSON.parse(paymentSummaryData);



useEffect(()=>{
 
},[paymentSummaryData, orderPaymentSummary])
 
  const invoiceData = [
    {
      id: 1,
      title: `Sub Total (${itemCount} item)`,
      //priceDetails: subTotal || Data?.orderAmount,
      priceDetails: subTotal
    },
    {
      id: 2,
      title: "Discount",
      priceDetails: discount ,
    },
    {
      id: 3,
      title: "Delivery Charges",
      //priceDetails: orderPaymentSummary?.deliveryCharges || Data?.delivery,
      priceDetails: orderPaymentSummary?.deliveryCharges
    },
    {
      id: 4,
      title: "Tax (CGST)",
      // priceDetails: orderPaymentSummary?.tax?.cgst || Data?.tax?.cgst,
      priceDetails: orderPaymentSummary?.tax?.cgst
    },
    {
      id: 5,
      title: "Tax (SGST)",
      // priceDetails: orderPaymentSummary?.tax?.sgst || Data?.tax?.cgst,
      priceDetails: orderPaymentSummary?.tax?.sgst
      
    },
    {
      id: 6,
      title: "Tax (IGST)",
      // priceDetails: orderPaymentSummary?.tax?.igst || Data?.tax?.cgst,
      priceDetails: orderPaymentSummary?.tax?.igst 
    },
    {
      id: 7,
      title: "Total Amount",
      // priceDetails: orderPaymentSummary?.totalAmount || Data?.totalAmount,
      priceDetails: orderPaymentSummary?.totalAmount
      //priceDetails : totalAmount
    },

  ];


  return (
    <Card sx={{ boxShadow: 4, borderRadius: 2, color: "#443A4F" }}>
      <CardContent>
      {isShowProductPrice &&
        <Typography fontWeight="bold" fontSize="16px" textAlign="start">
          Order Summary
        </Typography>
      }
      </CardContent>
      <Divider />
      {isShowProductPrice && <>
      {invoiceData.map((item) => (
        <CardContent key={item.id}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={8}>
              <Typography
                fontWeight="regular"
                fontSize="14px"
                textAlign="start"
              >
                {item.title}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                fontWeight="regular"
                fontSize="14px"
                textAlign="end"
              >
              {isNaN(item.priceDetails) ? '--' : moneyFormatter(item.priceDetails)}
                {/* {moneyFormatter(item.priceDetails)} */}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      ))}
      </>
      }
      <Divider />
      {isShowProductPrice &&
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography fontWeight="bold" fontSize="16px" textAlign="start">
              Total Amount
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography fontWeight="bold" fontSize="16px" textAlign="end">
              {/* {moneyFormatter(orderPaymentSummary?.totalAmount)}  */}
              {isNaN(orderPaymentSummary?.totalAmount) ? '--' : moneyFormatter(orderPaymentSummary?.totalAmount)}
              {/* {isNaN(subTotal) ? '--' : moneyFormatter(subTotal)} */}
     
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    }
      {/* <Divider sx={{ border: "1px dashed #443A4F25" }} /> */}
      {/* <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              fontWeight="bold"
              fontSize="16px"
              textAlign="center"
              color="#1459BA"
              textTransform="uppercase"
            >
              You will save ₹15,000 on this order
            </Typography>
          </Grid>
        </Grid>
      </CardContent> */}
    </Card>
  );
};

export default Summary;
