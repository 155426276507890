/* eslint-disable no-param-reassign */
import produce from "immer";

import { IMAGE_BASE_URL } from "config/index";
import { HOME_CAROUSEL_ITEMS, CATEGORIES, NEWS, VIDEOS } from "config/content";

import {
    FETCH_CUSTOMER_DETAILS,
    FETCH_CUSTOMER_DETAILS_SUCCESS,
    FETCH_BANNERS, FETCH_BANNERS_SUCCESS, FETCH_BANNERS_FAILURE,
    FETCH_POLICY, FETCH_POLICY_SUCCESS, FETCH_POLICY_FAILURE,
    FETCH_NEWS, FETCH_NEWS_SUCCESS, FETCH_NEWS_FAILURE,
    FETCH_ABOUT_US, FETCH_ABOUT_US_SUCCESS, FETCH_ABOUT_US_FAILURE,
} from "actions/contentAction";

const initialState = {
    theme: {
        primaryColor: "#1078BD",
        secondaryColor: "#7209b7",
        scaffoldColor: '#FFFFFF',
        backgroundColor: '#FFFFFF',
        fontColor: "#000000",
        fontFamily: "Roboto Slab",
    },    
    homeCarousel: [...HOME_CAROUSEL_ITEMS],
    categories: [...CATEGORIES],
    newsList: [...NEWS],
    videos: [...VIDEOS],
    socialLinks: {
        facebookUrl: undefined,
        twitterUrl: undefined,
        instagramUrl: undefined,
        linkedInUrl: undefined,
        youtubeUrl: undefined,
    },
    policies: {
        privacy: undefined,
        terms: undefined,
        refund: undefined,
        cancellation: undefined,
    },
    aboutUs: undefined,
};

// Note: This is only specific to branding that includes theme, fonts, logo and colors

const brandReducer = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_CUSTOMER_DETAILS_SUCCESS: {
            const {  GSTN, paymentGateway, isEngineeringType, brandId, showQuotationRequest,
                showPos, businessName } = action.payload?.customerDetails;
            const { brandDetails, socialLinks } = action.payload?.customerDetails;

            // console.log("brandReducer: ", GSTN, 
            // paymentGateway, isEngineeringType, brandId, showQuotationRequest,
            // showPos, businessName);

            const brandInfo = brandDetails?.length> 0 ?  brandDetails[0] : undefined;

            return produce(state, (draft) => {
                draft.theme.primaryColor = brandInfo?.theme?.primaryColor;
                draft.theme.secondaryColor = brandInfo?.theme?.secondaryColor;
                draft.theme.scaffoldColor = brandInfo?.theme?.scaffoldColor;
                draft.theme.backgroundColor = brandInfo?.theme?.backgroundColor;
                draft.theme.fontColor = brandInfo?.theme?.fontColor;
                draft.theme.fontFamily = brandInfo?.theme?.fontFamily;

                draft.socialLinks = {
                    facebookUrl: socialLinks?.facebookUrl,
                    twitterUrl: socialLinks?.twitterUrl,
                    instagramUrl: socialLinks?.instagramUrl,
                    linkedInUrl: socialLinks?.linkedInUrl,
                    youtubeUrl: socialLinks?.youtubeUrl,                   
                }
            });
        } 
            break;


        case FETCH_BANNERS_SUCCESS: {
            const { banners } = action.payload;
            return produce(state, (draft) => {

                if (banners?.length) {
                    draft.homeCarousel = [];
                    banners.map((item) => {
                        draft.homeCarousel.push({
                            label: "",
                            imgPath: IMAGE_BASE_URL + '/' + item
                        });
                    });
                }
            });
        }
            break;

        case FETCH_BANNERS_FAILURE: {


        }
            break;

        case FETCH_POLICY_SUCCESS: {
            const { policies } = action.payload;
            return produce(state, (draft) => {
                draft.policies.privacy = policies?.privacyPolicy;
                draft.policies.terms = policies?.termsOfUse;

            });
        }
            break;

        case FETCH_NEWS_SUCCESS: {
            const { newsList } = action.payload;
            //console.log("Reducer: ", action.payload, newsList);
            return produce(state, (draft) => {
                draft.newsList = newsList.list;

            });
        }
            break;

        case FETCH_ABOUT_US_SUCCESS: {
            const { aboutUs } = action.payload;
            return produce(state, (draft) => {
                draft.aboutUs = aboutUs;

            });
        }
            break;

        default:
            {
                return state;
            }
            break;
    }




};

export default brandReducer;

