import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import SplashScreen from "components/SplashScreen";
import { initializeAuth } from "actions/initActions";
import { setUserData, signOut } from "actions/authActions";
import authService from "services/authService";

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(signOut()),
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        // console.log("Auth|Before loginInWithToken ");
        const userId = authService.getUserId();
        // console.log("Auth|Before loginInWithToken :" + userId);

        const user = await authService.loginInWithToken(userId);
        await dispatch(setUserData(user));
        // console.log("Auth|After loginInWithToken " + JSON.stringify(user));
        // Fetch and Set all the configuration data on successful login
        // Initialize User Information
        dispatch(initializeAuth());
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any,
};

export default Auth;
