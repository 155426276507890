import { PaymentDetails } from "interface/paymentIf";
import paymentService from "services/paymentService";

import { NewOrder, PaymentInfo } from "interface/orderIf";

export const FETCH_PAYMENT_DETAILS = "@payment/payment-details";
export const FETCH_PAYMENT_DETAILS_SUCCESS = "@payment/payment-details-success";
export const FETCH_PAYMENT_DETAILS_FAILURE = "@payment/payment-details-failure";

export const PLACE_ORDER = "@payment/place-order";
export const PLACE_ORDER_SUCCESS = "@payment/place-order-success";
export const PLACE_ORDER_FAILURE = "@payment/place-order-failure";

export const PREVIEW_ORDER ="@payment/preview-order";
export const PREVIEW_ORDER_SUCCESS ="@payment/preview-order-success";
export const PREVIEW_ORDER_FAILURE ="@payment/preview-order-failure";

export const PAYMENT_STATUS ="@payment/payment-status";
export const PAYMENT_STATUS_SUCCESS ="@payment/payment-status-success";
export const PAYMENT_STATUS_FAILURE ="@payment/payment-status-failure";


export function fetchPaymentDetails() {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_PAYMENT_DETAILS });

            const respData = await paymentService.fetchPaymentDetails();

            const paymentDetails = new PaymentDetails(respData);

            console.log("fetchPaymentDetails() ", paymentDetails);
            dispatch({ type: FETCH_PAYMENT_DETAILS_SUCCESS, payload: { paymentDetails } });

        } catch (error) {
            dispatch({ type: FETCH_PAYMENT_DETAILS_FAILURE });
        }
    };
}

export function previewOrder(stateName) {
    return async (dispatch) => {
      try {
        const payload = {
            billtoState : stateName
        };
        
        console.log("previewOrder(): ", payload);
  
        dispatch({
          type: PREVIEW_ORDER,
          payload: payload,
        });
  
        const orderPaymentDetails = await paymentService.previewOrder(payload);       
  
        console.log("previewOrder: orderPaymentDetails", orderPaymentDetails);

        dispatch({
          type: PREVIEW_ORDER_SUCCESS,
          payload: {
            orderPaymentDetails
            },
        });

        return orderPaymentDetails;

      } catch (err) {
        console.log("previewOrder(err): ", err);
        //let errMsg = err?.response?.data?.message;
        let errMsg = err;
        const error = { message: errMsg };
  
        dispatch({ type: PREVIEW_ORDER_FAILURE });
        throw error;
      }
    };
  }



export function placeOrder(centerContact, deliveryAddress, billingAddress, description, redirectUrl) {
    return async (dispatch) => {
      // const centerContact, shipTo, billTo, orderDesc = new centerContact, shipTo, billTo, orderDesc(plan, info, supplierId);
      try {
  
        // TODO_SP : Bring redirect url based on payment type
        const newOrder = new NewOrder(centerContact, deliveryAddress, billingAddress, description, redirectUrl);
        
        console.log("placeOrder(): ", newOrder);
  
        dispatch({
          type: PLACE_ORDER,
          payload: newOrder,
        });
  
        const orderData = await paymentService.placeOrder(newOrder);       
  
        console.log("placeOrderAction: ", orderData);

        dispatch({
          type: PLACE_ORDER_SUCCESS,
          payload: orderData,
        });

        localStorage.setItem("PPSession", orderData?.order?.paymentOrderRef?.X_VERIFY);
        localStorage.setItem("paymentOrderId", orderData?.order?.paymentOrderId);




        localStorage.setItem("payUData", JSON.stringify(orderData?.order));
        localStorage.setItem("paymentOrderId", orderData?.order?.paymentOrderId);
        localStorage.setItem("totalAmount", orderData?.order?.paymentDetails?.totalAmount);
        localStorage.setItem("status", orderData?.status);

        localStorage.setItem("formbody",orderData?.order?.paymentOrderRef?.formbody);
        localStorage.setItem("encodedRequest",orderData?.order?.paymentOrderRef?.encodedRequest);
        
        
        return orderData;

      } catch (err) {
        console.log("placeOrderAction(err): ", err);
        //let errMsg = err?.response?.data?.message;
        let errMsg = err;
        const error = { message: errMsg };
  
        dispatch({ type: PLACE_ORDER_FAILURE });
        throw error;
      }
    };
  }


  export function paymentSuccessAction(data) {
    return async (dispatch) => {
      try {
        const payload = data
        console.log("paymentSuccessAction(): ", payload);
  
        dispatch({
          type: PAYMENT_STATUS,
          payload: payload,
        });
  
        const paymentSuccess = await paymentService.paymentStatus(payload);       
        console.log("previewOrder: orderPaymentDetails", paymentSuccess);

        dispatch({
          type: PAYMENT_STATUS_SUCCESS,
          payload: {
            paymentSuccess
            },
        });

        return paymentSuccess;

      } catch (err) {
        console.log("previewOrder(err): ", err);
        let errMsg = err;
        const error = { message: errMsg };
  
        dispatch({ type: PAYMENT_STATUS_FAILURE });
        throw error;
      }
    };
  }