import { CategoryList, ProductDetails, ProductList } from "interface/categoryIf";
import catalogService from "services/catalogService";

export const FETCH_CATEGORIES = "@content/categories";
export const FETCH_CATEGORIES_SUCCESS = "@content/categories-success";
export const FETCH_CATEGORIES_FAILURE = "@content/categories-failure";

export const FETCH_PRODUCT_DETAILS = "@content/product-details";
export const FETCH_PRODUCT_DETAILS_SUCCESS = "@content/product-details-success";
export const FETCH_PRODUCT_DETAILS_FAILURE = "@content/product-details-failure";

export const FETCH_RECENT_PRODUCTS = "@content/recent-products";
export const FETCH_RECENT_PRODUCTS_SUCCESS = "@content/recent-products-success";
export const FETCH_RECENT_PRODUCTS_FAILURE = "@content/recent-products-failure";

export const FETCH_CATEGORY_PRODUCTS = "@content/category-products";
export const FETCH_CATEGORY_PRODUCTS_SUCCESS = "@content/category-products-success";
export const FETCH_CATEGORY_PRODUCTS_FAILURE = "@content/category-products-failure";



export const GET_VARIATION = "@variation/get-variation";
export const GET_VARIATION_SUCCESS = "@variation/get-variation-success";
export const GET_VARIATION_FAILURE = "@variation/get-variation-failure";

export const GET_VARIATION_VAR = "@variation/get-variation-var";
export const GET_VARIATION_VAR_SUCCESS = "@variation/get-variation-var-success";
export const GET_VARIATION_VAR_FAILURE = "@variation/get-variation-var-failure";

export const GET_ALL_CATEGORY_HEADER = "@category/get-all-category-header";
export const GET_ALL_CATEGORY_HEADER_SUCCESS = "@category/get-all-category-header-success";
export const GET_ALL_CATEGORY_HEADER_FAILURE = "@category/get-all-category-header-failure";


export function fetchCategories(category) {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_CATEGORIES });

            const data = await catalogService.fetchCategories(category);
            console.log("data() ", data);
            // const categories = new CategoryList(data);
            let categories = undefined;
            if(category){
                // console.log("fetchCategories() category: ", category, data);
                categories = new CategoryList(data[0]?.children); 
            }else{
             categories = new CategoryList(data);  
            }
            //console.log("fetchCategories() ", categories);

            dispatch({ type: FETCH_CATEGORIES_SUCCESS, payload: { categories: categories?.children, data : data } });
            return categories;
        } catch (error) {
            dispatch({ type: FETCH_CATEGORIES_FAILURE });
        }
    };
}


export function fetchProductDetails(productId) {
    return async (dispatch) => {
        try {
            if (!productId)
                return (undefined)

            dispatch({ type: FETCH_PRODUCT_DETAILS });

            const data = await catalogService.fetchProductDetails(productId);
            // console.log("fetchProductDetails() ", data);
            const productDetails = new ProductDetails(data);

            // console.log("fetchProductDetails() ", productDetails);

            dispatch({ type: FETCH_PRODUCT_DETAILS_SUCCESS, payload: { product: productDetails } });
            return (productDetails);

        } catch (error) {
            dispatch({ type: FETCH_PRODUCT_DETAILS_FAILURE });
        }
    };
}


export function fetchProductDetailsBySKU(sku) {
    return async (dispatch) => {
        try {
            if (!sku)
                return(undefined)

            dispatch({ type: FETCH_PRODUCT_DETAILS });

            const data = await catalogService.fetchProductDetailsBySku(sku);
            console.log("fetchProductDetails() ", data);
            const productDetails = new ProductDetails(data);

            console.log("fetchProductDetails() ", productDetails);

            dispatch({ type: FETCH_PRODUCT_DETAILS_SUCCESS, payload: { product: productDetails } });
            return(productDetails);

        } catch (error) {
            dispatch({ type: FETCH_PRODUCT_DETAILS_FAILURE });
        }
    };
}

export function fetchRecentProducts() {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_RECENT_PRODUCTS });

            const data = await catalogService.fetchRecentProducts();
            // console.log("fetchRecentProducts() ", data);
            const productsList = new ProductList(data);
            // console.log("fetchRecentProducts() ", productsList?.list);

            dispatch({ type: FETCH_RECENT_PRODUCTS_SUCCESS, payload: { products: productsList?.list } });
        } catch (error) {
            dispatch({ type: FETCH_RECENT_PRODUCTS_FAILURE });
        }
    };
}

export function fetchCategoryProducts(categoryName) {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_CATEGORY_PRODUCTS });

            const data = await catalogService.fetchCategoryProducts(categoryName);
            // console.log("fetchCategoryProducts() ", data);
            const productList = new ProductList(data);
            // console.log("fetchCategoryProducts() ", productList?.list);

            dispatch({ type: FETCH_CATEGORY_PRODUCTS_SUCCESS, payload: { products: productList?.list } });
            return(productList?.list);
        } catch (error) {
            dispatch({ type: FETCH_CATEGORY_PRODUCTS_FAILURE });
        }
    };
}


export function fetchVariations(productId){
    return async(dispatch)=>{
        try{
            dispatch({type:GET_VARIATION});

            const payload = await catalogService.fetchVariations(productId);

            dispatch({
                type: GET_VARIATION_SUCCESS, 
                payload : payload
            });
        }
        catch(error){
            dispatch({
                type: GET_VARIATION_FAILURE
            });
        }

    }
}

export function setVarFunctionForMinicart(mapVariable, mapVariable2){
    return async(dispatch)=>{
        try{
           
            dispatch({
                type: GET_VARIATION_VAR_SUCCESS, 
                payload : {mapVariable, mapVariable2}
            
            });
            console.log("mapVariableForMiniCart", mapVariable, mapVariable2)
        }
        catch(error){
            dispatch({
                type: GET_VARIATION_VAR_FAILURE
            });
        }

    }
}


export function getAllCategoryForHeader(){
    return async(dispatch)=>{
        try{
            dispatch({type:GET_ALL_CATEGORY_HEADER});

            const payload = await catalogService.fetchCategoryHead();

            dispatch({
                type: GET_ALL_CATEGORY_HEADER_SUCCESS, 
                payload : payload
            });
            return payload;
        }
        catch(error){
            dispatch({
                type: GET_ALL_CATEGORY_HEADER_FAILURE
            });
        }

    }
}


