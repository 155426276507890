// import {
// } from "config/services";

class InitService {
  initialize = () =>
    new Promise((resolve, reject) => {
      /*
      axios
        .post(SIGNIN_EMAIL_PASS_URL, { email, password })
        .then((response) => {

          if (response?.data?.error) {
            reject(response?.data?.message);
          } else {
            if (response?.data?.payload) {
              this.setSession(response?.data?.payload?.token);
              this.setUserId(response?.data?.payload?.user?.userId);
              this.setUserEmail(response?.data?.payload?.user?.email);
              this.setUserMobile(response?.data?.payload?.user?.mobile);
            }
            resolve(response?.data?.payload?.user);
          }
        })
        .catch((error) => {
          if (error?.response) {
            reject(error?.response?.data);
          }
          reject(error);
        });
        */
    }
    );
  
}

const initService = new InitService();

export default initService;
