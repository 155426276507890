import produce from "immer";

import {
  GET_INVOICE_SUCCESS,
  GET_INVOICE_DETAILS_SUCCESS,
  SET_INVOICE_DETAILS_ID
} from "actions/invoiceAction";

const initialState = {
  invoices: [],
  invoicesList: [],
  invoiceId:""
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICE_SUCCESS:
      {
        const invoiceData = action.payload;
        console.log("action.payload", action.payload);
        return produce(state, (draft) => {
          draft.invoices = invoiceData ? invoiceData : [];
          console.log("invoiceData", invoiceData);
        });
      }
      break;
      
    case GET_INVOICE_DETAILS_SUCCESS: {
      const  invoiceDetails = action.payload;
      return produce(state, (draft) => {
        console.log("action.payload1", invoiceDetails);
        draft.invoicesList = invoiceDetails;
        console.log("invoiceData 1", invoiceDetails);
      });
    }
    break;
    case SET_INVOICE_DETAILS_ID: {
      const  invoiceId = action.payload;
      return produce(state, (draft) => {
        
        draft.invoiceId = invoiceId;
        console.log("invoiceId==",invoiceId)
      });
    }

    default:
      {
        return state;
      }
      break;
  }
};

export default invoiceReducer;
