import { Typography } from "@mui/material";
import React from "react";
import { moneyFormatter } from "utils/formatUtils";

function Price({ price, specialPrice, fromDate, tillDate, discount }) {
  const specialPriceStartDate = new Date(fromDate);
  const specialPriceEndDate = new Date(tillDate);


  const today = new Date();

  const isSpecialPriceApplicable =
    today >= specialPriceStartDate && today <= specialPriceEndDate;

  const addStyle = {
    display: "inline-block",
    textDecorationLine: "line-through",
    marginLeft: "5px",
  };

  const NoStyle = {
    marginLeft: "5px",
  };

  if (isSpecialPriceApplicable) {
    return (
      <Typography sx={{ display: "flex" }}>
        <Typography>{moneyFormatter(specialPrice)}</Typography>{" "}
        <Typography style={isSpecialPriceApplicable ? addStyle : NoStyle}>
          {isNaN(price) ? '--' :  moneyFormatter(price)}         
        </Typography>{" "}
        <Typography sx={{color:'red', ml:1}}>({discount}%)</Typography>
      </Typography>
    );
  } else {
    return (
      <Typography style={isSpecialPriceApplicable ? addStyle : NoStyle}>
        {isNaN(price) ? '--' :  moneyFormatter(price)} 
      </Typography> 
    );
  }
  
  
}

export default Price;
