/* eslint-disable no-param-reassign */
import produce from "immer";
import { processSearchResults, processSearchFacets } from "utils/searchUtils";

import { SEARCH, SEARCH_SUCCESS, SET_PRICE } from "actions/searchAction";


const initialState = {
  searchKeyword: "",
  results: [],
  facets: [],
  selectedFacets: [],
  numFound: 0,
  numFoundExact: false,
  start: 0,
  currentPage: 0,
  isSearching: false,
  rangeSliderPrice: [],
  aggregationsList:''
};

const searchReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case SEARCH:
      {
        const { searchKeyword } = action.payload;
        return produce(state, (draft) => {
          draft.searchKeyword = searchKeyword;
          draft.results = [];
          draft.facets = [];
          draft.currentPage = 0;
          draft.selectedFacets = [];
          draft.isSearching = true;
        });
      }
      break;


    case SEARCH_SUCCESS:
    //   case SEARCH_FILTERED_SUCCESS:
    // case SEARCH_PAGE_SUCCESS:
      {
        const { aggregations, hits, _shards} = action.payload;

        const results = processSearchResults(hits?.hits);
        const facets = processSearchFacets(aggregations);

        console.log("aggregations:  ", aggregations);
        // console.log("searchReducer:  ", hits);
        // console.log("searchReducer: results ", results);
        // console.log("searchReducer: facets ", facets);

        return produce(state, (draft) => {
          draft.searchData = hits;
          draft.numFound = hits?.total?.value;
          draft.results = results;
          draft.facets = facets;

          draft.isSearching = false;
          draft.aggregationsList = aggregations;

          // console.log("initialState", draft.searchData);
        });
      }

      break;
      case SET_PRICE:{
        const  price  = action.payload;
        console.log("price", price)
        return produce(state, (draft) => {
          draft.rangeSliderPrice = price;
        });
      }
  break;
    default:
      {
        return state;
      }
      break;
  }
};

export default searchReducer;
