import React from 'react';

function Logo( props) {
  const logo = props?.logoUrl ? props?.logoUrl : "/assets/logo.png";

  return (
    <img
      alt="Logo"
      src={logo}
      {...props}
    />
  );
}

export default Logo;
