import React from "react";

// import { useTheme } from "@mui/material/styles";

import { Button, Box, Grid, Typography } from "@mui/material";
import Minimal from "layouts/Minimal";

const ThemePage = () => {
  //  const theme = useTheme();

  return (
    <Minimal>
      <Box sx={{ width: "100%", maxWidth: 600 }}>
        <Typography
          variant="h1"
          component="div"
          gutterBottom
          fontWeight="fontWeightBold"
        >
          h1. Heading
        </Typography>
        <Typography
          variant="h1"
          component="div"
          gutterBottom
          fontWeight="fontWeightSemiBold"
        >
          h1. Heading
        </Typography>
        <Typography
          variant="h1"
          component="div"
          gutterBottom
          fontWeight="fontWeightRegular"
        >
          h1. Heading
        </Typography>

        <Typography variant="h2" gutterBottom component="div">
          h2. Heading
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          h3. Heading
        </Typography>
        <Typography variant="h4" gutterBottom component="div">
          h4. Heading
        </Typography>
        <Typography variant="h5" gutterBottom component="div">
          h5. Heading
        </Typography>
        <Typography variant="h6" gutterBottom component="div">
          h6. Heading
        </Typography>
        <Typography variant="subtitle1" gutterBottom component="div">
          subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Quos blanditiis tenetur
        </Typography>
        <Typography variant="subtitle2" gutterBottom component="div">
          subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Quos blanditiis tenetur
        </Typography>
        <Typography variant="body1" gutterBottom>
          body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="body2" gutterBottom>
          body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="button" display="block" gutterBottom>
          button text
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          caption text
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
          overline text
        </Typography>

        <Grid>
        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            background: "#F1C12B",
            // height: 48,
            color: "#000000",
            borderRadius: 2,
            "&:hover": {
              background: "#CDA425",
              color: "white",
            },
          }}
        >
          Primary Button
        </Button>
        </Grid>
      </Box>
    </Minimal>
  );
};

export default ThemePage;
