import React, { useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import NewsSlider from "./components/NewsSlider";

function NewsSection({newsList}) {

  useEffect(() => {
  }, [newsList]);
    return (
        <Grid container sx={{mb:6}}>
            <Grid item xs={12} sx={{backgroundColor: '#f2f3f5', pt:4, pb:4}}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '20px' }}>
                    <Box>
                        {/* <NewspaperIcon sx={{ color: '#1459BA', fontSize: '24px' }} /> */}
                    </Box>
                    <Box>
                    <Typography variant="h3" color="#323334" sx={{fontWeight: 600}}>Latest News</Typography>
                        {/* <Typography sx={{ color: '#1459BA', fontSize: "16px", fontWeight: 600 }}></Typography> */}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}  sx={{backgroundColor: '#f2f3f5', pt:4, pb:4}}>
                <NewsSlider newsList={newsList}/>
            </Grid>
        </Grid>
    );
}

export default NewsSection;
