
import { Review , ProductReview} from 'interface/reviewIf'
import FavouriteService from "services/FavouriteService";
export const ADD_REVIEW = "@review/add-review";
export const ADD_REVIEW_SUCCESS= "@review/add-review-success";
export const ADD_REVIEW_FAILURE = "@review/add-review-failure"


export const ADD_PRODUCT_REVIEW = "@review/add-product-review";
export const ADD_PRODUCT_REVIEW_SUCCESS= "@review/add-product-review-success";
export const ADD_PRODUCT_REVIEW_FAILURE = "@review/add-product-review-failure"


export function sendReviewAction(rating, feedback, orderId, productId ){
    return async(dispatch)=>{
        try{
            const reviewIf = new Review(rating, feedback, orderId, productId);
            dispatch({
                type: ADD_REVIEW,
            })
          
            const reviewService = await FavouriteService.addReview(reviewIf)
            console.log("reviewIf", reviewIf)
           dispatch({
            type: ADD_REVIEW_SUCCESS,
            payload : reviewService
          });

        }catch(error){
            dispatch({
                type: ADD_REVIEW_FAILURE,
              });
              console.log(error);
        }
        
    }
}

 export function addReviewForProduct(formData){
    return async(dispatch)=>{

        // const productreviewIf = new ProductReview(rating, review, orderId, productId, );
        try{
            dispatch({
                type:ADD_PRODUCT_REVIEW,  
            })
            const productReview = await FavouriteService.addProductReview(formData)

            dispatch({
                type: ADD_PRODUCT_REVIEW_SUCCESS,
                payload : productReview
              });
        }catch(error){
            dispatch({
                type: ADD_PRODUCT_REVIEW_FAILURE,
              });
        }
    }
 }