import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Box, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { Main as MainLayout } from "layouts";

import { BrandSection, AboutSection, HeroSection, ContactSection } from "./components";
import { IMAGE_BASE_URL } from "config";


function AboutUs() {

    const cms = useSelector((state) => state.cms);
    const aboutUs = cms?.aboutUs;
    const bannerImage = (cms?.homeCarousel?.length > 0) ? cms?.homeCarousel[0]?.imgPath : undefined;
    const contactDetails = cms?.contact;

    const app = useSelector((state) => state.app);
    const brandName = app?.brandName
    const appDescription = app?.appDescription;
    const logoUrl = IMAGE_BASE_URL + "/"+ app?.logoUrl;



    // console.log("About Us: ", bannerImage, logoUrl, contactDetails);
    useEffect(() => {
    }, [aboutUs, bannerImage, logoUrl]);

    return (
        <MainLayout>
            <Helmet>
                <title>About US</title>
            </Helmet>

            <Grid container spacing={2}mb={2}>
                <Grid item md={12} sm={12}>
                    <HeroSection heroImage={bannerImage} />
                </Grid>

                <Grid item md={12} sm={12} mt={2}>
                    <AboutSection content={aboutUs} />
                </Grid>

                <Grid item md={12} sm={12} mt={2}>
                    <BrandSection image={logoUrl} content={appDescription} />
                </Grid>
                <Grid item md={12} sm={12} mt={10}>
                    <ContactSection contactDetails={contactDetails}/>
                </Grid>
            </Grid>
        </MainLayout>
    );
}

export default AboutUs;