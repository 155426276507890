import React, { useEffect } from "react";
import { paymentSuccessAction } from "../../../actions/paymentAction";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Card, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { moneyFormatter } from "utils/formatUtils";
const SuccessPage = () => {
  const dispatch = useDispatch();
  const paymentOrderId = window.localStorage.getItem("paymentOrderId");
  const paymentId = window.localStorage.getItem("merchantId");


  const phonePeSuccess = useSelector((state)=> state?.payment?.phonePeSuccess);
  console.log("phonePeSuccess01", phonePeSuccess?.paymentSuccess?.paymentOrderId)
  useEffect(() => {
    const data = {
      status: "paid",
      paymentOrderId: paymentOrderId,
      paymentId: paymentId,
      signature: "",
    };
  
    dispatch(paymentSuccessAction(data));
  }, []);

  const removeLocalStorage=()=>{
    localStorage.removeItem("paymentOrderId");
    localStorage.removeItem("paymentId");
  }

  
  return (
    <div>
      <Container maxWidth="md" sx={{ mt: 3, mb: 3 }}>
        <Card sx={{ borderRadius: 5, width: "100%" }}>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "center", mt: 3 }}
          >
            <Card
              sx={{
                borderRadius: 3,
                width: "70%",
                mb: 3,
                p: 1,
                backgroundColor: "#f1faf5",
              }}
            >
              <Grid
                container
                spacing={1}
                sx={{ display: "flex", justifyContent: "center", p: 1 }}
              >
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <CheckCircleIcon
                    sx={{
                      width: "100px",
                      height: "100px",
                      color: "green",
                      mb: 2,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  sx={{ display: "flex", justifyContent: "start", mt: 3 }}
                >
                  <Typography><b>Status: </b>{phonePeSuccess?.paymentSuccess?.status}</Typography>
                  
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  sx={{ display: "flex", justifyContent: "start", mt: 3 }}
                >
                
                  <Typography><b>Payment Order Id: </b>{phonePeSuccess?.paymentSuccess?.paymentOrderId}</Typography>
               
                </Grid>
                {/* <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  sx={{ display: "flex", justifyContent: "start", mt: 3 }}
                >
                  
                  <Typography>Payment Id: {paymentId}</Typography>
                 
                </Grid> */}
                <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  sx={{ display: "flex", justifyContent: "start", mt: 3 }}
                >
                  
                  <Typography><b>Amount : </b> {isNaN(phonePeSuccess?.paymentSuccess?.paymentDetails?.totalAmount) ? '--' :  moneyFormatter(phonePeSuccess?.paymentSuccess?.paymentDetails?.totalAmount)}</Typography>
                        
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  sx={{ display: "flex", justifyContent: "start", mt: 3 }}
                >
                  <RouterLink to="/">
                    <Button variant="contained" onClick={removeLocalStorage}>Continue Shopping</Button>
                  </RouterLink>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Card>
      </Container>
    </div>
  );
};

export default SuccessPage;
