import React, { useEffect, useState, useCallback } from "react";
import { Grid, Card, Container, CardMedia, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Main as MainLayout } from "layouts";

import { IMAGE_BASE_URL } from "config";
import { Summary, AddressSection, ItemsSection } from "./components";
import { fetchMyAddresses } from "actions/addressAction";
import { ADDRESS_TYPE } from "config";
import OrderConfirm from "./components/OrderConfirm";
import PreviewOrder from "./components/OrderPreview";
import { previewOrder } from "actions/paymentAction";

const Checkout = () => {
  const dispatch = useDispatch();
  const [addresses, setAddresses] = useState(null);

  const deliveryAddress = useSelector((state) => state.checkout?.deliveryAddress);
  const deliveryAddressState = useSelector((state) => state.checkout?.deliveryAddressState);
  const billingAddress = useSelector((state) => state.checkout?.billingAddress);
  const billingAddressState = useSelector((state) => state.checkout?.billingAddressState);
  const cart = useSelector((state) => state?.cart);

  const orderPaymentSummary = useSelector((state) => state?.checkout?.orderPaymentSummary );
  const isReadyForPayment =  useSelector((state) => state.checkout?.isReadyForPayment);

  
  //const discountAmount = cart.items.map((items)=> (items.priceDetail.specialPrice.discount));
  // console.log("discountAmount", discountAmount)
  // const TotalDiscount = discountAmount.reduce((acc, num) => acc + num, 0);
  // const setLocalData=()=>{
  //   localStorage.setItem("LocalTotalDiscount", TotalDiscount)
  // }
  // const LocalTotalDiscount = localStorage.getItem("LocalTotalDiscount")
  useEffect(() => {
    async function fetchAddress() {
      const addressList = await dispatch(fetchMyAddresses());
      setAddresses(addressList);
 
    }
    fetchAddress();
  },[]);

  useEffect(() => { }, [deliveryAddress, billingAddress, addresses, deliveryAddressState, billingAddressState, cart, orderPaymentSummary])

  async function handlePreviewOrder() {
    try {
      await dispatch(previewOrder(deliveryAddressState));
      console.log("Order Previewed Successfully");
      
    } catch (error) {
      console.log("error", error.response.status);
    }
  }

  useEffect(() => { 
    handlePreviewOrder()
  },[deliveryAddressState])

  let isPreview = billingAddress && deliveryAddress 
  return (
    <MainLayout>
      <Helmet>
        <title>Checkout</title>
      </Helmet>
      <Container maxWidth>
        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item container xs={12} sm={12} md={8} spacing={2}>
            <Grid item md={12}>
              <AddressSection addressType={ADDRESS_TYPE.DELIVERY} title="Delivery Address" addresses={addresses} address={deliveryAddress} addressState={deliveryAddressState} />
            </Grid>
            <Grid item md={12}>
              <AddressSection addressType={ADDRESS_TYPE.BILLING} title="Billing Address" addresses={addresses} address={billingAddress} addressState={billingAddressState} />
            </Grid>
            <Grid item md={12}>
              <ItemsSection cartItems={(cart?.items) ? cart.items : []}/>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {/* ToDo_vishal fetch Discount and remove 0 hardcoding */}
            <Summary itemCount={cart?.items?.length} subTotal={cart?.subTotal} discount={0} totalAmount={cart?.totalAmount} orderPaymentSummary={orderPaymentSummary}/>
            {
              isPreview && !isReadyForPayment && 
              <PreviewOrder 
              totalAmount={cart?.totalAmount} 
              deliveryAddress={deliveryAddress}
              billingAddress={billingAddress}
              deliveryAddressState={deliveryAddressState}
              cart={cart}
            />

            }


            {
              isReadyForPayment && <OrderConfirm 
              totalAmount={cart?.totalAmount} 
              deliveryAddress={deliveryAddress}
              billingAddress={billingAddress}
              cart={cart}
              />
            }
          </Grid>
        </Grid>
      </Container>
    </MainLayout>
  );
};

export default Checkout;
