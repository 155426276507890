import {
  Instagram as InstagramIcon,
  WhatsApp as WhatsAppIcon,
  Twitter as TwitterIcon,
  FacebookOutlined as FacebookOutlinedIcon,
  YouTube as YouTubeIcon,
} from "@mui/icons-material";

export const getSocialLinks = socialLinks => {

  const socialIconList = [];
  const iconColor = "#1459BA";

  if (socialLinks) {
    if (socialLinks?.facebookUrl) {
      socialIconList.push({
        name: "Facebook",
        icon: <FacebookOutlinedIcon sx={{ color: iconColor, fontSize: "36px" }} />,
        target: socialLinks.facebookUrl,
      });
    }

    if (socialLinks?.twitterUrl) {
      socialIconList.push({
        name: "Twitter",
        icon: <TwitterIcon sx={{ color: iconColor, fontSize: "36px" }} />,
        target: socialLinks?.twitterUrl,
      });
    }

    if (socialLinks?.instagramUrl) {
      socialIconList.push({
        name: "Instagram",
        icon: <InstagramIcon sx={{ color: iconColor, fontSize: "36px" }} />,
        target: socialLinks.instagramUrl,
      });
    }

    if (socialLinks?.youtubeUrl) {
      socialIconList.push({
        name: "Youtube",
        icon: <YouTubeIcon sx={{ color: iconColor, fontSize: "36px" }} />,
        target: socialLinks.youtubeUrl,
      });
    }
  }


  return socialIconList;
};

