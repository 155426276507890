import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { Main as MainLayout } from "layouts";


export default function PrivacyPolicy() {

    const cms = useSelector((state) => state.cms);
    const privacyPolicy = cms?.policies?.privacy;

    const registeredEmail = useSelector((state) => state.app.registeredEmail);

    useEffect(() => {
    }, [privacyPolicy]);

    return (
        <MainLayout>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <Container>
                <Box pt={12} sx={{ flexGrow: 1 }}>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h3" >
                                Privacy Policy
                            </Typography>
                            <Typography >Last updated: June 13, 2023</Typography>
                            <br /> <br />
                            <Typography variant="body" >
                                {privacyPolicy}
                            </Typography>
                            <br /> <br />

                            <Typography  variant="body">
                                <li> 
                                    By email:{" "}
                                    <a
                                        href="/cdn-cgi/l/email-protection"
                                        class="__cf_email__"
                                        data-cfemail="bdcec8cdcdd2cfc9fdd0d4d3d9ceded1d4d693ded2d0"
                                        style={{ color: "#00c3ff" }}
                                    >
                                        {registeredEmail}
                                    </a>
                                </li>
                            </Typography>
                            <br />
                            <br /> <br />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </MainLayout>
    );
}
