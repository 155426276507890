/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  // SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE,
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  SIGNOUT,
  SILENT_LOGIN,
  SIGNUP_MOBILE,
  SIGNUP_MOBILE_SUCCESS,
  SIGNUP_MOBILE_FAILURE,
  SIGNUP_MOBILE_VERIFY_OTP,
  SIGNUP_MOBILE_VERIFY_OTP_SUCCESS,
  SIGNUP_MOBILE_VERIFY_OTP_FAILURE,
  SIGNIN_MOBILE_REQ_OTP,
  SIGNIN_MOBILE_REQ_OTP_FAILURE,
  SIGNIN_MOBILE_OTP_VERIFY,
  SIGNIN_MOBILE_OTP_VERIFY_SUCCESS,
  SIGNIN_MOBILE_OTP_VERIFY_FAILURE,
} from "actions/authActions";

import { BUSINESS_TYPES, USER_ROLES } from "config";
import { generateAvatarName } from "utils/formatUtils";
import { EDIT_PROFILE_SUCCESS } from "../actions/addressAction";
const initialState = {
  //user: null,
  isAuthorized: false,
  user: undefined,
  activeRole: USER_ROLES.SALES,
  businessInfo: {
    type: BUSINESS_TYPES.GENERAL,
  },
  newUserInfo: null,
  signUpMobile: null,
  signInMobile: null,
  signUpRegisterName: null,
  signUpRegisterMobile: null,
  showOTPPage: false,
  signUpOTPSuccess: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN_MOBILE_REQ_OTP:
      {
        const { mobile } = action.payload;

        // console.log("Reducer: SIGNUP_SEND_MOBILE_OTP_SUCCESS, mobile ", mobile);

        return produce(state, (draft) => {
          draft.isAuthorized = false;
          draft.signInMobile = mobile;
        });
      }
      break;

    case SIGNIN_SUCCESS:
    case SIGNIN_MOBILE_OTP_VERIFY_SUCCESS:
    case SILENT_LOGIN:   
      {
        const { user } = action.payload;
        //console.log("authReducer: SIGNIN_SUCCESS", user);

        return produce(state, (draft) => {
          draft.isAuthorized = user?.userType && user?.mobile ? true : false;
          draft.user = user;
          draft.user.avatar = generateAvatarName(user?.name);
          draft.activeRole = user?.userType; // USER_ROLES.OWNER;  //
          draft.businessInfo = {
            type: BUSINESS_TYPES.GENERAL,
          };
        });
      }
      break;
      case  SIGNUP_MOBILE_SUCCESS: {
        const  user  = action.payload.newUser;
        return produce(state, (draft) => {
          draft.isAuthorized = user?.userType && user?.mobile ? true : false;
          draft.user = user;
          draft.user.avatar = generateAvatarName(user?.name);
          draft.activeRole = user?.userType; // USER_ROLES.OWNER;  //
          draft.businessInfo = {
            type: BUSINESS_TYPES.GENERAL,
          };
        });
      }
      break;
    case SIGNUP_MOBILE_VERIFY_OTP_SUCCESS:
      {
       
        return produce(state, (draft) => {
          const  isMobileValidated  = action.payload;
          draft.signUpOTPSuccess = isMobileValidated.user.isMobileValidated;
          //console.log("action.payload01", action.payload)
        });
      }
      break;
    // TODO_SP : need to address handling of the verify mobile otp sign up
    case SIGNUP_MOBILE_VERIFY_OTP_SUCCESS:
    case EDIT_PROFILE_SUCCESS:
      {
        const { user } = action.payload;
        console.log("userrrr", user);
        console.log(
          "authReducer: SIGNIN_SUCCESS ",
          action.type,
          user,
          action.payload
        );

        return produce(state, (draft) => {
          draft.isAuthorized = user?.userType && user?.mobile ? true : false;
          draft.user = user;
          draft.user.avatar = generateAvatarName(user?.name);
          draft.activeRole = user?.userType; // USER_ROLES.OWNER;  //
          draft.businessInfo = {
            type: BUSINESS_TYPES.GENERAL,
          };
        });
      }
      break;

    case SIGNIN:
      {
        return produce(state, (draft) => {
          draft.isAuthorized = false;
          draft.user = null;
        });
      }
      break;

    case SIGNIN_FAILURE:
    case SIGNIN_MOBILE_REQ_OTP_FAILURE:
    case SIGNIN_MOBILE_OTP_VERIFY_FAILURE:
    case SIGNUP_MOBILE_FAILURE:
    case SIGNUP_MOBILE_VERIFY_OTP_FAILURE:
      {
        return produce(state, (draft) => {
          draft.isAuthorized = false;
          draft.user = null;
          // Maybe store error
        });
      }
      break;

    case SIGNUP_MOBILE:
      {
        const { name, mobile } = action.payload;
        //console.log("Reducer: SIGNUP_SEND_MOBILE_OTP_SUCCESS, mobile ", mobile);
        return produce(state, (draft) => {
          draft.signUpRegisterName = name;
          draft.signUpRegisterMobile = mobile;

          draft.isAuthorized = false;
          draft.user = null;
          // console.log("mobile number =>>>>>>>>.",mobile)
        });
      }
      break;

    case SIGNOUT:
      {
        return produce(state, (draft) => {
          draft.isAuthorized = false;
          draft.user = null;
          draft.newUserInfo = null;
          draft.signUpMobile = null;
          draft.signInMobile = null;
          draft.signUpRegisterName = null;
          draft.signUpRegisterMobile = null;
        });
      }
      break;
    default:
      {
        return state;
      }
      break;
  }
};

export default authReducer;
