export const getEmbeddedUrl = url => {

    const regExp = /^(?:https?:\/\/)?(?:www\.)?youtube(?:-nocookie)?\.com(?:\S+)?(?:\/embed\/|\/v\/|watch\/|(?:(?:\S+)?\?v=))([\w-]{11})(?:\S+)?$/;
    const match = url.match(regExp);
    const videoId =  match && match[1];
    // Construct the embedded URL using the extracted video ID
    const embeddedUrl = `https://www.youtube.com/embed/${videoId}`;
    
    return embeddedUrl;
  };


  export function convertVideoLink(link) {
    if (link.includes('youtube.com')) {
      // YouTube link
      const videoIdMatch = link.match(/[?&]v=([^&]+)/);
      if (videoIdMatch) {
        const videoId = videoIdMatch[1];
        return `https://www.youtube.com/embed/${videoId}`;
      }
    } else if (link.includes('facebook.com') && link.includes('/videos/')) {
      // Facebook link
      const videoIdMatch = link.match(/\/videos\/(\d+)/);
      if (videoIdMatch) {
        const videoId = videoIdMatch[1];
        return `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fvideo.php%3Fv%3D${videoId}`;
      }
    } else if (link.includes('facebook.com') && link.includes('/reel/')) {
      // Facebook Reels link
      const reelIdMatch = link.match(/\/reel\/(\d+)/);
      if (reelIdMatch) {
        const reelId = reelIdMatch[1];
        return `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Freel%2F${reelId}`;
      }
    } else if (link.includes('twitter.com')) {
      // Twitter link
      return link; // Twitter links can be directly embedded in iframes
    } else if (link.includes('instagram.com/tv/')) {
      // Instagram TV (IGTV) link
      return link.replace('?igshid=', '/embed/');
    }
  
    // Unsupported platform or invalid link
    return null;
  }
  