
import React, { useEffect } from "react";

import { Dashboard as DashboardLayout } from "layouts";
import { Grid, Box, Card, CardMedia, Typography, Button } from "@mui/material";
import { getMyOrdersAction } from "actions/subscribeAction";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_BASE_URL } from "config";
import Container from "@mui/material/Container";
import { DataGrid } from "@mui/x-data-grid";
import { dateFormatter } from "utils/formatUtils";
import { Link } from "react-router-dom"
import { useNavigate, } from "react-router";
import PreviewIcon from '@mui/icons-material/Preview';
import { getInvoiceAction, getInvoiceByIdAction, storeInvoiceId } from "actions/invoiceAction";
import Tooltip from '@mui/material/Tooltip';
const InvoiceList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  

  
  const invoice = useSelector((state) => state.invoice?.invoices?.invoices);

  console.log("invoice1", invoice)
  useEffect(() => {
    dispatch(getInvoiceAction());
  }, []);

  const columns = [
    { field: "invoiceId", headerName: "Invoice Id", width: 200 },
    {
      field: "businessDetails",
      valueGetter: ({ row }) => row.businessDetails.businessName,
      headerName: "Business Name",
      width: 100,
      editable: true,
    },
    { field: "orderId", headerName: "Order Id", width: 200 },
    {
      field: "paymentDetails",
      headerName: "Amount",
      valueGetter: ({ row }) => row.paymentDetails.orderAmount,
      // type: 'number',
      width: 150,
      editable: true,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      valueGetter: ({ row }) => row.paymentDetails?.totalAmount,
      // type: 'number',
      width: 150,
      editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      width: 10,
      editable: true,
    },
    {
      field: "total",
      headerName: "Total",
      type: "number",
      width: 20,
      editable: true,
      // valueGetter: (params) => dateFormatter(params.row.updatedAt),
    },
    {
      field: "metrics",
      headerName: "Metrics",
      type: "number",
      width: 100,
      editable: true,
    },
    

    {
      field: "",
      disableClickEventBubbling: true,
      headerName: "Action",
      renderCell: (params) => {

        const onClick = () => {
          const invoiceId = params.row.invoiceId
          dispatch(getInvoiceByIdAction(invoiceId));
          dispatch(storeInvoiceId(invoiceId));
          navigate("/invoice-details");
          
        };
        return <Tooltip title="View Invoice details"><PreviewIcon onClick={onClick}/></Tooltip>;
      },
      width: 150,
      // onClick: (params) => {
      //   alert( params.row.updatedAt);
      // }
    }
    // {
    //   field: 'orderAmount',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  const getInvoiceFun=()=>{
    dispatch(getInvoiceAction());
  }

  return <>
  <DashboardLayout>
      <Container sx={{ mb: 5, mt: 5 }} >
        <Grid container sx={{ pt: 5 }}  >
          <Grid items xs={12} sm={12} md={12} sx={{ mb: 2 }}>
            <Typography variant="h3">All Your Invoices</Typography>
            <Button onClick={getInvoiceFun}>fetch invoice list .....</Button>
          </Grid>
          <Grid items xs={12} sx={{ height: 600, width: 800}} >
            <DataGrid
              getRowId={(row) => row.invoiceId}
              rows={invoice ? invoice : []}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              // checkboxSelection
              disableRowSelectionOnClick
            />
            </Grid>
        </Grid>
      </Container>
    </DashboardLayout>
  </>;
};

export default InvoiceList;
