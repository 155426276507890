import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";

import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";

import { STATE_LIST } from "config";

import { addAddress } from "actions/addressAction";
import { chooseAddress } from "actions/addressAction";
import {useSelector} from "react-redux";

function AddressForm({
  addressType,
  onSubmitSuccess,
  handleNewAddressSave,
  handleNewAddress,
  ...rest
}) {
  const dispatch = useDispatch();

  const cancelForm = () => {
    dispatch(chooseAddress(addressType));
  };

  const { user } = useSelector(state => state.auth);

  return (
    <Formik
      initialValues={{
        label: "",
        name: user?.name,
        mobile: user?.mobile,
        email: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
      }}
      validationSchema={Yup.object().shape({
        label: Yup.string()
          .min(2)
          .max(20)
          .required("Please enter proper label to identify address"),
        name: Yup.string().min(2).max(120).required(),
        mobile: Yup.string()
          .min(10, "Must be 10 characters")
          .max(10, "Must be 10 characters ")
          .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "Please enter valid mobile number"
          )
          .required("Required"),
        email: Yup.string().email(),
        address1: Yup.string().max(120).required(),
        address2: Yup.string().max(120).required(),
        city: Yup.string().max(120).required(),
        state: Yup.string().max(120).required(),
        country: Yup.string().max(120).required(),
        pincode: Yup.string()
          .matches(/^[0-9]{6}/, "Please enter valid Pincode")
          .min(6)
          .max(6)
          .required("Please Enter valid Pincode"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(addAddress(addressType, values));
          onSubmitSuccess();
        } catch (error) {
          // console.log("Form: error", error);
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Grid container spacing={1} maxWidth="sm">
            <Grid item xs={12}>
              <TextField
                name="label"
                id="label"
                label="Label"
                value={values.label}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.label && errors.label)}
                helperText={touched.label && errors.label}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="name"
                id="name"
                label="Name"
                value={values.name}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="mobile"
                id="mobile"
                label="mobile"
                value={values.mobile}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                name="email"
                id="email"
                label="email"
                value={values.email}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                name="address1"
                id="address1"
                label="Address Line 1"
                value={values.address1}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.address1 && errors.address1)}
                helperText={touched.address1 && errors.address1}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="address2"
                id="address2"
                label="Address Line 2"
                value={values.address2}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.address2 && errors.address2)}
                helperText={touched.addres2 && errors.addres2}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="city"
                id="city"
                label="City"
                value={values.city}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.city && errors.city)}
                helperText={touched.city && errors.city}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                value={values.state}
                onChange={(event, newValue) => {
                  setFieldValue("state", newValue);
                }}
                disablePortal
                id="state"
                options={STATE_LIST}
                required
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <>
                    <TextField {...params} label="State"  error={Boolean(touched.state && errors.state)}/>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="country"
                id="country"
                label="Country"
                disabled
                value={values.country}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.country && errors.country)}
                helperText={touched.country && errors.country}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="pincode"
                id="pincode"
                label="Pincode"
                value={values.pincode}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ maxLength: 6 }}
                error={Boolean(touched.pincode && errors.pincode)}
                helperText={touched.pincode && errors.pincode}
              />
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              alignItems={"end"}
              justifyContent={"flex-end"}
              sx={{ mt: 2 }}
            >
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ borderRadius: 30 }}
              >
                Save Address
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              alignItems={"end"}
              justifyContent={"flex-end"}
              sx={{ mt: 2 }}
            >
              <Button
                onClick={handleNewAddress}
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="button"
                variant="contained"
                sx={{ borderRadius: 30 }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

AddressForm.propTypes = {
  addressType: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

AddressForm.default = {
  onSubmitSuccess: () => {},
};

export default AddressForm;
