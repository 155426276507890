
export const DEFAULT_SHIPPING_POLICY = `

Shipping Process

- Orders will be processed and shipped within 2-3 business days from the date of purchase.
- Shipping times may vary depending on the destination and shipping method selected by the customer.
- Customers will receive a tracking number from the businesses via email once the order has been shipped.
- Order delivery status can be tracked by customer by login into the application and view the order history / details.

Shipping Rates

- Shipping rates will be calculated based on the weight of the package and the destination address.
- Customers will have the option to choose from various shipping methods at checkout, each with its associated cost.

International Shipping
- We don’t ship products internationally.

Shipping Address
- Customers are responsible for providing accurate shipping addresses. We are not liable for 
  any delays or non-delivery due to incorrect address information provided by the customer.

Delivery Issues
- In the event of a delivery issue such as non-delivery or damaged goods, customers are advised to contact our customer support team for assistance.

Changes to this Shipping Policy
- We may update Our Shipping Policy from time to time. We will notify You of any changes by posting the new Shipping Policy on this page.
- We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update 
  the "Last updated" date at the top of this Shipping Policy.
- You are advised to review this Shipping Policy periodically for any changes. Changes to this Shipping Policy are effective when they are posted on this page.
`;

export const DEFAULT_RETURN_POLICY = `

Eligibility for Returns
 
- Any consumable products purchased are not eligible for return
- Products purchased are eligible for return within 15 days from the date of delivery
- To be eligible for a return, items must be unused, in the same condition as received, and in the original packaging.
 
Return Process

- Return process will take 5-7 working days
- Customers must contact our customer support team to initiate a return request.
- Once the return request is approved, customers will be provided with instructions on how to return the item.
 
Return Shipping
- Customers are responsible for the cost of return shipping unless the return is due to a mistake on our part (e.g., wrong item shipped, defective product).
 
Refunds
 
- Customers are responsible for providing accurate shipping addresses. We are not liable for any delays or non-delivery due to incorrect address information provided by the customer.
 
Delivery Issues

- Refunds will be issued to the original payment method within 15 business days of receiving the returned item.
- Shipping costs are non-refundable unless the return is due to a mistake on our part.
 
Changes to this Return Policy
- We may update Our Return Policy from time to time. We will notify You of any changes by posting the new Return Policy on this page.
- We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the 
  "Last updated" date at the top of this Return Policy.
- You are advised to review this Return Policy periodically for any changes. Changes to this Return Policy are effective when they 
  are posted on this page.
`;

export const DEFAULT_REFUND_POLICY = `
Refund Eligibility
 
Refunds may be issued in the following circumstances:
- The customer cancels an order before it has been shipped.
- The customer returns a product in accordance with our return policy.
- A refund is required due to a mistake on our part (e.g., billing errors, defective products).
 
Refund Process
 
- Customers must contact our customer support team to request a refund.
- Refunds will be processed back to the original payment method within 15 business days of the refund request being approved.
 
Refund Exceptions
 
- We reserve the right to refuse refunds in cases where products are returned in a used or damaged condition, or if the return does 
  not meet our return policy criteria.
 
Changes to this Refund Policy

- We may update Our Refund Policy from time to time. We will notify You of any changes by posting the new Refund Policy on this page.
- We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the 
  "Last updated" date at the top of this Refund Policy.
- You are advised to review this Refund Policy periodically for any changes. Changes to this Refund Policy are effective when 
  they are posted on this page.
`;