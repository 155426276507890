import { IMAGE_BASE_URL } from "config";
import { getEmbeddedUrl, convertVideoLink } from "utils/transformUtils";

class GreetingInfo {
    constructor(info) {
        if (info) {
            this.title = info.Title;
            this.greetingId = info.greetId;
            this.description = info.Description;
            //this.videoUrl = getEmbeddedUrl(info.VideoUrl);
            this.videoUrl = convertVideoLink(info.VideoUrl);
            this.imageUrl = IMAGE_BASE_URL + "/" + info.ImageUrl;
            this.created = info.CreatedDate;
            this.validity = info.Validity;
        }
    }
}

class GreetingsList {
    constructor(info) {
        this.list = [];
        info.map((item) => {
            const greetingItem = new GreetingInfo(item);
            this.list.push(greetingItem)           
        });
    }
}

export default { GreetingInfo };
export { GreetingInfo, GreetingsList}
