import React, { useEffect } from "react";
import { Grid, Button, CardMedia, Box, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import {Link} from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Main as MainLayout } from "layouts";
const PaymentSuccess = () => {
  

  return (
    <MainLayout>
    <Container maxWidth="lg">
      <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12}>
      <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: 5,
            p: 4,
            borderRadius: 1,
            m: 4,
          }}
        >
         

          <CheckCircleIcon sx={{ width: 100, height: 100, color: "green" }} />
          <Typography component="h1" variant="h3">
            Payment Successful
           
          </Typography>

          <Button sx={{mt:5}} component={Link} to="/search-results" variant="contained">
            Continue Shopping 
          </Button>
          
        </Box>
        </Grid>
      </Grid>
    </Container>
    </MainLayout>
  );
};

export default PaymentSuccess;
