import React, { useEffect } from "react";

import { useNavigate } from "react-router";
import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";

import Minimal from "layouts/Minimal";
import { Helmet } from "react-helmet";
import Logo from "components/Logo";
import SignUpMobileOTPVerifyForm from "./SignUpMobileOTPVerifyForm";

function SignUpMobileOTPVerify() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });

  const auth = useSelector((state) => state.auth);

  const signUpOTPSuccess  = useSelector((state) => state.auth.signUpOTPSuccess);
  console.log("signUpOTPSuccess", signUpOTPSuccess)
  const mobile = auth.signUpRegisterMobile;

  // console.log("SignInEmail: auth", auth, " mobile: ", mobile)
  useEffect(() => {}, [auth.signUpRegisterMobile]);

  const navigate = useNavigate();

  // if(signUpOTPSuccess === true){
  //   navigate("/");
  // }
  useEffect(() => {
    if (signUpOTPSuccess === true) {
      window.location.href = "/";
    }
  }, [signUpOTPSuccess]);
  
  const handleSubmitSuccess = () => {
   
  };

  return (
    <Minimal>
      <Helmet>
        <title>SignUp OTP Verify</title>
      </Helmet>

      <Grid
        container
        direction="row"
        mt={3}
      >
        <Grid item container xs={12} md={6} mt={4} >

        </Grid>
        <Grid item container xs={12} md={6} justifyContent="center"
          alignItems="center" alignContent="center">
          <Card sx={{ maxWidth: "sm", borderRadius: 15, boxShadow: 4 }}>
            <CardContent>
              <Grid item xs={12} mb={6}>
                <Logo width="120px" />
                <Typography variant="h5" color={"primary.main"} fontWeight="bold">
                  {" "}
                  Sign Up OTP Verification{" "}
                </Typography>
                <Typography variant={"subtitle2"} mt={2}>
                  Enter OTP code sent to {mobile}
                </Typography>
              </Grid>

              <Grid item container xs={12} maxWidth="sm">

              </Grid>
              <Grid item xs={12}>
                <SignUpMobileOTPVerifyForm
                  onSubmitSuccess={handleSubmitSuccess}
                  mobile={mobile}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </Minimal>
  );
}

export default SignUpMobileOTPVerify;
