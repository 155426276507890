class Review{
    constructor(rating, feedback, orderId, productId ) {
        this.rating = rating;
        this.feedback = feedback;
        this.orderId = orderId;
        this.productId = productId;
    //    this.selectedImages = selectedImages;
    }
}

class ProductReview{
    constructor(review, productId, orderId, rating ) {
        this.review = review;
        this.productId = productId;
        this.orderId = orderId;
        this.rating = rating;
    //    this.imageURL = imageURL;
    }
}

export default { Review };
export { Review, ProductReview };