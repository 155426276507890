import React, { useEffect } from "react";

import { Dashboard as DashboardLayout } from "layouts";
import { Grid, Card, CardMedia, Typography } from "@mui/material";
import { getMyOrdersAction } from "actions/subscribeAction";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_BASE_URL } from "config";
import Container from "@mui/material/Container";
import { DataGrid } from "@mui/x-data-grid";
import { dateFormatter } from "utils/formatUtils";
import { Link } from "react-router-dom"
import { useNavigate, } from "react-router";
import Tooltip from '@mui/material/Tooltip';

import PreviewIcon from '@mui/icons-material/Preview';

function MyOrders() {
  const navigate = useNavigate();
  const myAllOrders = useSelector((state) => state?.order?.myOrders?.result);
  console.log("myAllOrders ", myAllOrders);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMyOrdersAction());
  }, []);

  useEffect(() => { }, [myAllOrders]);

  const columns = [
    { field: "orderId", headerName: "Order Id", width: 90 },
    {
      field: "paymentOrderId",
      headerName: "Payment Order Id",
      width: 150,
      editable: true,
    },
    {
      field: "paymentGateway",
      headerName: "Payment",
      width: 100,
      editable: true,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      // type: 'number',
      width: 150,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      type: "number",
      width: 100,
      editable: true,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      type: "number",
      width: 200,
      editable: true,
      valueGetter: (params) => dateFormatter(params.row.updatedAt),
    },
    {
      field: "",
      type: "number",
      disableClickEventBubbling: true,
      headerName: "Action",
      renderCell: (params) => {

        const onClick = () => {
          const orderId = params.row.orderId
          navigate(`/order-details/${orderId}`);
        };
        return <Tooltip title="View Order details"><PreviewIcon onClick={onClick}/></Tooltip>;
      },
      width: 150,
      // onClick: (params) => {
      //   alert( params.row.updatedAt);
      // }
    }
    // {
    //   field: 'orderAmount',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  return (
    <DashboardLayout>
      <Container sx={{ mb: 5, mt: 5 }} >
        <Grid container sx={{ pt: 5 }}  >
          <Grid items xs={12} sm={12} md={12} sx={{ mb: 2 }}>
            <Typography variant="h3">All Your Orders</Typography>
          </Grid>
          <Grid items xs={12} sx={{ height: 600, width: 800}} >
            <DataGrid
              getRowId={(row) => row.orderId}
              rows={myAllOrders ? myAllOrders : []}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              // checkboxSelection
              disableRowSelectionOnClick
            />
            </Grid>
        </Grid>
      </Container>
    </DashboardLayout>
  );
}

export default MyOrders;
