import React, { useEffect } from "react";
import {
  CardContent,
  CardMedia,
  Grid,
  Card,
  Typography,
  Button,
} from "@mui/material";


function AddressInfo({ address }) {
  useEffect(() => { }, [address]);
  return (
    <Card >
      <CardContent>        
        <Typography>{address?.addressType}: {address?.addressLine1}, {address?.addressLine2}, {address?.city}, {address?.state}, {address?.country}, {address?.pincode} </Typography>  
      </CardContent>
    </Card>
  );
};

export default AddressInfo;
