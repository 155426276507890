import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import ProductBaseInfo from "./ProductBaseInfo";
import ProductDetails from "./ProductDetails";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import {Container} from "@mui/material"

import { Main as MainLayout } from "layouts";
import { fetchProductDetails, fetchProductDetailsBySKU } from "actions/catalogAction";


function PDP({ }) {

  useEffect(()=>{
  window.scrollTo(0, 0)
  },[])
  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { sku } = useParams();
  let product = undefined;
  const [productDetails, setProductDetails ] = useState(null);
  const [variationsList, setVariationsList] = useState('')

  // console.log("productDetails", productDetails)
  if(!sku){
    navigate("/home");
  }

  const getProductDetails = useCallback(async () => {
    // product = await dispatch(fetchProductDetails(sku));
    product = await dispatch(fetchProductDetailsBySKU(sku));
    setProductDetails(product);
    //console.log("getProducts: ", product);
  });

  useEffect(() => {
    // console.log("ProductDetails : ", productDetails);
  },[productDetails]);

  useEffect(() => {
    // console.log(`/product/${id}`);
    getProductDetails();
  }, [sku]);

  // console.log("variationsList", variationsList)

  return (
    <MainLayout>
      <Helmet>
        <title>PDP</title>
      </Helmet>
      <Container maxWidth="lg">
      {/* <Grid container mt={2}>
        <Grid item md={12} sm={12}>
          <ProductBaseInfo product={productDetails ? productDetails : []} />
        </Grid>
        <Grid item md={1} sm={1}></Grid>
        <Grid item md={8} sm={8} mb={3} mt={3}>
          <ProductDetails product={productDetails} />
        </Grid>
      </Grid> */}



      <Grid container mt={2}>
        <Grid item md={12} sm={12} xs={12}>
          <ProductBaseInfo product={productDetails ? productDetails : []} getProductDetails={getProductDetails} sku={sku} variationsList={variationsList}/>
        </Grid>
    
        <Grid item md={12} sm={12} xs={12} sx={{mt:4}}>
          <ProductDetails product={productDetails ? productDetails : []} setVariationsList={setVariationsList} variationsList={variationsList}/>
        </Grid>
      </Grid>
      </Container>
    </MainLayout>
  );
}

export default PDP;
