import { React, useEffect } from "react";
import {
  CardContent,
  CardMedia,
  Grid,
  Card,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router";
import { height, width } from "@mui/system";
import { IMAGE_BASE_URL } from "config";
import { useSelector } from "react-redux";

const CategoryCard = ({ image, price, title, categoryId, item }) => {
  const navigate = useNavigate();

  // console.log("CategoryCard item: ",item);

  const handleCategorySelection = () => {
    
    //navigate(`/category/${categoryId}`)
    navigate(`/category/${categoryId}/${title}`)
  }


useEffect(()=>{

},[item])

const showSqrCategory = useSelector((state)=> state.app.showSqrCategory)
  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item xs={12} 
      // sx={{
      //         transition: "transform 0.5s",
      //         "&:hover": {
      //           transform: "scale(1.1)"
      //         },
      //       }}
            >
        <Card sx={{width: "170px", height: "170px", borderRadius: showSqrCategory === true ? '5px' : '90px', }}>
       
          <CardMedia
            onClick={handleCategorySelection}
            component="img"
            // image = {image ? image : "/assets/defaults/mperito_defualt_product.png" }
            image = {image || '/assets/defaults/mperito_defualt_product.png'}
            sx={{
              width: "170px",
              height: "170px",
               transition: "transform 0.5s",
               "&:hover": {
              transform: "scale(1.5)"
               },
            }}
            // alt={title}
          />
        
          </Card>
        <Grid item xs={12} sx={{mt:2, mb:1}}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            {title.slice(0, 10) || item} {title.length > 10 ? "..." : ""}
            
          </Typography>
          
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CategoryCard;
