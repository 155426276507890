import produce from "immer";
import { FETCH_TESTIMONIALS_SUCCESS } from "../actions/testimonials";
const initialState = {
  testimonialsList: "",
};

const testimonial = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TESTIMONIALS_SUCCESS:
      {
        const list = action.payload;
        return produce(state, (draft) => {
          draft.testimonialsList = list;
        });
      }
      break;
    default:
      {
        return state;
      }
      break;
  }
};
export default testimonial;