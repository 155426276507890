import authService from "services/authService";

import { initializeAuth } from "./initActions";
// import { UserProfile } from "interface/userProfileIf";

export const SIGNIN = "@auth/signin";
export const SIGNIN_SUCCESS = "@auth/signin-success";
export const SIGNIN_FAILURE = "@auth/signin-failure";

export const SILENT_LOGIN = "@auth/silent-login";
export const SIGNOUT = "@auth/signout";

export const SIGNIN_MOBILE_REQ_OTP = "@auth/signinmobilereqotp";
export const SIGNIN_MOBILE_REQ_OTP_SUCCESS =
    "@auth/signinmobilereqotp-success";
export const SIGNIN_MOBILE_REQ_OTP_FAILURE =
    "@auth/signinmobilereqotp-failure";

export const SIGNIN_MOBILE_OTP_VERIFY = "@auth/signinmobileotpverify";
export const SIGNIN_MOBILE_OTP_VERIFY_SUCCESS =
    "@auth/signinmobileotpverify-success";
export const SIGNIN_MOBILE_OTP_VERIFY_FAILURE =
    "@auth/signinmobileotpverify-failure";

export const SIGNUP_MOBILE = "@auth/signupmobile";
export const SIGNUP_MOBILE_SUCCESS = "@auth/signupmobile-success";
export const SIGNUP_MOBILE_FAILURE = "@auth/signupmobile-failure";

export const SIGNUP_MOBILE_VERIFY_OTP = "@auth/signupmobileverifyotp-otp";
export const SIGNUP_MOBILE_VERIFY_OTP_SUCCESS = "@auth/signupmobileverifyotp-success";
export const SIGNUP_MOBILE_VERIFY_OTP_FAILURE= "@auth/signupmobileverifyotp-failure";

export const SIGNUP_RESEND_OTP = "@auth/signup-resend-otp";
export const SIGNUP_RESEND_OTP_SUCCESS = "@auth/signup-resend-otp-success";
export const SIGNUP_RESEND_OTP_FAILURE = "@auth/signup-resend-otp-failure";

export function signIn(email, password) {
    return async (dispatch) => {
        try {
            dispatch({ type: SIGNIN });
            const user = await authService.signinWithEmailAndPassword(
                email,
                password
            );

            dispatch({
                type: SIGNIN_SUCCESS,
                payload: {
                    user,
                },
            });

            // Initialize User Information
            dispatch(initializeAuth());

        } catch (error) {
            dispatch({ type: SIGNIN_FAILURE });
            throw error;
        }
    };
}

export function setUserData(user) {
    return (dispatch) =>
        dispatch({
            type: SILENT_LOGIN,
            payload: {
                user,
            },
        });
}

export function signOut() {
    return async (dispatch) => {
        authService.signOut();

        dispatch({
            type: SIGNOUT,
        });
    };
}


export function signInMobile(mobile) {
    return async (dispatch) => {
        try {
            dispatch({
                type: SIGNIN_MOBILE_REQ_OTP,
                payload: {
                    mobile,
                },
            });

            const payload = await authService.signinMobileReqOTP(mobile);

            dispatch({
                type: SIGNIN_MOBILE_REQ_OTP_SUCCESS,
                payload,
            });

        } catch (err) {

            let errMsg = err?.response?.data?.message;
            const error = { message: errMsg };

            dispatch({ type: SIGNIN_MOBILE_REQ_OTP_FAILURE });
            throw error;
        }
    };
}

export function signInMobileVerifyOTP(mobile, otp) {
    return async (dispatch) => {
        try {
            dispatch({
                type: SIGNIN_MOBILE_OTP_VERIFY,
                payload: {
                    mobile,
                },
            });
            const user = await authService.signinMobileVerifyOTP(mobile, otp);

            dispatch({
                type: SIGNIN_MOBILE_OTP_VERIFY_SUCCESS,
                payload: {
                    user,
                },
            });

            // Initialize User Information
            dispatch(initializeAuth());
        } catch (error) {
            // console.log("AuthAction.signInWithMobileOTP: Error", error);

            // let errMsg = err?.response?.data?.message;
            // const error = { message: errMsg };

            dispatch({ type: SIGNIN_MOBILE_OTP_VERIFY_FAILURE });
            throw error;
        }
    };
}


export function signUpMobile(name, mobile, email) {
    return async (dispatch) => {
        try {
            dispatch({
                type: SIGNUP_MOBILE,
                payload: {
                    name,
                    mobile,
                    email,
                },
            });
            // console.log("AuthAction.signupMobile:", name, mobile);

            const newUser = await authService.signUp(name, mobile, email);
            //console.log("signUpMobile() ", newUser);

            const user = await authService.signUpMobile(name, mobile);

            // console.log("AuthAction.signupMobile: Resp:" , user);

            dispatch({
                type: SIGNUP_MOBILE_SUCCESS,
                payload: {
                    user,
                    newUser
                },
            });

        } catch (error) {
            // console.log("AuthAction.signupMobile: Error", error);
            dispatch({ type: SIGNUP_MOBILE_FAILURE });
            throw error;
        }
    };
}

export function signUpMobileVerifyOTP(mobile, name, otp) {
    return async (dispatch) => {
        try {
            dispatch({ type: SIGNUP_MOBILE_VERIFY_OTP });
            const user = await authService.signUpMobileVerifyOTP(mobile, name, otp);
            
            dispatch({
                type: SIGNUP_MOBILE_VERIFY_OTP_SUCCESS,
                payload: {
                    user,
                },
            });

            // Initialize User Information
            dispatch(initializeAuth());

        } catch (error) {
            dispatch({ type: SIGNUP_MOBILE_VERIFY_OTP_FAILURE });
            throw error;
        }
    };
}

export function signUpResendOTP(mobile) {
    return async (dispatch) => {
        try {
            dispatch({ type: SIGNUP_RESEND_OTP });
            const otp = await authService.signUpResendOTP(mobile);
            
            dispatch({
                type: SIGNUP_RESEND_OTP_SUCCESS,
                payload: {
                    otp,
                },
            });

            // Initialize User Information
            // dispatch(initializeAuth());

        } catch (error) {
            dispatch({ type: SIGNUP_RESEND_OTP_FAILURE });
            throw error;
        }
    };
}


