import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import KeepTouch from "./components/KeepTouch";
import UsefullLink from "./components/UsefullLink";
import CustomerPolicies from "./components/CustomerPolicies";
import ContactForm from "./components/ContactForm";
import { getSocialLinks } from "utils/socialUtils";

function Footer() {

    const brand = useSelector((state) => state.brand);
    const app = useSelector((state) => state.app);
    const socialLinks = brand?.socialLinks 
    const socialIconList = getSocialLinks(socialLinks);

    useEffect(() =>{

    },[socialLinks, app.logoUrl]);

    return (
        <Grid container>
            <Grid item xs={12} p={'30px'} bgcolor="#1459BA25">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <KeepTouch socialIconList={socialIconList} logoUrl={app.logoUrl}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                        <UsefullLink />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                        <CustomerPolicies />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <ContactForm />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Footer;
