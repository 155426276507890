import React from "react";

import HeroSlider from "./components/HeroSlider";

function HeroSection({carouselItems}) {
  return (
        <HeroSlider carouselItems={carouselItems} />
  );
}

export default HeroSection;
