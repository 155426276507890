import React from "react";

import { Container, Grid, Typography } from "@mui/material";

function AboutSection({ content }) {

    return (
        <Container maxWidth >
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Typography component="h1" variant="h3" >
                        About Us
                    </Typography>
                    <br /> <br />
                    <Typography variant="body" >
                        {content}
                    </Typography>
                    <br /> <br />
                </Grid>
            </Grid>
        </Container >
    );
}

export default AboutSection;
