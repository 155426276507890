import { NewsList } from "interface/newIf";
import { GreetingsList } from "interface/greetingIf";
import TestimonialsService from "services/testimonialsService";

export const FETCH_TESTIMONIALS = "@testimonials/testimonoals";
export const FETCH_TESTIMONIALS_SUCCESS = "@testimonials/testimonials-success";
export const FETCH_TESTIMONIALS_FAILURE = "@testimonials/testimonials-failure";



export function getTestimonialsAction() {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_TESTIMONIALS });
            const testimonials = await TestimonialsService.testimonials();

            console.log("fetchCustomerDetails(): ", testimonials);
            dispatch({ type: FETCH_TESTIMONIALS_SUCCESS, payload: { testimonials } });
        } catch (error) {
            dispatch({ type: FETCH_TESTIMONIALS_FAILURE });
        }
    };
}



