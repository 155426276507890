import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { Main as MainLayout } from "layouts";

import { Grid } from "@mui/material";

import { CategorySection, HeroSection, NewsSection, RecentProductsSection, VideoSection, GreetingsSection } from "./components";
import Testimonial from "views/Testimonial";
import { REACT_APP_NAME } from "config";
import {fetchCategories} from "actions/catalogAction";
import NewHeroSection from "./components/HeroSection/NewHeroSection"

function Home() {

    const cms = useSelector((state) => state.cms);
    const catalog = useSelector((state) => state.catalog);

    const carouselItems = cms?.homeCarousel ? cms?.homeCarousel : [];
    const newsList = cms?.newsList ? cms?.newsList : [];
    const greetings = cms?.greetings ? cms?.greetings : [];
    const videos = cms?.videos ? cms?.videos : [];
    const recentProducts = catalog?.latestProducts ? catalog?.latestProducts : [];
    const categories = catalog?.categories ? catalog?.categories : []

    // console.log("Home: greetings : ", greetings);
    const list =  useSelector((state)=> state.testimonial?.testimonialsList?.testimonials?.testimonies)

    useEffect(() => {
    }, [carouselItems, newsList, greetings, recentProducts, categories]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCategories())
    }, []);
  
    return (
        <MainLayout>
            <Helmet>
                <title>{REACT_APP_NAME} - Home</title>
                
            </Helmet>
            <Grid container spacing={6} >
                <Grid item xs={12}>
                    {/* <HeroSection carouselItems={carouselItems} /> */}
                    <NewHeroSection carouselItems={carouselItems}/>
                </Grid>
                <Grid item xs={12}>
                    <CategorySection categories={categories} />
                </Grid>
                <Grid item xs={12}>
                    <RecentProductsSection recentProducts={recentProducts} />
                </Grid>
                {/* {list?.length > 0 &&  
                    <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Testimonial/>
                    </Grid> 
                } */}
                <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Testimonial/>
                    </Grid> 
                {
                    greetings?.length &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <GreetingsSection greetings={greetings} />
                    </Grid>

                }

                {/* <Grid item xs={12}>
                <VideoSection videos={videos} />
            </Grid> */}
                {
                    newsList?.length &&
                    <Grid item xs={12}>
                        <NewsSection newsList={newsList} />
                    </Grid>
                }
            </Grid>
        </MainLayout>
    );
}

export default Home;