import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Card,
  Container,
  CardMedia,
  Box,
  Typography,
  Alert,
  Snackbar
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getQuotationByUser } from "actions/cartAction";
import { List } from "react-feather";

import { useNavigate } from "react-router";

const SendGetQuote = ({ cart, shippingAdd, billingAdd }) => {
  const AppbusinessName = useSelector((state) => state.app);
  const getQuote = useSelector((state) => state.cart.getQuoteSuccess);
  const getQuoteFailureAlert = useSelector((state) => state.cart.getQuoteFailure);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

const [redirect, setRedirect] = useState(false)
  // function getCart() {
  //   const values = 
  //   cart?.items.map((list) => (
  //     <>
  //     <p>{list.productId}</p>
     
  //     </>
  //   ))
  // console.log("values12", values);
  // }
  const [cartItems, setCartItems] = useState([]);
  
  const redirectOnSuccess =()=>{
    if(redirect == true){
      navigate("/quotation-list")
     }else{
      // setRedirect(false)
     }
  }

  useEffect(() => {
    if (cart?.items) {
      // Extract product IDs and quantities from the cart items
      const items = cart.items.map((item) => ({
        productId: item.productId,
        quantity: item.quantity,
      }));

      setCartItems(items);
    }
    if(getQuote == true){
      setRedirect(true)
    }else{
      setRedirect(false)
    }
    redirectOnSuccess()
  }, [cart, getQuoteFailureAlert, redirect]);
  const items = cartItems
  console.log("quantity1", cartItems)

 
  


  const shippingAddress = {
    address: {
      name: shippingAdd?.name,
      addressLine1: shippingAdd?.addressLine1,
      addressLine2: shippingAdd?.addressLine2,
      city: shippingAdd?.city,
      state: shippingAdd?.state,
      country: shippingAdd?.country,
      pinCode: shippingAdd?.pinCode,
      mobile: shippingAdd?.mobile,
      email: shippingAdd?.email,
    },
  };
  const billingAddress = {
    address: {
      name: billingAdd?.name,
      addressLine1: billingAdd?.addressLine1,
      addressLine2: billingAdd?.addressLine1,
      city: billingAdd?.city,
      state: billingAdd?.state,
      country: billingAdd?.country,
      pinCode: billingAdd?.pinCode,
      mobile: billingAdd?.mobile,
      email: billingAdd?.email,
    },
  };
  const businessName = AppbusinessName?.appName;
  const gstin = AppbusinessName?.gstin;
  
  

  const getQuotation = () => {
     dispatch(
        getQuotationByUser(
          items,
          shippingAddress,
          billingAddress,
          businessName,
          gstin
        )
      );
  };
  const showQuotation = useSelector((state)=> state.app.showQuotation);

  return (
    <>
    {showQuotation && 
      <Button
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        sx={{ borderRadius: 30, mt:2 }}
        onClick={getQuotation}
      >
        Get Quote
      </Button>
    }
      {getQuoteFailureAlert && 
          <Alert autoHideDuration={5000} severity="error">Please Try After Some Time</Alert>      
    }
      <div>
      
    </div>
    </>
  );
};

export default SendGetQuote;
