import React, { useEffect, useState } from "react";
import { Dashboard as DashboardLayout } from "layouts";
import Container from "@mui/material/Container";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  Box,
  Card,
  FormHelperText,
  TextField,
  Grid,
  Button,
  CardHeader,
  Divider,
  CardContent,
  DialogTitle,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { AccountCircle, Call, Email } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { editProfileAction } from "../../actions/addressAction";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
function ProfileForm({
  name,
  email,
  mobile,
  gstin,
  handleEdit,
  signInMobile,
  ...rest
}) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const successCall = () => {
    setOpen(true);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 13, mb: 5 }}>
      <Grid item xs={12} md={12} xl={12}>
        <Card sx={{ width: 1 }}>
          <CardHeader
            title=<div>
              Personal Information{" "}
              {/* <button onClick={setShowForm (false)}>Back</button> */}
            </div>
            onClick={handleEdit}
          ></CardHeader>
          <Divider />
          <CardContent>
            <Formik
              initialValues={{
                name: name,
                email: email,
                mobile: mobile,
                // userType: "User",
                GSTN: gstin,
                // businessName: "test business",
                // companyPan:"PPD712345"
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Please Enter your name"),
                mobile: Yup.string()
                  .matches(
                    /^[6-9][0-9]{9}/,
                    "Please enter valid 10 digit mobile number"
                  )
                  .min(10)
                  .max(10)
                  .required("Please Enter your mobile"),
                email: Yup.string()
                  .email("Invalid email address")
                  .required("Required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  await dispatch(editProfileAction(values));
                  successCall();
                } catch (error) {
                  // console.log("Form: error", error);

                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit} {...rest}>
                  <Grid container spacing={4} maxWidth="xl">
                    <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                      <TextField
                        name="name"
                        id="name"
                        label="Name"
                        value={values.name}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                      <TextField
                        disabled
                        name="mobile"
                        id="mobile"
                        label="Mobile"
                        value={values.mobile}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{ maxLength: 10 }}
                        error={Boolean(touched.mobile && errors.mobile)}
                        helperText={touched.mobile && errors.mobile}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        name="email"
                        id="email"
                        label="Email"
                        value={values.email}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        name="GSTN"
                        id="GSTN"
                        label="GSTN"
                        value={values.GSTN}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // inputProps={{ maxLength: 10 }}
                        error={Boolean(touched.GSTN && errors.GSTN)}
                        helperText={touched.GSTN && errors.GSTN}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        disabled
                        name="userType"
                        id="userType"
                        label="User Type"
                        // value={values.userType}
                        defaultValue="User"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // inputProps={{ maxLength: 10 }}
                        error={Boolean(touched.userType && errors.userType)}
                        helperText={touched.userType && errors.userType}
                      />
                    </Grid>

                    {/* <Grid item xs={12}>
                      <TextField
                        name="businessName"
                        id="businessName"
                        label="Business Name"
                        value={values.businessName}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{ maxLength: 10 }}
                        error={Boolean(touched.businessName && errors.businessName)}
                        helperText={touched.businessName && errors.businessName}
                      />
                    </Grid> */}

                    {/* <Grid item xs={12}>
                      <TextField
                        name="companyPan"
                        id="companyPan"
                        label="Company Pan"
                        value={values.companyPan}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{ maxLength: 10 }}
                        error={Boolean(touched.companyPan && errors.companyPan)}
                        helperText={touched.companyPan && errors.companyPan}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12} sm={6} md={6}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{ borderRadius: 30 }}
                      >
                        Save
                      </Button>
                    </Grid>

                    <Grid
                      item
                      xs={12} sm={6} md={6}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Button
                        onClick={handleEdit}
                        //  onClick={handleShowForm}
                        color="primary"
                        fullWidth
                        size="large"
                        type="button"
                        variant="contained"
                        sx={{ borderRadius: 30 }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
        <Dialog
          sx={{ color: "green" }}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>Profile Update successfully</Typography>
            <CheckCircleIcon sx={{ width: 30, height: 30, color: "green" }} />
          </DialogContent>
        </Dialog>
      </Grid>
    </Container>
  );
}

export default ProfileForm;
