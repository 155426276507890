import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import Logo from "components/Logo";
import NavItem from "./NavItem";

import UserNavConfig from "./UserNav";

function renderNavItems({ items, activeRole, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, activeRole, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, activeRole, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(
      {
        path: item.href,
        exact: false,
      },
      pathname
    );

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        role={item.role}
        activeRole={activeRole}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          activeRole,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        role={item.role}
        activeRole={activeRole}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
    marginTop: 70,
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user, activeRole, businessInfo } = useSelector((state) => state.auth);
  // console.log("NavBar: user:", user);
  // console.log("NavBar: activeRole:", activeRole);
  // console.log("NavBar: businessInfo:", businessInfo);

  // TODO_SP: Implementation of the Platform Admin login to be done here
  const navConfig = UserNavConfig; //AdminNavConfig;
  const account = useSelector((state) => state.auth);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo height="60px" />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink 
            // to="/app/account" 
            to="/">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user?.avatar}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              // to="/app/account"
              to="/"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user?.name ? user?.name : "" }`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {activeRole}
            </Typography>
          </Box>
        </Box>
        <Divider />
        {account?.user ? (
          <Box p={2}>
            {navConfig
              .filter(function (nConfig) {
                if (
                  nConfig.businesstype === undefined ||
                  nConfig.businesstype === ""
                ) {
                  return true;
                }
                /*if (nConfig.businesstype === user.businessInfo.type) {
                return true;
              } */

                let typeFlag = nConfig.businesstype.filter(
                  (btype) => btype === businessInfo?.type
                );
                if (typeFlag.length > 0) {
                  return true;
                }

                return false;
              })
              .map((config) => (
                <List
                  key={config.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {config.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: config.items,
                    pathname: location.pathname,
                    activeRole: `${activeRole}`,
                  })}
                </List>
              ))}
          </Box>
        ) : (
          ""
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
