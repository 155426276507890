import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import { Card, Grid, CircularProgress } from "@mui/material";
import Container from "@mui/material/Container";

import { useSelector, useDispatch } from "react-redux";
import SubCategory from "./SubCategory";
import {
  fetchCategories,
  getAllCategoryForHeader,
} from "actions/catalogAction";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
const CustomTab = ({ label, onClick, onMouseEnter, isActive }) => (
  <div
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    style={{
      cursor: "pointer",
      padding: "10px",
      width:"100px",
      maxWidth:'100px',
      borderBottom: isActive ? "2px solid blue" : "none",
      color: isActive ? "blue" : "black",
    }}
  >
    {label}
  </div>
);

const CategoryBar = () => {
  const dispatch = useDispatch();
  const catalog = useSelector((state) => state.catalog);
  const categories = catalog?.categories ? catalog?.categories : [];

  const [activeTab, setActiveTab] = useState(0);
  const [headerCategory, setHeaderCategory] = useState(null);
  const [showCard, setShowCard] = useState(false);
  const [subCategories, setSubCategories] = useState(null);
  const [id, setId] = useState("");
  const [value, setValue] = useState(0);
  const [categoryProducts, setCategoryProducts] = useState(categories);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 375, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 414, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 390, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 7,
          slidesToScroll: 3,
        },
      },
      
      {
        breakpoint: 540, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 280, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1200, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1920, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 11,
          slidesToScroll: 3,
        },
      },
    ],
  };


  const [loading, setLoading] = useState(false);

  const getSubCategory = async (index, categoryId) => {
    setLoading(true);
    try {
      setActiveTab(index);
      setValue(index);
      setId(categoryId);
      setShowCard(true);
      const subCategories = await dispatch(fetchCategories(categoryId));
      if (subCategories?.children?.length > 0) {
        setSubCategories(subCategories?.children);
      } else {
        setSubCategories([]);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    } finally {
      setLoading(false);
    }
  };

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

useEffect(() => {
    const fetchData = async () => {
      const allCategory = await dispatch(getAllCategoryForHeader());
      setHeaderCategory(allCategory);
    };
    fetchData();
  }, []);


  useEffect(() => {
  }, [subCategories]);
  
    console.log("headerCategory", headerCategory);
  return (
    <>
        <AppBar position="fixed" sx={{ backgroundColor: "white", mt: isMobileView ? 16 :10 }}>
        <Container maxWidth="xl">
          <Grid container >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Slider {...settings}>
                {headerCategory?.map((tab, index) => (
                <div key={index}>
                    <CustomTab 
                      label={<Typography variant="body1" sx={{textTransform:"lowercase", color:'gray', fontWeight:100}}>{tab.name.slice(0, 10)}</Typography>}
                      onClick={() => getSubCategory(index, tab.categoryId)}
                      onMouseEnter={() => getSubCategory(index, tab.categoryId)}
                      isActive={activeTab === index}
                    />
                  </div>
                ))}
              </Slider>
            </Grid>
          </Grid>
          </Container>
          <Container
        maxWidth="xl"
        sx={{
            alignItems: "center",
            zIndex: "1000",
            position: "fixed",
            marginTop:'255px',
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        onMouseLeave={() => setShowCard(false)}
      >
       
        {showCard && subCategories?.length > 0 && (
          <Card elevation={5} sx={{ borderRadius: "0px", height:"500px" }}>
          {loading && <CircularProgress  sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}/>}
            <SubCategory categories={subCategories} setShowCard={setShowCard}/>
          </Card>
        )}
      </Container>
        </AppBar>     
    </>
  );
};

export default CategoryBar;
