import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MainCart from "./MainCart";
import { useSelector } from "react-redux";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";
export default function CartDrawer() {
  const cartItems = useSelector((state) => state?.cart?.items);
  
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.up('sm'));
  const [state, setState] = React.useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    
<div>
  {["right"].map((anchor) => (
    <React.Fragment key={anchor}>
      <Badge
        badgeContent={cartItems?.length}
        color="secondary"
        onClick={toggleDrawer(anchor, true)}
      >
        <ShoppingCartIcon />
      </Badge>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: (isMobileView ? 550 : 280),
            overflowY: "scroll",
          },
        }}
      >
        <MainCart />
      </Drawer>
    </React.Fragment>
  ))}
</div>
  );
}
