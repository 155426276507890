import React, { useState } from "react";

import { Main as Main } from "layouts";
import { Grid, TextField, Card, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";


function PyaU() {

  var localStoragePayu = JSON.parse(localStorage.getItem("payUData"));

  return (
    <Main>
      <Container maxWidth="md" sx={{ mb: 5, mt:10 }}>
        <form method="post" action="https://test.payu.in/_payment">
          <input name="key" type="hidden" value="B5Lodx" />
          <input
            name="txnid"
            type="hidden"
            value={localStoragePayu?.paymentOrderRef?.id}
          />
          <input
            name="amount"
            type="hidden"
            value={localStoragePayu.paymentDetails.totalAmount}
          />
          <input
            name="productinfo"
            type="hidden"
            value={localStoragePayu.description}
          />
          <input
            name="firstname"
            type="hidden"
            value={localStoragePayu?.name}
          />
          <input name="email" type="hidden" value={localStoragePayu.email} />
          <input name="surl" type="hidden" value="https://mperito.com/" />
          <input
            name="furl"
            type="hidden"
            value="https://test-payment-middleware.payu.in/simulatorResponse"
          />
          <input
            name="hash"
            type="hidden"
            value={localStoragePayu?.paymentOrderRef?.hash}
          />
          <input
            name="salt"
            type="hidden"
            value="9kzuKYLJMSbz1me8yx3uKr8j4lipB3sg"
          />
          <input name="service_provider" type="hidden" value="payu_paisa" />

          {/* <input name="merchantId"      type="hidden"  value="508029"   />
            <input name="accountId"       type="hidden"  value="512321" />
            <input name="description"     type="hidden"  value="Test PAYU"  />
            <input name="referenceCode"   type="hidden"  value="TestPayU" />
            
            <input name="tax"             type="hidden"  value="3193"  />
            <input name="taxReturnBase"   type="hidden"  value="16806" />
            <input name="currency"        type="hidden"  value="COP" />
            <input name="signature"       type="hidden"  value="7ee7cf808ce6a39b17481c54f2c57acc"  />
            <input name="test"            type="hidden"  value="0" />
            <input name="buyerEmail"      type="hidden"  value="test@test.com" />
            <input name="responseUrl"     type="hidden"  value="http://www.test.com/response" />
            <input name="confirmationUrl" type="hidden"  value="http://www.test.com/confirmation" /> */}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ borderRadius: 2 }}
          >
            Process to payment
          </Button>
        </form>
      </Container>
    </Main>
  );
}

export default PyaU;
