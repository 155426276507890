import {
    BarChart as BarChartIcon,
    PieChart as PieChartIcon,
    Users as UsersIcon,
    ShoppingCart as OrderMgmtIcon,
  } from 'react-feather';
  
  import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
  
  import { BUSINESS_TYPES, USER_ROLES } from 'config';
  
  const UserNavConfig = [
    {
      subheader: 'Reports',
      businesstype: [
        BUSINESS_TYPES.GENERAL,
      ],
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/dashboard',
          role: [
            USER_ROLES.OWNER, USER_ROLES.SALES, USER_ROLES.USER
          ],
        },
        {
          title: 'Dashboard Financial',
          icon: BarChartIcon,
          href: '/app/dashboards/dashboard-financial',
          role: [
            USER_ROLES.OWNER
          ],
        },
      ]
    },
    {
      subheader: 'Profile',
      businesstype: [
        BUSINESS_TYPES.GENERAL],
      items: [
        {
          title: 'Profile',
          icon: UsersIcon,
          href: '/profile',
          items: [
            {
              title: 'Manage Your Profile',
              href: '/profile',
              role: [
                USER_ROLES.OWNER, USER_ROLES.SALES, USER_ROLES.USER
              ],
            },
            {
              title: 'Manage Your Address',
              href: '/profile',
              role: [
                USER_ROLES.OWNER, USER_ROLES.SALES, USER_ROLES.USER
              ],
            },
            {
              title: 'Favourites',
              href: '/favourite',
              role: [
                USER_ROLES.OWNER, USER_ROLES.SALES, USER_ROLES.USER
              ],
            }
          ]
        },      
      ]
    },
    {
      subheader: 'Management',
      businesstype: [
        BUSINESS_TYPES.GENERAL],
      items: [
        {
          title: 'Orders',
          icon: OrderMgmtIcon,
          href: '/app/mgmt/orders',
          items: [
            {
              title: 'My Orders',
              href: '/my-orders',
              role: [
                USER_ROLES.OWNER, USER_ROLES.SALES, USER_ROLES.USER
              ],
            },
            {
              title: 'My Invoices',
              href: '/invoice-list',
              role: [
                USER_ROLES.OWNER, USER_ROLES.SALES, USER_ROLES.USER
              ],
            }
          ]
        },     
      ]
    },
  ];
  
  export default UserNavConfig;
  