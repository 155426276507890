import { NewsList } from "interface/newIf";
import { GreetingsList } from "interface/greetingIf";
import contentService from "services/contentService";

export const FETCH_BANNERS = "@content/banners";
export const FETCH_BANNERS_SUCCESS = "@content/banners-success";
export const FETCH_BANNERS_FAILURE = "@content/banners-failure";

export const FETCH_LOGO = "@content/logo";
export const FETCH_LOGO_SUCCESS = "@content/logo-success";
export const FETCH_LOGO_FAILURE = "@content/logo-failure";

export const FETCH_POLICY = "@content/policy";
export const FETCH_POLICY_SUCCESS = "@content/policy-success";
export const FETCH_POLICY_FAILURE = "@content/policy-failure";

export const FETCH_NEWS = "@content/news";
export const FETCH_NEWS_SUCCESS = "@content/news-success";
export const FETCH_NEWS_FAILURE = "@content/news-failure";

export const FETCH_GREETINGS = "@content/greetings";
export const FETCH_GREETINGS_SUCCESS = "@content/greetings-success";
export const FETCH_GREETINGS_FAILURE = "@content/greetings-failure";

export const FETCH_ABOUT_US = "@content/aboutus";
export const FETCH_ABOUT_US_SUCCESS = "@content/aboutus-success";
export const FETCH_ABOUT_US_FAILURE = "@content/aboutus-failure";

export const FETCH_CUSTOMER_DETAILS = "@content/customer-details";
export const FETCH_CUSTOMER_DETAILS_SUCCESS = "@content/customer-details-success";
export const FETCH_CUSTOMER_DETAILS_FAILURE = "@content/customer-details-failure";

export function fetchCustomerDetails() {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_CUSTOMER_DETAILS });
            const customerDetails = await contentService.fetchCustomerDetails();

            console.log("fetchCustomerDetails(): ", customerDetails);
            dispatch({ type: FETCH_CUSTOMER_DETAILS_SUCCESS, payload: { customerDetails } });
        } catch (error) {
            dispatch({ type: FETCH_CUSTOMER_DETAILS_FAILURE });
        }
    };
}



export function fetchBanners() {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_BANNERS });
            const banners = await contentService.fetchBanners();

            dispatch({ type: FETCH_BANNERS_SUCCESS, payload: { banners } });
        } catch (error) {
            dispatch({ type: FETCH_BANNERS_FAILURE });
        }
    };
}


export function fetchLogo() {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_BANNERS });

            const banners = await contentService.fetchBanners();

            dispatch({ type: FETCH_BANNERS_SUCCESS, payload: banners });
        } catch (error) {
            dispatch({ type: FETCH_BANNERS_FAILURE });
        }
    };
}

export function fetchPolicy() {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_POLICY });

            const policies = await contentService.fetchPolicy();
            console.log("fetchPoliciesAction() ", policies);

            dispatch({ type: FETCH_POLICY_SUCCESS, payload: { policies } });
        } catch (error) {
            dispatch({ type: FETCH_POLICY_FAILURE });
        }
    };
}

export function fetchNews() {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_NEWS });

            const newsData = await contentService.fetchNews();
            console.log("fetchNews() ", newsData);

            //const newsList = new NewsList(newsData)
            const newsList = new NewsList(newsData)

            //console.log("fetchNews() ", newsList);

            dispatch({ type: FETCH_NEWS_SUCCESS, payload: { newsList } });
        } catch (error) {
            dispatch({ type: FETCH_NEWS_FAILURE });
        }
    };
}

export function fetchGreetings() {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_GREETINGS });

            const greetingsData = await contentService.fetchGreetings();
            console.log("fetchGreetings() ", greetingsData);

            //const newsList = new NewsList(newsData)
            const greetingsList = new GreetingsList(greetingsData)

            //console.log("fetchNews() ", newsList);

            dispatch({ type: FETCH_GREETINGS_SUCCESS, payload: { greetingsList } });
        } catch (error) {
            dispatch({ type: FETCH_GREETINGS_FAILURE });
        }
    };
}


export function fetchAboutUs() {
    return async (dispatch) => {
        try {
            dispatch({ type: FETCH_ABOUT_US });

            const aboutUs = await contentService.fetchAboutUs();
            console.log("fetchAboutUs() ", aboutUs);
            dispatch({ type: FETCH_ABOUT_US_SUCCESS, payload: { aboutUs } });

        } catch (error) {
            dispatch({ type: FETCH_ABOUT_US_FAILURE });
        }
    };
}
