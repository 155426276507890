import React from "react";
import { Grid, Card } from "@mui/material";
import CartRight from "./CartRight";

import { Main as MainLayout } from "layouts";
import MainCart from "./MainCart"

const Cart = [
  {
    brand: "airFryer",
    Number: "SkuairFryerCust63",
    company: "Kent",
    prise: "1",
    qty: 1,
  },
  {
    brand: "airFryer",
    Number: "SkuairFryerCust63",
    company: "Kent",
    prise: "1",
    qty: 1,
  },
  {
    brand: "airFryer",
    Number: "SkuairFryerCust63",
    company: "Kent",
    prise: "1",
    qty: 1,
  },
  
];

const CartPage = () => {
  return (
    <MainLayout>
    <Card sx={{ borderRadius: 5, p: 2, backgroundColor:'#eaeded', p:3 }} >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={9}>
          <MainCart cart={Cart}/>
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <CartRight cart={Cart}/>
        </Grid>
      </Grid>
    </Card>
    </MainLayout>
  );
};

export default CartPage;
