import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import {Typography, Box, Button, TextField} from '@mui/material';
import {onFacetSelection, setPriceInReducer} from "../../../actions/searchAction";
import { useDispatch, useSelector } from "react-redux";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
const PriceRangeSlider = ({applyRangeFilter,priceRange, setPriceRange, maxPrise,  minPrise}) => {

  const price = useSelector((state)=>state?.search?.rangeSliderPrice)

//   const [priceRange, setPriceRange] = useState([price[0], price[1]]); // Initial price range

  const dispatch = useDispatch()

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
    dispatch(setPriceInReducer(newValue))
  };

  applyRangeFilter=()=>{
    dispatch(onFacetSelection("", priceRange[0], priceRange[1]));
  }

//   useEffect(()=>{
//     handlePriceChange()
//   },[])
  return (
    <div>
      <Typography variant="searchFilterCardTitle" gutterBottom>
        Price
      </Typography>

      <Box sx={{ width: 220 }}>
      <Slider
        value={priceRange}
        onChange={handlePriceChange}
        valueLabelDisplay="auto"
        aria-labelledby="price-range"
        min={minPrise}
        max={maxPrise}
      />
      </Box>
      {/* <Box>
        <TextField placeHolder="Min" />
        <TextField placeHolder="Max" />
      </Box> */}
      <Typography variant="h5" >
        Price: <CurrencyRupeeIcon sx={{fontSize: 18 }}/>{price[0] ? price[0]: priceRange[0] } - <CurrencyRupeeIcon sx={{fontSize: 18 }}/>{price[1] ? price[1] :priceRange[1] }
      </Typography>
      <Box>
      </Box>
    </div>
  );
};

export default PriceRangeSlider;
