import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useDispatch, useSelector } from "react-redux";

import SearchBar from "../SearchBar";
import { Logo } from "components";
import { Search } from "react-feather";
import CartDrawer from "../../../../views/Cart/CartDrawer";
import FavoriteDrawer from "../../../../views/Cart/FavoriteDrawer";
import { REACT_APP_NAME } from "config";

import { profileMenu } from "layouts/Dashboard/components/TopBar/menuData";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Notifications from "../../../../views/Notifications/Notifications"
// import CategoryBar from "./CategoryBar";
// const pages = ["Products", "Pricing"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

// const profileMenu = [
//   { label: "Profile", path: "/profile" },
//   { label: "My Account", path: "/account" },
//   { label: "My Orders", path: "/my-orders" },
//   { label: "My Invoice", path: "/invoice-list" },
//   { label: "Sign Out", path: "/signout" },

// ]

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const account = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);

  const isAuthorized = account.isAuthorized;

  const appName = app.appName ? app.appName : REACT_APP_NAME;
  const appLogo = app.logoUrl;

  // console.log("account", account);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {}, [app, account]);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
    <AppBar position="fixed" >
      <Container maxWidth="xl" sx={{ pb: 2 }}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {account?.user ? (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={onMobileNavOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            ) : (
              ""
            )}

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {/* {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))} */}
            </Menu>
          </Box>
          <RouterLink to="/">
          <Logo height={isMobileView ? "28" : "50"} logoUrl={appLogo} />
          </RouterLink>
          {isMobileView ? (
            ""
          ) : (
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {appName}
            </Typography>
          )}

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          ></Typography>
          {/* <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box> */}
          <Box
            sx={{
              ml: 1,
              mr: 1,
              flexGrow: 2,
              display: { xs: "none", sm: "flex", md: "flex" },
            }}
          >
            <SearchBar />
          </Box>
          <Box
            sx={{
              ml: 1,
              mr: 1,
              display: { xs: "flex", sm: "flex", md: "flex" },
            }}
          >
            
            <Notifications/>
          
          </Box>
          <Box
            sx={{
              ml: 1,
              mr: 1,
              display: { xs: "flex", sm: "flex", md: "flex" },
            }}
          >
            {isAuthorized && <CartDrawer />}
          </Box>
          <Box
            sx={{
              ml: 1,
              mr: 1,
              display: { xs: "flex", sm: "flex", md: "flex" },
            }}
          >
            {isAuthorized && <FavoriteDrawer />}
          </Box>
          <Box>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                {isAuthorized && <AccountCircle />}

                {account?.user ? (
                  <Typography variant="h6" color="inherit" sx={{ ml: 1 }}>
                    {isMobileView ? "" : `${account?.user?.avatar}`}
                  </Typography>
                ) : (
                  <Button
                    //color="primary"
                    component={RouterLink}
                    to="/signin"
                    variant="contained"
                    size="medium"
                  >
                    Sign In
                  </Button>
                )}
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {profileMenu.map((item) => (
                  <MenuItem
                    onClick={handleClose}
                    component={RouterLink}
                    to={item.path}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            {/* <Button
              //color="secondary"
              component={RouterLink}
              to="/signup"
              variant="contained"
              size="small"
            >
              Sign Up
            </Button> */}
          </Box>
          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
            
          </Box> */}
        </Toolbar>
        <Box
          sx={{ flexGrow: 5, display: { xs: "flex", sm: "none", md: "none" } }}
        >
          <SearchBar />
        </Box>
      </Container>
    </AppBar>
    {/* <CategoryBar/> */}
    </>
  );
}
export default TopBar;
