import { useState, React, useEffect } from "react";
import {
  CardContent,
  CardMedia,
  Grid,
  Card,
  Typography,
  Button,
  IconButton,
  Box,
  Alert,
  Rating,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { moneyFormatter } from "utils/formatUtils";
import StarIcon from "@mui/icons-material/Star";
import { addToCartAction } from "../../../../../actions/cartAction";
import { useNavigate } from "react-router-dom";
// import {  } from "react-feather";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
// import productCardTitle from "theme/typography";
import Price from "components/Price";
import {
  addToFavorite,
  removeToFavorite,
} from "../../../../../actions/favoriteAction";
import { useSelector } from "react-redux";
import { IMAGE_BASE_URL } from "config";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const ProductCard = ({
  image,
  price,
  title,
  productId,
  description,
  sku,
  specialPrice,
  fromDate,
  tillDate,
  fav,
  discount,
  availableStock,
  brand
}) => {
  // console.log("sku", sku);
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const showAlert = useSelector((state) => state.cart.showAlert);
  const isTaxInclusivePrice = useSelector(
    (state) => state.app.isTaxInclusivePrice
  );
  const account = useSelector((state) => state.auth);
  const isShowProductPrice = useSelector((state) => state.app.showProductPrice);
  const isAuthorized = account.isAuthorized;

  // console.log("showProductPrice", isShowProductPrice);
  const addToCart = async (productId) => {
    try {
      await dispatch(addToCartAction(productId, quantity, description));
      handleAddToCart();
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  const redirectToProductDetails = () => {
    navigate(`/product/${sku}`);
  };

  const [liked, setLiked] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  const toggleLike = (productId, description) => {
    setLiked(!liked);
    if (liked == false) {
      dispatch(addToFavorite(productId, description));
    } else if (liked == true) {
      dispatch(removeToFavorite(sku));
    }
  };

  const addFevFunc = (productId, description) => {
    dispatch(addToFavorite(productId, description));
  };

  const removeFevFunc = (sku) => {
    dispatch(removeToFavorite(sku));
  };

  function closeSuccessAlert() {
    setIsAdded(false);
  }
  const handleAddToCart = () => {
    // dispatch(addToCart(item));
    if (showAlert == true) {
      setIsAdded(true);
    } else {
      setIsAdded(true);
    }
    setTimeout(closeSuccessAlert, 5000);
  };
  useEffect(() => {}, [showAlert]);

  const checkAuth = () => {
    if (isAuthorized == true) {
      addToCart(productId, quantity, description);
    } else {
      navigate("/signin");
    }
  };
  const value = 5;
  const isXL = useMediaQuery("(min-width:1920)");
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
  const showSKU = useSelector((state) => state.app.showSKU);
  const showBrand = useSelector((state) => state.app.showBrand);
  return (
    <Box>
      <Grid container sx={{ display: "flex", justifyContent: "end" }}>
        <Box sx={{ display: "flex", justifyContent: "end", zIndex: "1000" }}>
          <IconButton>
            {fav ? (
              <FavoriteIcon
                sx={{
                  backgroundColor: "white",
                  borderRadius: "90px",
                  color: "red",
                  width: "30px",
                  height: "30px",
                  p: 0.5,
                }}
                onClick={() => removeFevFunc(sku)}
              />
            ) : (
              <FavoriteBorderIcon
                sx={{
                  backgroundColor: "white",
                  borderRadius: "90px",
                  width: "30px",
                  height: "30px",
                  p: 0.5,
                }}
                onClick={() => addFevFunc(productId, description)}
              />
            )}
          </IconButton>
        </Box>
      </Grid>
      <Card
        sx={{
          boxShadow: 8,
          borderRadius: "20px",
          width: isMobileView ? "270px" : "320",
          maxWidth: isMobileView ? "270px" : "320px",
          // "&:hover": {
          //   transform: "scale(1.1)",
          // },
          mt: -6,
          mb: 5,
        }}
      >
        <CardMedia
          onClick={redirectToProductDetails}
          component="img"
          image={image || "/assets/defaults/mperito_defualt_product.png"}
          sx={{
            transition: "transform 0.5s",
            "&:hover": {
              transform: "scale(1.2)",
            },
            // width:'100%',
            // aspectRatio: 3/2,
            // objectFit: "contain",
            backgroundSize: "contain",
            display: "block",
            overflow: "hidden",
            // width: "259px",
            height: "259px",
            // mt: -.1,
            // mt:-5
          }}
          // alt={title}
        />
        <CardContent>
          <Grid
            container
            direction="column"
            display="flex"
            justifyContent="start"
            alignItems="center"
            rowSpacing={2}
          >
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", mt: 2 }}
            >
              <Box>
                <Typography variant="productCardTitle" sx={{ color: "black" }}>
                  <b>
                    {title.slice(0, 20)}
                    {title.length > 20 ? "..." : ""}
                  </b>
                </Typography>
                {showBrand && 
                <Typography>
                  {brand}
                </Typography>
                }
              </Box>
            </Grid>
            <Grid item xs={12}></Grid>

            {isShowProductPrice && (
              <>
                <Price
                  price={price}
                  specialPrice={specialPrice}
                  fromDate={fromDate}
                  tillDate={tillDate}
                  discount={discount}
                />
                <Rating
                  name="text-feedback"
                  value={value}
                  readOnly
                  precision={0.5}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                {showSKU && (
                  <Typography variant="body1" color="text.secondary">
                    {sku.slice(0, 30)} {sku.length > 30 ? "..." : ""}
                  </Typography>
                )}
                <Typography variant="body2" gutterBottom>
                  {isTaxInclusivePrice ? "Inclusive of all taxes" : ""}
                </Typography>
              </>
            )}

            <Grid item xs={12}>
              {availableStock == 0 ? (
                <Box sx={{ height: 40, width: "240px" }}>
                  <Typography sx={{ color: "red", ml: 3, textAlign: "center" }}>
                    Out of Stock
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ height: 40 }}>
                  <Button
                    // onClick={() => addToCart(productId, quantity, description)}
                    onClick={checkAuth}
                    variant="contained"
                    size="large"
                    sx={{
                      borderRadius: "20px",
                      width: "240px",
                    }}
                  >
                    Add to Cart
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
        {isAdded && (
          <Alert severity={showAlert ? "success" : "error"}>
            {showAlert
              ? "Product Added Successfully To Cart"
              : "Something went wrong please try after some time"}
          </Alert>
        )}
      </Card>
    </Box>
  );
};

export default ProductCard;
