import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Main as MainLayout } from "layouts";
import { Helmet } from "react-helmet";

import { Grid, Card } from "@mui/material";
import {SearchResults, SearchFilter } from "./components";
import SearchHeader from "./components/SearchHeader";
// import SearchFilter from "./SearchFilter";

const Search = ({ }) => {

  const search = useSelector((state) => state.search);
  const searchKeyword = search.searchKeyword;
  const numFound = search.numFound;
  const isSearching = search.isSearching;
  const currentPage = search.currentPage;
  const maxPrise = search?.aggregationsList?.max_price?.value;
  const minPrise = search?.aggregationsList?.min_price?.value;



  useEffect(() => {

  }, [search.results, searchKeyword]);

  return (
    <MainLayout>
      <Helmet>
        <title>Search</title>
      </Helmet>
      <Card sx={{ borderRadius: 5, p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <SearchHeader isSearching={isSearching} numFound={numFound} searchKeyword={searchKeyword} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <SearchFilter facets={search?.facets} minPrise={minPrise} maxPrise={maxPrise}/>
          </Grid>

          <Grid item xs={12} sm={12} md={9}>
            <SearchResults data={search?.results} numFound={numFound} />
          </Grid>
        </Grid>
      </Card>
    </MainLayout>
  );
};

export default Search;
