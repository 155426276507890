import React from "react";
import VideoSlider from "./components/VideoSlider";
import { Grid, Typography, Box } from "@mui/material";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";


function VideoSection({videos}) {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '20px' }}>
                    <Box>
                        {/* <OndemandVideoIcon sx={{ color: '#1459BA', fontSize: '24px' }} /> */}
                    </Box>
                    <Box>
                        <Typography sx={{ color: '#1459BA', fontSize: "16px", fontWeight: 600 }}>Videos</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <VideoSlider videos={videos}/>
            </Grid>
        </Grid>
    );
}

export default VideoSection;
