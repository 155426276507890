import InvoiceService from "services/invoiceService";

export const GET_INVOICE = "@invoice/get-invoice";
export const GET_INVOICE_SUCCESS = "@invoice/get-invoice-success";
export const GET_INVOICE_FAILURE = "@invoice/get-invoice-failure";

export const GET_INVOICE_DETAILS = "@invoice/get-invoice-details";
export const GET_INVOICE_DETAILS_SUCCESS = "@invoice/get-invoice-details-success";
export const GET_INVOICE_DETAILS_FAILURE = "@invoice/get-invoice-details-failure";

export const SET_INVOICE_DETAILS_ID = "@invoice/set-invoice-id";

export function getInvoiceAction() {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_INVOICE });

      const payload = await InvoiceService.getInvoice();

      dispatch({
        type: GET_INVOICE_SUCCESS,
        payload: payload,
      });
    } catch (error) {
      dispatch({
        type: GET_INVOICE_FAILURE,
      });
      console.log("error", error);
    }
  };
}

export function getInvoiceByIdAction(invoiceId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_INVOICE_DETAILS });
      const invoiceDetails = await InvoiceService.getInvoiceDetails(invoiceId);
      dispatch({ type: GET_INVOICE_DETAILS_SUCCESS, payload: invoiceDetails });
    } catch (error) {
      dispatch({ type: GET_INVOICE_DETAILS_FAILURE });
    }
  };
}


export  function storeInvoiceId (invoiceId){
return async (dispatch)=>{
  try{
    dispatch({type: SET_INVOICE_DETAILS_ID,
    payload:invoiceId})
  }catch(error){
    console.log(error)
  }
}
}