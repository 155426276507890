/* eslint-disable no-param-reassign */
import produce from "immer";

import { IMAGE_BASE_URL } from "config/index";
import { HOME_CAROUSEL_ITEMS, CATEGORIES, NEWS, VIDEOS } from "config/content";

import {
    FETCH_CUSTOMER_DETAILS, FETCH_CUSTOMER_DETAILS_SUCCESS, FETCH_CUSTOMER_DETAILS_FAILURE,
    FETCH_BANNERS, FETCH_BANNERS_SUCCESS, FETCH_BANNERS_FAILURE,
    FETCH_POLICY, FETCH_POLICY_SUCCESS, FETCH_POLICY_FAILURE,
    FETCH_NEWS, FETCH_NEWS_SUCCESS, FETCH_NEWS_FAILURE,
    FETCH_GREETINGS, FETCH_GREETINGS_SUCCESS, FETCH_GREETINGS_FAILURE,
    FETCH_ABOUT_US, FETCH_ABOUT_US_SUCCESS, FETCH_ABOUT_US_FAILURE,

} from "actions/contentAction";
import { DEFAULT_REFUND_POLICY, DEFAULT_RETURN_POLICY, DEFAULT_SHIPPING_POLICY } from "config/policies";

const initialState = {

    showContacts: false,
    showDistributors: false,
    showServiceCenters: false,
    showMaintenanceTroubleshooting: false,

    website: undefined,
    certificates: [],

    homeCarousel: [...HOME_CAROUSEL_ITEMS],
    categories: [...CATEGORIES],
    newsList: [], // [...NEWS],
    greetings: [],
    // videos: [...VIDEOS],
    socialLinks: {
        facebookUrl: undefined,
        twitterUrl: undefined,
        instagramUrl: undefined,
        linkedInUrl: undefined,
        youtubeUrl: undefined,
    },
    policies: {
        privacy: undefined,
        terms: undefined,
        shipping: undefined,
        return: undefined,
        refund: undefined,        
        cancellation: undefined,
    },

    contact: {
        // bannerContactAddress  TODO_SP: Check why this is required
        mobile: undefined,
        whatsApp: undefined,
        helpLine: undefined,
        registeredEmail: undefined,
        registeredAddress: undefined,
    },

    aboutUs: undefined,
};


const contentReducer = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_CUSTOMER_DETAILS_SUCCESS: {
            const { showContacts, showDistributors, showServiceCenters, showMaintenanceTroubleshooting,
                website, certificates, socialLinks, mobile, whatsApp, helpLine,
                registeredEmail, registeredAddress
            } = action.payload?.customerDetails;

            return produce(state, (draft) => {
                draft.showContacts = showContacts;
                draft.showDistributors = showDistributors;
                draft.showServiceCenters = showServiceCenters;
                draft.showMaintenanceTroubleshooting = showMaintenanceTroubleshooting
                draft.website = website;
                draft.certificates = certificates ? certificates : [];
                draft.socialLinks = {
                    facebookUrl: socialLinks?.facebookUrl,
                    twitterUrl: socialLinks?.twitterUrl,
                    instagramUrl: socialLinks?.instagramUrl,
                    linkedInUrl: socialLinks?.linkedInUrl,
                    youtubeUrl: socialLinks?.youtubeUrl
                };
                draft.contact = {
                    mobile: mobile,
                    whatsApp: whatsApp,
                    helpLine: helpLine,
                    registeredEmail: registeredEmail,
                    registeredAddress: registeredAddress
                };

            });
        }
            break;


        case FETCH_BANNERS_SUCCESS: {
            const { banners } = action.payload;
            return produce(state, (draft) => {

                if (banners?.length) {
                    draft.homeCarousel = [];
                    banners.map((item) => {
                        draft.homeCarousel.push({
                            label: "",
                            imgPath: IMAGE_BASE_URL + '/' + item
                        });
                    });
                }
            });
        }
            break;

        case FETCH_BANNERS_FAILURE: {


        }
            break;

        case FETCH_POLICY_SUCCESS: {
            const { policies } = action.payload;
            return produce(state, (draft) => {
                draft.policies.privacy = policies?.privacyPolicy;
                draft.policies.terms = policies?.termsOfUse;
                draft.policies.shipping = policies?.shippingPolicy || DEFAULT_SHIPPING_POLICY;
                draft.policies.return = policies?.returnPolicy || DEFAULT_RETURN_POLICY;
                draft.policies.refund = policies?.refundPolicy || DEFAULT_REFUND_POLICY;

            });
        }
            break;

        case FETCH_NEWS_SUCCESS: {
            const { newsList } = action.payload;
            //console.log("Reducer: ", action.payload, newsList);
            return produce(state, (draft) => {
                draft.newsList = newsList.list;

            });
        }
            break;

        case FETCH_GREETINGS_SUCCESS: {
            const { greetingsList } = action.payload;
            // console.log("Reducer: Greeting Success ", action.payload, greetingsList);
            return produce(state, (draft) => {
                draft.greetings = greetingsList.list;

            });
        }
            break;

        case FETCH_ABOUT_US_SUCCESS: {
            const { aboutUs } = action.payload;
            return produce(state, (draft) => {
                draft.aboutUs = aboutUs;

            });
        }
            break;

        default:
            {
                return state;
            }
            break;
    }

};

export default contentReducer;

