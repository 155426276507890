import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  Grid,
} from "@mui/material";
import { moneyFormatter } from "utils/formatUtils";
import { PAYMENT_GATEWAY } from "config";
import SendGetQuote from "views/GetQuote/SendGetQuote.js";
import { previewOrder } from "actions/paymentAction";

function PreviewOrder({
  totalAmount,
  deliveryAddress,
  billingAddress,
  cart,
  GetQuotePage,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderDesc = "Web Order";

  const paymentGateway = useSelector((state) => state.payment?.paymentGateway);
  //  const isAddressSelect =  useSelector((state) => state.address?.addressSelected);
 // const deliveryAddressState = useSelector((state) => state.checkout?.deliveryAddressState);
  // console.log("deliveryAddress", deliveryAddress.state);
  const deliveryAddressState = deliveryAddress.state

  async function handlePreviewOrder() {
    try {
      await dispatch(previewOrder(deliveryAddressState));
      console.log("Order Previewed Successfully");
      
    } catch (error) {
      console.log("error", error.response.status);
    }
  }

  useEffect(() => {
    handlePreviewOrder()
  }, [paymentGateway]);

  return (
    <Grid mt={2}>
      <Card sx={{ boxShadow: 4, borderRadius: 2, color: "#443A4F" }}>
        {/* <CardContent>
        <Typography fontWeight="bold" fontSize="16px" textAlign="start">

        </Typography>
      </CardContent>
      <Divider /> */}

        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {GetQuotePage ? (
              <SendGetQuote
                cart={cart}
                shippingAdd={deliveryAddress}
                billingAdd={billingAddress}
              />
            ) : (
              <Button
                fullWidth
                // disabled={!isReadyForPayment}
                size="large"
                type="submit"
                variant="contained"
                sx={{ borderRadius: 30 }}
                onClick={handlePreviewOrder}
              >
                Preview Order
              </Button>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default PreviewOrder;
