import axios from "utils/axios";

import {
    FETCH_CATEGORIES_URL,
    FETCH_PRODUCT_BY_ID_URL,
    FETCH_PRODUCT_BY_SKU_URL,
    FETCH_RECENT_PRODUCTS_URL,
    FETCH_CATEGORY_PRODUCTS_URL,
    GET_PRODUCT_DETAILS
} from "config/services";

class CatalogService {

    

    fetchCategories = (categoryId) =>
        new Promise((resolve, reject) => {
            const url = categoryId ? 
            `${FETCH_CATEGORIES_URL}?categoryId=${categoryId}` : FETCH_CATEGORIES_URL
            axios
                .get(url)
                .then((resp) => {
                    if (resp.data) {

                        // console.log("fetchCategories() ", resp.data?.data?.categories);
                        const categories = (resp.data?.data) ? resp.data?.data?.categories : undefined;
                        resolve(categories);
                    } else {
                        reject(resp.data?.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });


    fetchProductDetails = (productId) =>
        new Promise((resolve, reject) => {
            axios
                .get(FETCH_PRODUCT_BY_ID_URL + "/" + productId)
                .then((resp) => {
                    if (resp.data) {

                        // console.log("fetchProductDetails() ", resp.data?.data?.product);
                        const product = (resp.data?.data) ? resp.data?.data?.product : undefined;
                        resolve(product);
                    } else {
                        reject(resp.data?.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });

    fetchProductDetailsBySku = (sku) =>
        new Promise((resolve, reject) => {
            axios
                .get(FETCH_PRODUCT_BY_SKU_URL, { params: { productSKU: sku } })
                .then((resp) => {
                    if (resp.data) {
                        // console.log("fetchProductDetailsBySku ", resp.data?.data);
                        const product = (resp.data?.data) ? resp.data?.data?.product : undefined;
                        resolve(product);
                    } else {
                        reject(resp.data?.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });



    fetchRecentProducts = () =>
        new Promise((resolve, reject) => {
            axios
                .get(FETCH_RECENT_PRODUCTS_URL)
                .then((resp) => {
                    if (resp.data) {

                        console.log("fetchRecentProducts() ", resp.data?.data);
                        const products = (resp.data?.data) ? resp.data?.data : undefined;
                        resolve(products);
                    } else {
                        reject(resp.data?.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });

    fetchCategoryProducts = (categoryName) =>
        new Promise((resolve, reject) => {
            axios
                .get(FETCH_CATEGORY_PRODUCTS_URL, {
                    params: {
                        //categoryId: categoryName
                        name: categoryName
                    }
                })
                .then((resp) => {
                    if (resp.data) {

                        console.log("fetchCategoryProducts() ", resp.data?.data);
                        const products = (resp.data?.data) ? resp.data?.data?.result : undefined;
                        resolve(products);
                    } else {
                        reject(resp.data?.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });


        fetchVariations = (productId) =>
        new Promise((resolve, reject) => {
            axios.get(GET_PRODUCT_DETAILS + "/" + productId)
                .then((resp) => {
                    if (resp.data) {

                        // console.log("fetchProductDetails() ", resp.data?.data?.product);
                        const Variations = (resp.data?.data) ? resp.data?.data : undefined;
                        // const Variations = (resp.data?.data) ? resp.data?.data?.product : undefined;
                        resolve(Variations);
                    } else {
                        reject(resp.data?.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });



        fetchCategoryHead = () =>
        new Promise((resolve, reject) => {
            axios
                .get(FETCH_CATEGORIES_URL)
                .then((resp) => {
                    if (resp.data) {
                         console.log("categoriesForHead() ", resp.data?.data);
                        const categoriesForHead = (resp.data?.data) ? resp.data?.data?.categories: undefined;
                        resolve(categoriesForHead);
                    } else {
                        reject(resp.data?.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });

}

const catalogService = new CatalogService();

export default catalogService;