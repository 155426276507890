import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardContent, Divider, Typography, Grid, CardHeader } from "@mui/material";
import AddressLine from "views/Checkout/components/AddressLine";
import AddressForm from "views/Checkout/components/AddressForm";
import AddressSelect from "views/Checkout/components/AddressSelect";
import { ADDRESS_STATUS, ADDRESS_TYPE } from "config";
import { chooseAddress, fetchMyAddresses } from "actions/addressAction";

import AddressList from "./components/AddressList";


function AddressSection({ title }) {
  const dispatch = useDispatch();
  const [addresses, setAddresses] = useState(null);
  const [addressState, setAddressState] = useState(ADDRESS_STATUS.LIST);

  useEffect(() => {
    async function fetchAddress() {
      // console.log("checkout Fetching Address:");
      const addressList = await dispatch(fetchMyAddresses());
      // console.log("getAddress: addressList", addressList);
      setAddresses(addressList);
    }
    fetchAddress();
  }, [addresses]);

  useEffect(() => {
   
  }, [addresses]);

  // console.log("AddressSection ", addresses);

  const handleSelectAddress = async () => {
    await dispatch(chooseAddress(ADDRESS_TYPE.GENERAL));   
  }

  const handleNewAddress = () => {
    setAddressState(ADDRESS_STATUS.NEW);
  }

  const handleNewAddressSave = () => {
    setAddressState(ADDRESS_STATUS.LIST);
  }

  useEffect(() => { }, []);

  return (
    <Card sx={{ boxShadow: 4, borderRadius: 2, color: "#443A4F" }}>
      
      <CardContent>
        <Typography fontWeight="bold" fontSize="16px" textAlign="start">
          {title}
        </Typography>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          {(addressState === ADDRESS_STATUS.NEW) && <AddressForm addressType={ADDRESS_TYPE.GENERAL} onSubmitSuccess={handleNewAddressSave} handleNewAddressSave={handleNewAddressSave} handleNewAddress={handleNewAddressSave}/>}                    
          {(addressState === ADDRESS_STATUS.LIST) && <AddressList addressType={ADDRESS_TYPE.GENERAL} addresses={addresses} handleNewAddress={handleNewAddress}/>}
        </Grid>
      </CardContent>

      <Divider />
    </Card>
  );
};

export default AddressSection;
