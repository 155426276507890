import React from "react";

import Box from "@mui/material/Box";
import { Tab, CardMedia } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";

import GreetingCard from "./GreetingCard";
import VideoCard from "../../VideoSection/components/VideoCard";

function GreetingSlider({greetings}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={true}
        aria-label="scrollable videos"
        allowScrollButtonsMobile={true}
        centered={true}
        indicatorColor="none"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 },
          },
        }}
      >
        {greetings.map((item) => (
          <Tab            
            label={
              (item.videoUrl ? 
                <VideoCard video={item.videoUrl} title={item.title} /> :
                <GreetingCard image={item.imageUrl} title={item.title} created={item.created}/>
            )
          }
          />
          )
        )}
      </Tabs>
    </Box>
  );
}

export default GreetingSlider;
