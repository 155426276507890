import React from "react";
// import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

import Minimal from "layouts/Minimal";
import { Helmet } from "react-helmet";
import Logo from "components/Logo";

const notFoundImg = "/assets/general/404.jpeg";

const PageNotFound = () => {
    // const theme = useTheme();

    return (
        <Minimal>
            <Helmet>
                <title>Page Not Found</title>
            </Helmet>
            <Box>
                <Logo height="200px" />
                <img src={notFoundImg} alt="Page Not Found" width={400} height={400} />
                <Typography fontSize={50} fontWeight={600} color="primary">
                    Sorry Page is Missing
                </Typography>
            </Box>
        </Minimal>
    );
};

export default PageNotFound;
