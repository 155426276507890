
import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  MenuItem,
  Select,
  Card,
  Typography,
  Box
} from "@mui/material";



const AdditionalFeatures = ({ product, getCartAction, cartItems, setVariationsList, variationsList }) => {
  // Initialize selectedValues with the first value of each key
  const [selectedValues, setSelectedValues] = useState(() => {
    const initialSelected = {};
    if (product?.additionalFeatures && product.additionalFeatures.length > 0) {
      const firstFeature = product.additionalFeatures[0];
      Object.keys(firstFeature).forEach((key) => {
        const value = firstFeature[key];
        initialSelected[key] = typeof value === "string" && value.includes("\n")
          ? value.split("\n")[0].trim()
          : value;
      });
    }
    return initialSelected;
  });

  const dataArray = Object.keys(selectedValues).map((key) => {
    return { [key]: selectedValues[key] };
    
  });

  // console.log("dataArray", dataArray)

  const handleChange = (event, key) => {
    setSelectedValues({
      ...selectedValues,
      [key]: event.target.value,    
    });
    
  };

  useEffect(()=>{
    setVariationsList(dataArray)
  },[dataArray])
 

  
  return (
    <>
      <Grid container spacing={4} key={product?.additionalFeatures.index}>
        {/* Map through the object keys if additionalFeatures exists */}
        {product.additionalFeatures &&
          product.additionalFeatures.length > 0 &&
          Object.keys(product.additionalFeatures[0]).map((key, keyIndex) => {
            const value = product.additionalFeatures[0][key];
            const valuesArray =
              typeof value === "string" && value.includes("\n")
                ? value.split("\n")
                : [value];

            return (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={keyIndex} sx={{ display: "flex", alignItems:'center' }}>
                <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
                  <Typography id={`select-label-${keyIndex}`}>{key} :</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                <FormControl fullWidth>
                  {/* Remove InputLabel */}
                  <Select
                    labelId={`select-label-${keyIndex}`}
                    value={selectedValues[key] || ""} // Set initial value from state
                    displayEmpty // Show an empty value in the Select
                    onChange={(e) => handleChange(e, key)}
                  >
                    {/* Map through valuesArray */}
                    {valuesArray.map((val, valIndex) => (
                      <MenuItem
                        key={valIndex}
                        value={typeof val === "string" ? val.trim() : val}
                      >
                        {typeof val === "string" ? val.trim() : val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default AdditionalFeatures;
