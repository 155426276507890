import {
  DELETE_CART,
  ADD_TO_CART_URL,
  GET_CART_URL,
  DELETE_CART_URL,
  ADD_FAVORITE_URL,
  REMOVE_FAVORITE_URL,
  GET_FAVORITE_URL,
  GET_QUOTE_BY_USER_URL,
  GET_QUOTE_LIST_URL,
  GET_QUOTE_BY_ID_URL,
} from "config/services";
import axios from "utils/axios";

class CartService {
  search = (id) =>
    new Promise((resolve, reject) => {
      axios
        .get(DELETE_CART, { id })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error?.response?.payload);
        });
    });

  addToCart = (productId, quantity, description, productVariantId, variations) =>
    new Promise((resolve, reject) => {
      axios
        .post(ADD_TO_CART_URL, { productId, quantity, description , productVariantId, variations})
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error?.response?.payload);
        });
    });

  getCart = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_CART_URL, {})
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          console.log("121231321", error);
          reject(error?.response?.payload);
        });
    });

  deleteCart = (productId) =>
    new Promise((resolve, reject) => {
      axios
        .delete(`${DELETE_CART_URL}/${productId}`)
        .then((response) => {
          resolve(response?.data?.data?.cart);
        })
        .catch((error) => {
          reject(error?.response?.payload);
        });
    });

  getQuote = (items,
    shippingAddress,
    billingAddress,
    businessName,
    gstin) =>
    new Promise((resolve, reject) => {
      axios
        .post(GET_QUOTE_BY_USER_URL, { items,
          shippingAddress,
          billingAddress,
          businessName,
          gstin} )
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          reject(error?.response?.payload);
        });
    });


    getQuoteList = () =>
      new Promise((resolve, reject) => {
        axios
          .get(GET_QUOTE_LIST_URL )
          .then((response) => {
            resolve(response?.data);
              console.log("response?.data?.data", response?.data?.data)
          })
          .catch((error) => {
            reject(error?.response?.payload);
          });
      });

      getQuoteById = (id) =>
      new Promise((resolve, reject) => {
        axios
          .get(`${GET_QUOTE_LIST_URL}?quotationId=${id}`)
          .then((response) => {
            resolve(response?.data?.data);
              console.log("response by id", response?.data?.data)
          })
          .catch((error) => {
            reject(error?.response?.payload);
          });
      });
}

const cartService = new CartService();

export default cartService;
