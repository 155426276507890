import React, {
  useRef,
  useState
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { makeStyles } from '@mui/styles';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { signOut } from 'actions/authActions';
import { useNavigate } from "react-router";

import { profileMenu } from './menuData';

function Account() {
  //const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const account = useSelector((state) => state.account);
  const account = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >

        <AccountCircleIcon fontSize="large" color="contrastText" />
        <Hidden smDown>
          <Typography
            variant="h6"
            color="inherit"
            sx={{ml:1}}
          >
            {`${account?.user?.avatar}`}
            
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted

        PaperProps={{ style: {width: 200 } }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {profileMenu.map((item) => (
          <MenuItem onClick={handleClose} component={RouterLink} to={item.path} >{item.label}</MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default Account;
