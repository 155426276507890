import React, { useEffect } from "react";
import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import ProductCard from "./ProductCard";
import { Tab } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";

function ProductSlider({ products }) {
  const [value, setValue] = React.useState(0);

  const favourites = useSelector((state) => state.favorite?.favourites);
  const favouritesID = favourites.map((item) => item?.productId);
  
  const isFavourites = (productId) => favouritesID.includes(productId);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {}, [products, favourites]);

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={true}
        aria-label="scrollable auto tabs example"
        allowScrollButtonsMobile={true}
        centered={true}
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 },
          },
        }}
      >
        {products?.map((item) => (
        
          <Tab
            label={
              <ProductCard
                image={item.baseImage}
                title={item.name}
                price={item.price?.sellPrice}
                specialPrice={item.price?.specialPrice?.price}
                fromDate={item.price?.specialPrice?.from}
                tillDate={item.price?.specialPrice?.till}
                discount={item.price?.specialPrice?.discount}
                productId={item.productId}
                description={item.name}
                availableStock={item?.availableStock}
                sku={item.sku}
                fav={isFavourites(item?.productId) ? true : false}
                
              />
            }
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default ProductSlider;
