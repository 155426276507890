/* eslint-disable no-param-reassign */
import {
    MY_ADDRESS_FETCH, MY_ADDRESS_FETCH_SUCCESS, ADDRESS_SELECTED,
    CHOOSE_ADDRESS, CHOOSE_ADDRESS_NEW, ADDRESS_ADD, ADDRESS_ADD_SUCCESS
} from "actions/addressAction";
import { PREVIEW_ORDER_SUCCESS } from "actions/paymentAction";
import { ADDRESS_STATUS } from "config";
import { ADDRESS_TYPE } from "config";
import produce from "immer";


const initialState = {
    deliveryAddress: undefined,
    billingAddress: undefined,
    deliveryAddressState: ADDRESS_STATUS.NEW,
    billingAddressState: ADDRESS_STATUS.NEW,
    addresses: [],
    orderPaymentSummary: {
        deliveryCharges: undefined,
        tax: undefined,
        orderAmount: undefined,
        totalAmount: undefined,
    },
    isReadyForPayment:false,


}

const checkoutReducer = (state = initialState, action) => {

    switch (action.type) {

        case MY_ADDRESS_FETCH: {
            return produce(state, (draft) => {

            });
        }
            break;

        case MY_ADDRESS_FETCH_SUCCESS: {
            const { addresses } = action.payload;

            return produce(state, (draft) => {
                draft.addresses = addresses ? addresses : [];

                console.log("MY_ADDRESS_FETCH_SUCCESS : ", addresses, addresses.length);

                if (addresses && addresses.length > 0) {
                    draft.deliveryAddressState = ADDRESS_STATUS.SELECT;
                    draft.billingAddressState = ADDRESS_STATUS.SELECT;
                } else {
                    draft.deliveryAddressState = ADDRESS_STATUS.NEW;
                    draft.billingAddressState = ADDRESS_STATUS.NEW;
                }
            });
        }
            break;

        case ADDRESS_ADD: {
            const { addressType, address } = action.payload;

            console.log("checkoutReducer: ", addressType, address);

            return produce(state, (draft) => {
                if (addressType === ADDRESS_TYPE.DELIVERY) {
                    draft.deliveryAddress = address;
                    draft.deliveryAddressState = ADDRESS_STATUS.INFO;
                }


                if (addressType === ADDRESS_TYPE.BILLING) {
                    draft.billingAddress = address;
                    draft.billingAddressState = ADDRESS_STATUS.INFO;
                }

            });
        }
            break;

        case ADDRESS_ADD_SUCCESS: {
            const { addresses } = action.payload;
            console.log("checkoutReducer: ADDRESS_ADD_SUCCESS", addresses);
            return produce(state, (draft) => {
                draft.addresses = addresses;
            });
        }
            break;

        case ADDRESS_SELECTED: {
            const { addressType, address } = action.payload;
            console.log("checkoutReducer: ", addressType, address);
            return produce(state, (draft) => {
                if (addressType === ADDRESS_TYPE.DELIVERY) {
                    draft.deliveryAddress = address;
                    draft.deliveryAddressState = ADDRESS_STATUS.INFO;
                }

                if (addressType === ADDRESS_TYPE.BILLING) {
                    draft.billingAddress = address;
                    draft.billingAddressState = ADDRESS_STATUS.INFO;
                }

            });
        }
            break;

        case CHOOSE_ADDRESS: {
            const { addressType } = action.payload;

            console.log("checkoutReducer_ CHOOSE_ADDRESS: ", addressType);

            return produce(state, (draft) => {
                if (addressType === ADDRESS_TYPE.DELIVERY) {
                    draft.deliveryAddressState = ADDRESS_STATUS.SELECT;
                }


                if (addressType === ADDRESS_TYPE.BILLING) {
                    draft.billingAddressState = ADDRESS_STATUS.SELECT;
                }


            });
        }
            break;

        case CHOOSE_ADDRESS_NEW: {
            const { addressType } = action.payload;

            console.log("checkoutReducer_ CHOOSE_ADDRESS_NEW: ", addressType);

            return produce(state, (draft) => {
                if (addressType === ADDRESS_TYPE.DELIVERY) {
                    draft.deliveryAddressState = ADDRESS_STATUS.NEW;
                }


                if (addressType === ADDRESS_TYPE.BILLING) {
                    draft.billingAddressState = ADDRESS_STATUS.NEW;
                }


            });
        }
            break;

            case PREVIEW_ORDER_SUCCESS: {
                const { orderPaymentDetails } = action.payload;
    
                console.log("checkoutReducer_ PREVIEW_ORDER_SUCCESS: ", action.payload, orderPaymentDetails);
    
                return produce(state, (draft) => {
                    draft.orderPaymentSummary.deliveryCharges = orderPaymentDetails?.delivery;
                    draft.orderPaymentSummary.orderAmount = orderPaymentDetails?.orderAmount;
                    draft.orderPaymentSummary.tax = {
                        cgst: orderPaymentDetails?.tax?.cgst,
                        sgst: orderPaymentDetails?.tax?.sgst,
                        igst: orderPaymentDetails?.tax?.igst,
                    };
                    draft.orderPaymentSummary.totalAmount = orderPaymentDetails?.totalAmount;    
                    draft.isReadyForPayment = true;     
                    localStorage.setItem("checkout", JSON.stringify(orderPaymentDetails));          
                });
            }
                break;

        default: {
            return state;
        }
            break;

    }


};

export default checkoutReducer;