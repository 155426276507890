import { getEmbeddedUrl, convertVideoLink } from "utils/transformUtils";

class NewsInfo {
    constructor(info) {
        if (info) {
            this.title = info.Heading;
            this.description = info.Description;
            //this.videoUrl = getEmbeddedUrl(info.VideoUrl);
            this.videoUrl = convertVideoLink(info.VideoUrl);
            this.imageUrl = info.ImageURl;
            this.created = info.CreatedDate;
            this.validity = info.Validity;
        }
    }
}

class NewsList {
    constructor(info) {
        this.list = [];
        info.map((item) => {
            const newsItem = new NewsInfo(item);
            this.list.push(newsItem)           
        });
    }
}

export default { NewsInfo };
export { NewsInfo, NewsList }
