import React from "react";
import { CardMedia, Grid, Typography, Box, Button } from "@mui/material";

const policiesLink = [
  {
    id: 1,
    name: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    id: 2,
    name: "Terms & Conditions",
    link: "/terms-and-conditions",
  },
  {
    id: 3,
    name: "Shipping Policy",
    link: "/shipping-policy",
  },
  {
    id: 4,
    name: "Return Policy",
    link: "/return-policy",
  },
  {
    id: 5,
    name: "Refund Policy",
    link: "/refund-policy",
  },
  
];

const CustomerPolicies = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          color={"primary.main"} 
          sx={{
            fontSize: "16x",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          POLICIES
        </Typography>
      </Grid>
      {policiesLink.map((item) => (
        <Grid item xs={12}>
          <Button variant="text"
            href={item.link}
            sx={{ 
              textTransform: 'capitalize', 
              textAlign: 'start', 
              color: '#000000' }}>{item.name}</Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default CustomerPolicies;
