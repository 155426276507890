class Address {
  constructor(info) {
    if (info) {
      this.name = info.name;
      this.addressLine1 = info.address1;
      this.addressLine2 = info.address2;
      this.city = info.city;
      this.state = info.state;
      this.country = info.country;
      this.pinCode = info.pincode;
      this.mobile = info.mobile;
      this.email = info.email;
      this.addressType = info.label;
    }
  }
}

class ProfileEdit {
  constructor(info) {
    {
      this.name = info.name;
      this.email = info.email;
      this.mobile = info.mobile;
      this.userType = info.userType;
      this.GSTN = info.GSTN;
    }
  }
}

export default { Address };
export { Address, ProfileEdit };
