import axios from "utils/axios";

import {
    FETCH_BANNERS_URL,
    FETCH_POLICY_URL,
    FETCH_NEWS_URL,
    FETCH_GREETINGS_URL,
    FETCH_ABOUT_US_URL,
    FETCH_CUSTOMER_DETAILS_URL
} from "config/services";

class ContentService {

    fetchCustomerDetails = () =>
        new Promise((resolve, reject) => {
            axios
                .get(FETCH_CUSTOMER_DETAILS_URL)
                .then((resp) => {
                    if (resp.data) {
                        const customerDetails = resp.data?.data;
                        resolve(customerDetails);
                    } else {
                        reject(resp.data?.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });

    fetchBanners = () =>
        new Promise((resolve, reject) => {
            axios
                .get(FETCH_BANNERS_URL)
                .then((resp) => {
                    if (resp.data) {
                        const banners = resp.data?.data;
                        resolve(banners);
                    } else {
                        reject(resp.data?.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });


    fetchPolicy = () =>
        new Promise((resolve, reject) => {
            axios
                .get(FETCH_POLICY_URL)
                .then((resp) => {
                    if (resp.data) {

                        // console.log("fetchPolicy() ", resp.data?.data?.length, resp.data?.data[0] );
                        const policy = (resp.data?.data?.length) ? resp.data?.data[0] : undefined ;
                        resolve(policy);
                    } else {
                        reject(resp.data?.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });

        fetchNews = () =>
        new Promise((resolve, reject) => {
            axios
                .get(FETCH_NEWS_URL)
                .then((resp) => {
                    if (resp.data) {

                        // console.log("fetchNews() ", resp.data?.data?.newsRes);
                        const news = (resp.data?.data) ? resp.data?.data?.newsRes : undefined ;
                        resolve(news);
                    } else {
                        reject(resp.data?.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });

        fetchGreetings = () =>
        new Promise((resolve, reject) => {
            axios
                .get(FETCH_GREETINGS_URL)
                .then((resp) => {
                    if (resp.data) {

                        // console.log("fetchNews() ", resp.data?.data?.newsRes);
                        const greetings = (resp.data?.data) ? resp.data?.data?.greetingsRes : undefined ;
                        resolve(greetings);
                    } else {
                        reject(resp.data?.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });

        fetchAboutUs = () =>
        new Promise((resolve, reject) => {
            axios
                .get(FETCH_ABOUT_US_URL)
                .then((resp) => {
                    if (resp.data) {
                        // console.log("fetchAboutUs() ", resp.data?.data);
                        const about = (resp.data?.data?.length) ? resp.data?.data[0]?.about : undefined ;
                        resolve(about);
                    } else {
                        reject(resp.data?.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });


}

const contentService = new ContentService();

export default contentService;
