import React, { useState } from "react";
import { Main as MainLayout } from "layouts";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Card,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const Favourites = () => {
  const favourites = useSelector((state) => state.favorite?.favourites);
  const [favorites, setFavorites] = useState(["Item 1", "Item 2", "Item 3"]);

  const removeFavorite = (index) => {
    const updatedFavorites = [...favorites];
    updatedFavorites.splice(index, 1);
    setFavorites(updatedFavorites);
  };

  const navigate = useNavigate();
  const redirectToProductDetails = (sku) => {
    navigate(`/product/${sku}`);
  };

  return (
    <MainLayout>
      <Container maxWidth="sm" sx={{ p: 5 }}>
        <Card sx={{p:2}}>
        
        <Typography variant="h6">Favourites List</Typography>
          <List>
            {favourites.map((favorite, index) => (
              <Card
                sx={{ p: 1, pl: 2, mb: 1 }}
                onClick={() => redirectToProductDetails(favorite.productSKU)}
              >
                <Typography variant="h5">{favorite.productName}</Typography>
                <Typography variant="Title">{favorite.productSKU}</Typography>
              </Card>
            ))}
          </List>
        </Card>
      </Container>
    </MainLayout>
  );
};

export default Favourites;
