import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import {searchAction} from "../../../actions/searchAction"
import { Navigate, Link as RouterLink } from 'react-router-dom';
import { useNavigate } from "react-router";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const SearchBar = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const dispatch = useDispatch()

  const handleSearch = (e) => {
    setValue(e.target.value);
    localStorage.setItem("searchKey", e.target.value );
   
  };
const searchFunction=()=>{
    try{
        dispatch(searchAction(value))
    }catch{
        console.log(console.error);
    }
}


const handleKeyPress = (event) => {
  if (event.key === 'Enter') { 
    event.preventDefault();
    searchFunction(); 
    navigate("/search-results");  
  }
};

const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container>
      <Grid item sx={12} sm={12} md={8}>
        <Paper
          fullWidth
          component="form"
          sx={{
            p: "0px 2px",
            display: "flex",
            alignItems: "center",
            width: isMobileView ? "340px" : "100%",
            borderRadius :'90px',
            mt:2
          }}
        >
          <InputBase
            onKeyDown={handleKeyPress}
            sx={{ ml: 1, flex: 1, color: "black" }}
            placeholder="Search Products & Categories …"
            value={value}
            onChange={handleSearch}
            
          />
          <Divider sx={{ height: 25, m: 0.5 }} orientation="vertical" />
          <RouterLink to="/search-results">
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon onClick={searchFunction} />
          </IconButton>
          </RouterLink>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SearchBar;
