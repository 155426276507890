import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import Page from "./components/Page";
import Auth from "components/Auth";
import { initialize as initializeApp } from "actions/initActions";

import "react-lazy-load-image-component/src/effects/blur.css";
import "react-image-lightbox/style.css";
import "aos/dist/aos.css";
import ReactGA from 'react-ga';

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
// console.log("GA_TRACKING_ID",GA_TRACKING_ID);

ReactGA.initialize(GA_TRACKING_ID);

function App() {

  useEffect(() => {
    // Disable right-click context menu
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    // Disable F12 and Ctrl+Shift+I
    const handleKeyDown = (e) => {
      if (e.key === 'F12' || (e.ctrlKey && e.shiftKey && e.key === 'I')) {
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const dispatch = useDispatch();

  // TODO_SP Please check what param to go for pageview
  // TODO_SP potential performance checks as it calls the app twice (Strict mode makes useEffect to run twice)
  useEffect(() => {
    // Fetch all the content for the application page
    dispatch(initializeApp());

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  window.dataLayer.push({
    event: 'pageview'
  });

  return (
    <Page>
      <BrowserRouter>
        <Auth>
          <Routes />
        </Auth>
      </BrowserRouter>
    </Page>
  );
}

export default App;
