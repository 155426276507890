import React, { useEffect } from "react";
import { Card, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const SearchHeader = ({ isSearching, numFound, searchKeyword }) => {
  useEffect(() => { }, [isSearching, numFound, searchKeyword]);
  const searchKey = localStorage.getItem("searchKey")
  console.log("data", numFound);
  return (
    <Card sx={{ borderRadius: "10px", mt:3 }} elevation={5}>
      <Typography
        variant="body2"
        color={"primary.main"}
        fontWeight="bold"
        sx={{ mt: 2 }}
        mb={2}
        ml={6}
      >
        {isSearching
          ? "Search in progress..."
          : `${numFound} Results of Search : ${searchKeyword || searchKey}`}
      </Typography>

    </Card>
  );
};

SearchHeader.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  numFound: PropTypes.number.isRequired,
  searchKeyword: PropTypes.string
};

export default SearchHeader;
