import {
  ADD_FAVORITE_URL,
  REMOVE_FAVORITE_URL,
  GET_FAVORITE_URL,
  ADD_REVIEW_URL,
  ADD_PRODUCT_REVIEW_URL,
} from "config/services";
import axios from "utils/axios";

class FavouriteService {
  addFavorite = (productId, description) =>
    new Promise((resolve, reject) => {
      axios
        .post(ADD_FAVORITE_URL, { productId, description })
        .then((response) => {
          resolve(response?.data?.data);
          console.log("response add", response?.data?.data);
        })
        .catch((error) => {
          reject(error?.response?.payload);
        });
    });

  removeToFavorite = (sku) =>
    new Promise((resolve, reject) => {
      axios
        .delete(`${REMOVE_FAVORITE_URL}/${sku}`)
        .then((response) => {
          resolve(response?.data?.data);
        })
        .catch((error) => {
          reject(error?.response?.payload);
        });
    });

  getFavorite = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_FAVORITE_URL)
        .then((response) => {
          resolve(response?.data?.data);
          console.log("response get", response?.data?.data);
        })
        .catch((error) => {
          reject(error?.response?.payload);
        });
    });

  addReview = (reviewIf) =>
    new Promise((resolve, reject) => {
      console.log("PaymentServi() ", reviewIf);
      axios
        .post(ADD_REVIEW_URL, reviewIf)
        .then((resp) => {
          resolve(resp?.data?.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error?.response?.payload);
        });
    });

  addProductReview = (formData) =>
    new Promise((resolve, reject) => {
      // const reviewData = new FormData();
      // reviewData.append("reviewData", JSON.stringify(formData));
      // console.log("PaymentServi=", productreviewIf);
      axios
        .post(ADD_PRODUCT_REVIEW_URL, formData)
        .then((resp) => {
          resolve(resp?.data?.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error?.response?.payload);
        });
    });
}

const favouriteService = new FavouriteService();

export default favouriteService;
