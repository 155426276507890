import React,{useState} from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { Typography, TextField, Grid, Button, Divider } from "@mui/material";
import {useDispatch} from "react-redux"
// import AddImg from "./AddImg";
import {sendReviewAction} from "../../actions/AddReviewAction";

const Review = ({orderId, productId, title})=> {
  const [rating, setRating] = useState(2);
  const [selectedImages, setSelectedImages] = useState([]);
  const [feedback, setFeedback] = useState('')
  const dispatch = useDispatch();
  

  const sendReview=()=>{
    dispatch(sendReviewAction(rating, feedback, orderId, productId))
  }

  return (
    <Grid container rowSpacing={3} sx={{textAlign:'center'}}>
      <Grid item xs={12} md={12}>
        <Typography component="legend" variant="h4" sx={{mt:2 , mb:2}}>Order Review</Typography>
        <Divider/>
        <Rating
          sx={{ mt: 2 }}
          name="simple-controlled"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
      </Grid>
      <Grid item xs={12} md={12} sx={{display:'flex', justifyContent:'center', p:4}}>
        <TextField
          fullWidth
          id="outlined-multiline-static"
          label="Please Write ur feedback"
          onChange={(e)=>setFeedback(e.target.value)}
          multiline
          rows={4}
          
        />
      </Grid>
      <Grid item xs={12} md={12} sx={{textAlign:'center'}}>
          {/* <AddImg selectedImages={selectedImages} setSelectedImages={setSelectedImages}/> */}
      </Grid>

      <Grid item xs={12} md={12}>
        <Button variant="contained" fullWidth onClick={sendReview}> Submit</Button>
      </Grid>
    </Grid>
  );
}

export default Review;