import React from "react";
import { useSelector } from "react-redux";

import { Grid, Typography, Box } from "@mui/material";
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import CategorySlider from "./components/CategorySlider";


function CategorySection({categories}) {

//   const catalog = useSelector((state) => state.catalog);

//   const categories = catalog?.categories ? catalog?.categories : []

  

    return (
        <Grid container sx={{backgroundColor: '#f2f3f5', pt:5, pb:5}}>
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "20px",
                    }}
                >
                    <Box>
                        {/* <ViewCarouselIcon sx={{ color: "#1459BA", fontSize: "24px" }} /> */}
                    </Box>
                    <Box >
                    {/* <Typography variant="h4" color="primary.main"></Typography> */}
                    <Typography variant="h3" color="#323334" sx={{fontWeight: 600}}>Shop By Categories</Typography>
                        {/* <Typography
                            sx={{ color: "#1459BA", fontSize: "16px", fontWeight: 600 , mt:1}}
                        >
                            Shop By Categories
                        </Typography> */}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}  sx={{mt:5}}>
                <CategorySlider categories={categories}/>
            </Grid>
        </Grid>
    );
}

export default CategorySection;
