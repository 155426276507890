import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid,
  Card,
  Typography,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Rating,
  IconButton,
  Stack,
  Box,
  Alert,
} from "@mui/material";
import { useSelector } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useNavigate } from "react-router";
import { moneyFormatter } from "utils/formatUtils";
import { addToCartAction } from "../../../actions/cartAction";
import {
  addToFavorite,
  removeToFavorite,
} from "../../../actions/favoriteAction";
import { placeOrderAction } from "../../../actions/subscribeAction";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Price from "components/Price";
import { IMAGE_BASE_URL } from "config";

const SearchCard = ({
  name,
  img,
  price,
  sku,
  brand,
  productId,
  description,
  specialPrice,
  fromDate,
  tillDate,
  fav,
  discount,
  availableStock,
}) => {
  const [liked, setLiked] = useState(false);

  const dispatch = useDispatch();
  const value = 5;
  const [isAdded, setIsAdded] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const showAlert = useSelector((state) => state.cart.showAlert);

  const addToCart = async (productId) => {
    try {
      await dispatch(addToCartAction(productId, quantity, description));
      handleAddToCart();
    } catch (error) {
      console.log(error);
    }
  };
  const account = useSelector((state) => state.auth);
  const isAuthorized = account.isAuthorized;
  const isShowProductPrice = useSelector((state)=> state.app.showProductPrice);

  const checkAuth = () => {
    if (isAuthorized == true) {
      addToCart(productId, quantity, description);
    } else {
      navigate("/signin");
    }
  };

  const navigate = useNavigate();

  const redirectToProductDetails = () => {
    navigate(`/product/${sku}`);
  };

  function closeSuccessAlert() {
    setIsAdded(false);
  }
  const handleAddToCart = () => {
    // dispatch(addToCart(item));
    if (showAlert == true) {
      setIsAdded(true);
    } else {
      setIsAdded(true);
    }
    setTimeout(closeSuccessAlert, 5000);
  };

  const buy = (productId, quantity, description) => {
    try {
      if (isAuthorized == true) {
        dispatch(addToCartAction(productId, quantity, description));
        navigate("/checkout");
      } else {
        navigate("/signin");
      }
    } catch (error) {
      console.log(error);
    }

    // dispatch(placeOrderAction(centerContact, shipTo, billTo, orderDesc));
  };

  // async function subscribePlanHandl() {

  //   try {
  //     dispatch(placeOrderAction(centerContact, shipTo, billTo, orderDesc));
  //   } catch (error) {
  //     console.log("error", error.response.status);
  //   }
  // }

  const addFevFunc = (productId, description) => {
    dispatch(addToFavorite(productId, description));
  };

  const removeFevFunc = (sku) => {
    dispatch(removeToFavorite(sku));
  };

  const toggleLike = (productId, description) => {
    setLiked(!liked);

    if (liked == false) {
      dispatch(addToFavorite(productId, description));
    } else if (liked == true) {
      dispatch(removeToFavorite(sku));
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ zIndex:1000 }}>
        <Box sx={{ display: "flex", justifyContent: "end"}}>
          <IconButton>
            {fav ? (
              <FavoriteIcon
                sx={{
                  backgroundColor: "white",
                  borderRadius: "90px",
                  color: "red",
                  width: "30px",
                  height: "30px",
                  p: 0.5,
                }}
                onClick={() => removeFevFunc(sku)}
              />
            ) : (
              <FavoriteBorderIcon
                sx={{
                  backgroundColor: "white",
                  borderRadius: "90px",
                  width: "30px",
                  height: "30px",
                  p: 0.5,
                }}
                onClick={() => addFevFunc(productId, description)}
              />
            )}
          </IconButton>
        </Box>
      </Grid>


      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Card
        sx={{ borderRadius: "10px", mt: -5, maxWidth: "400px", width: "100%" }}
        elevation={5}
      >
        <CardMedia
          onClick={redirectToProductDetails}
          sx={{ height: 220 ,
            transition: "transform 0.5s",
                      "&:hover": {
                        transform: "scale(1.2)",
                    },
                    }}
          image={
            img == IMAGE_BASE_URL + "/"
              ? "assets/defaults/mperito_defualt_product.png"
              : img
          }
          //image={img}
          title="img"
          alt="not found"
        />

        <CardContent onClick={redirectToProductDetails} sx={{mt:1}}>
          <Typography gutterBottom variant="productCardTitle" component="div">
            {name.slice(0, 30)} {name.length > 30 ? "..." : ""}
          </Typography>
          {/* <Typography
          gutterBottom
          variant="body1"
          component="div"
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start ",
          }}
        >
          <b> {moneyFormatter(price)}</b>

        </Typography> */}
        
        {isShowProductPrice && 
          <Price
            price={price}
            specialPrice={specialPrice}
            fromDate={fromDate}
            tillDate={tillDate}
            discount={discount}
          />
          }
          <Rating
            name="text-feedback"
            value={value}
            readOnly
            precision={0.5}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
          <Typography variant="body1" color="text.secondary">
            {sku.slice(0, 30)} {sku.length > 30 ? "..." : ""}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {brand.slice(0, 30)}
            {sku.length > 30 ? "..." : ""}
          </Typography>
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid container spacing={2}>
            {availableStock == 0 ? (
              <Box sx={{ height: "60px" }}>
                <Typography sx={{ color: "red", ml: 3 }}>
                  Out of Stock
                </Typography>
              </Box>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={6} xl={6}>
                  <Button
                    // onClick={() => addToCart(productId, quantity, description)}
                    onClick={() => checkAuth()}
                    variant="contained"
                    startIcon={<ShoppingCartIcon />}
                    fullWidth
                  >
                    cart
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} xl={6}>
                  <Button
                    onClick={() => buy(productId, quantity, description)}
                    variant="contained"
                    endIcon={<ShoppingCartIcon />}
                    fullWidth
                  >
                    buy
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </CardActions>
        {isAdded && (
          <Alert severity={showAlert ? "success" : "error"}>
            {showAlert
              ? "Product Added Successfully To Cart"
              : "Something went wrong please try after some time"}
          </Alert>
        )}
      </Card>
      </Grid>
    </Grid>
  );
};

SearchCard.propTypes = {
  name: PropTypes.string,
  img: PropTypes.string,
  price: PropTypes.string,
  sku: PropTypes.string,
  brand: PropTypes.string,
};

export default SearchCard;
