import React, { useEffect, useState } from 'react';
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useDispatch } from "react-redux";
import AddressInfo from './AddressInfo';
import { chooseAddressNew, updateSelectedAddress } from 'actions/addressAction';

// import {setIsAddressSelectAction} from "../../../actions/addressAction";

function AddressSelect({ addressType, addresses }) {
    const dispatch = useDispatch();
    const [selectedAddress, setSelectedAddress] = useState('');
    
    // console.log("selectedAddress", selectedAddress)

    const handleAddressChange = (event) => {       
        const selectedValue = event.target.value;
        setSelectedAddress(selectedValue);
    
        if(selectedValue === "New") {
            dispatch(chooseAddressNew(addressType));
        } else {
            const addressValue = addresses.find(item => item.addressType === selectedValue);
            if(addressValue) {
                dispatch(updateSelectedAddress(addressType, addressValue));
            }
        }
    };
    
    // const handleAddressChange = (event) => {       
    //     setSelectedAddress(event.target.value);
    //     if(event.target.value === "New") {
    //         dispatch(chooseAddressNew(addressType));
         
    //     }        
  
    // };

    const handleUseSelectedAddress = () => {
        const addressValue = addresses.find(item => item.addressType === selectedAddress);
        dispatch(updateSelectedAddress(addressType, addressValue));
        
    }
   
   

    return (
        <Grid>
            <FormControl component="fieldset">
                <RadioGroup
                    aria-label="address"
                    name="address"
                    row
                    value={selectedAddress}
                    onChange={handleAddressChange}
                >
                    {addresses?.map((address, index) => (
<>
                        <FormControlLabel
                            key={index}
                            value={address.addressType}
                            control={<Radio />}
                            label={<span><AddressInfo address={address} /></span>}
                        />
                      
</>
                    ))}
                    <FormControlLabel
                        key={"new"}
                        value={"New"}
                        control={<Radio />}
                        label={<span><Typography>Add New Address</Typography></span>}
                    />

                </RadioGroup>
            </FormControl>
            <Grid
                item
                xs={12}
                alignItems={"end"}
                justifyContent={"flex-end"}
                sx={{ mt: 2 }}
            >
                <Button
                    disabled={selectedAddress === ''}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ borderRadius: 30 }}
                    onClick={handleUseSelectedAddress}
                >
                    Use Selected Address
                </Button>
            </Grid>
        </Grid>
    );
};

export default AddressSelect;
