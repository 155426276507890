import React from "react";

import { Card, CardMedia, Container, Grid, Typography } from "@mui/material";

function BrandSection({ image, content }) {
    
    return (
        <Container>
            <Grid container spacing={6} >
                <Grid item xs={12} sm={6} md={6}>
                    <Card sx={{ minWidth: "200", maxWidth: "300", boxShadow: 4 }}>
                        <CardMedia
                            component="img"
                            height="300"
                            sx={{
                                objectFit: "contain",
                            }}
                            image={image || "assets/defaults/mperito_defualt_product.png"}
                            alt="Brand Image"
                        ></CardMedia>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={6} spacing={6}>
                    <Typography variant="subtitle1" fontWeight={"regular"}>
                        Know More About Brand
                    </Typography>
                    <Typography variant="subtitle2" fontWeight={"regular"}>
                        {content}
                    </Typography>
                </Grid>
            </Grid>
        </Container>

    );
}

export default BrandSection;
