import React, { useEffect } from "react";
import ProductSlider from "./components/ProductSlider";
import { Grid, Typography, Box } from "@mui/material";
import ViewWeekIcon from '@mui/icons-material/ViewWeek';



function RecentProductsSection({recentProducts}) {

    useEffect(() => {
    }, [recentProducts]);

    return (
        <Grid container sx={{ pt:4, pb:6}}>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '20px' }}>
                    <Box>
                        {/* <ViewWeekIcon sx={{ color: '#1459BA', fontSize: '24px' }} /> */}
                    </Box>
                    <Box>
                    <Typography variant="h3" color="#323334" sx={{fontWeight: 600}}>Recent Products</Typography>
                        {/* <Typography sx={{ color: '#1459BA', fontSize: "16px", fontWeight: 600 }}>Recent Products</Typography> */}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{mt:5}}>
                <ProductSlider products={recentProducts} />
            </Grid>
        </Grid>

    );
}

export default RecentProductsSection;
