import React, { useEffect } from "react";
import {
  CardContent,
  CardMedia,
  Grid,
  Card,
  Typography,
  Button,
} from "@mui/material";

import { getEmbeddedUrl } from "utils/transformUtils";

const GreetingCard = ({ image, title, created }) => {

  useEffect(() => {
    // console.log("GreetingCard() : ",image, title, created);
  }, [image]);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Card
          sx={{
            boxShadow: 8,
            borderRadius: "20px",
            transition: "transform 0.5s",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
        >
          <CardContent>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              rowSpacing={2}
            >
              <Grid item xs={12}>
                <CardMedia
                  component="img"
                  image={image}
                  sx={{
                    backgroundSize: "contain",
                    display: "block",
                    overflow: "hidden",
                  }}
                  alt={title}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                  {created}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};



export default GreetingCard;
