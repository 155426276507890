import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import appReducer from './appReducer';
import authReducer from './authReducer';
import searchReducer from "./searchReducer";
import cartReducer from './cartReducer';
import addressReducer from "./addressReducer"
import paymentReducer from "./paymentReducer"
import contentReducer from './contentReducer';
import catalogReducer from "./catalogReducer";
import brandReducer from "./brandReducer";
import checkoutReducer from './checkoutReducer';
import favoriteReducer from './favoriteReducer';
import invoiceReducer from "./invoiceReducer";
import testimonialReducer from "./testimonials"

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  cms: contentReducer, 
  catalog: catalogReducer,  
  form: formReducer,
  search : searchReducer,
  cart: cartReducer,
  address: addressReducer,
  order: paymentReducer,
  brand: brandReducer,
  checkout: checkoutReducer,
  favorite: favoriteReducer,
  payment: paymentReducer,
  invoice: invoiceReducer,
  testimonial: testimonialReducer
});

export default rootReducer;
