import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';


const CCAvenueRedirection = ({ formBody, encodedRequest, accessCode}) => {
  // var formBody = sessionStorage.getItem("formbody");
  // var encodedRequest = sessionStorage.getItem("encodedRequest");
  // var accessCode = sessionStorage.getItem("accessCode");

   // alert(formBody);

    console.log("CCAvenueRedirectionPage : ", formBody, accessCode, encodedRequest);

  useEffect(() => {
    // Create a new form element
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';

    // Add hidden input fields for form body, access code, and encoded request
    // const formBodyInput = document.createElement('input');
    // formBodyInput.type = 'hidden';
    // formBodyInput.name = 'form_body';
    // formBodyInput.value = formBody;
    // form.appendChild(formBodyInput);

    const accessCodeInput = document.createElement('input');
    accessCodeInput.type = 'hidden';
    accessCodeInput.name = 'access_code';
    accessCodeInput.value = accessCode;
    form.appendChild(accessCodeInput);

    const encodedRequestInput = document.createElement('input');
    encodedRequestInput.type = 'hidden';
    encodedRequestInput.name = 'encRequest';
    encodedRequestInput.value = encodedRequest;
    form.appendChild(encodedRequestInput);

    // Append the form to the document body and submit it
    document.body.appendChild(form);

    console.log("CCAvenueRedirectionPage submitting form");
    form.submit();

    return () => {
      // Cleanup: Remove the form when the component unmounts
      document.body.removeChild(form);
    };
  }, [formBody, accessCode, encodedRequest]);

  // Render a message or loading spinner while redirecting
  return (
    <div>
      <p>Redirecting to CCAvenue payment page...</p>
    </div>
  );
};



const CCAvenueRedirectionPage = ({ }) => {

  const ccavenueData = useSelector((state) => state.payment.ccavenueData);
  const accessCode =  useSelector((state) => state.payment.paymentDetails?.paymentGWAccessCode);

  


  console.log("CCAvenueRedirectionPage: ", ccavenueData);


  



  // Render the redirection page if ccavenueData is available
  return ccavenueData ? (
    <CCAvenueRedirection
      formBody={ccavenueData.formbody}
      accessCode={accessCode}
      encodedRequest={ccavenueData.encodedRequest}
    />
  ) : (
    <div>Loading...</div> // Or you can render a loading indicator while waiting for the data
  );

}




export default CCAvenueRedirectionPage;
