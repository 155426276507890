/* eslint-disable no-param-reassign */
import produce from "immer";

import { HOME_CAROUSEL_ITEMS, CATEGORIES, NEWS, VIDEOS } from "config/content";

import {
    FETCH_CUSTOMER_DETAILS, FETCH_CUSTOMER_DETAILS_SUCCESS, FETCH_CUSTOMER_DETAILS_FAILURE
} from "actions/contentAction";
import { CUSTOMER_ID } from "config";
import { IMAGE_BASE_URL } from "config";


const initialState = {
    customerId: CUSTOMER_ID,
    appName: undefined,
    appDescription: undefined,
    brandId: undefined,
    brandName: undefined,
    businessName: undefined,
    logoUrl: undefined,
    shortLogoUrl: undefined,
    gstin: undefined,
    paymentGateway: "OFFLINE",
    appType: "Shop",
    isDemo: false, // This will enable the demo banner for testing purpose TODO_SP
    showQuotation: false,
    showPOS: false, // This feature is not implemented Point of Sale terminal
    showProductPrice: false,
    showSubcategories: false,  // TODO_SP check what impacts to be done here
    registeredEmail:undefined,
    isTaxInclusivePrice: false,
    appURL:'',
    pinchZoom:false,
    showSKU:false,
    showBrand:false,
    showSqrCategory:false
};


const appReducer = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_CUSTOMER_DETAILS_SUCCESS: {


            const {  GSTN, paymentGateway, isEngineeringType, brandId, showQuotationRequest,
                showPos, businessName, showProductPrice, showSubcategories , registeredEmail, isTaxInclusivePrice, appDetails, pinchZoom, showSKU, showBrand, showSqrCategory} = action.payload?.customerDetails;
            const { brandDetails } = action.payload?.customerDetails;

            // console.log("appReducer:22", brandDetails, 
            //  paymentGateway, isEngineeringType, brandId, showQuotationRequest,
            // showPos, businessName, registeredEmail);

            const brandInfo = brandDetails?.length> 0 ?  brandDetails[0] : undefined;

            console.log("appReducer: brand", brandDetails);

            return produce(state, (draft) => {
                // draft.customerId = customerId; This should be based on env
                draft.appName = brandInfo?.appName;
                draft.brandName = brandInfo?.brandName;
                draft.brandId = brandInfo?.brandId;
                draft.businessName = businessName;
                draft.appDescription = brandInfo?.appDescription;
                draft.logoUrl = IMAGE_BASE_URL + "/" + brandInfo?.logoURL
                draft.gstin = GSTN;
                draft.shortLogoUrl = brandInfo?.shortLogo;
                draft.paymentGateway = paymentGateway;
                draft.appType = isEngineeringType ? "Digital Briefcase" : "Shop";
                draft.showQuotation = showQuotationRequest;
                draft.showPOS = showPos;
                draft.showProductPrice = showProductPrice;
                draft.showSubcategories = showSubcategories;
                draft.registeredEmail = registeredEmail;
                draft.isTaxInclusivePrice = isTaxInclusivePrice;
                draft.appURL = appDetails;
                draft.pinchZoom = pinchZoom;
                draft.showSKU = showSKU;
                draft.showBrand = showBrand;
                draft.showSqrCategory = showSqrCategory
                //console.log("showSqrCategory",showSqrCategory)
                
            });
        }
            break;


        default:
            {
                return state;
            }
            break;
    }





};

export default appReducer;

