import jwtDecode from "jwt-decode";
import axios from "utils/axios";
import { UserProfile } from "interface/userProfileIf";

import {
  SIGNIN_EMAIL_PASS_URL,
  SIGNIN_MOBILE_REQ_OTP_URL,
  SIGNIN_MOBILE_VERIFY_OTP_URL,
  SIGNIN_SILENT_URL,
  SIGNOUT_URL,
  SIGNUP_URL,
  SIGNUP_MOBILE_URL,
  SIGNUP_MOBILE_VERIFY_OTP_URL,
} from "config/services";

import { CUSTOMER_ID } from "config";

class AuthService {
  setUserId = (userId) => {
    if (userId) {
      localStorage.setItem("userId", userId);
    } else {
      localStorage.removeItem("userId");
    }
  };

  setUserMobile = (mobile) => {
    if (mobile) {
      localStorage.setItem("mobile", mobile);
    } else {
      localStorage.removeItem("mobile");
    }
  };
  setUserEmail = (email) => {
    if (email) {
      localStorage.setItem("email", email);
    } else {
      localStorage.removeItem("email");
    }
  };

  isUserExist = (userExist) => {
    if (userExist === "SIGNUP_NO_USER") {
      localStorage.setItem("userExist", userExist);
    } else {
      localStorage.removeItem("userExist");
    }
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common.Authorization = `${accessToken}`;
    } else {
      localStorage.removeItem("accessToken");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem("accessToken");

  getUserId = () => localStorage.getItem("userId");

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    // console.log(
    //   "isValidToken() accessToken: ",
    //   accessToken,
    //   " decoded",
    //   decoded
    // );
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          this.setUserId(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    // console.log("handleAuthentication: ", accessToken);

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
      this.setUserId(null);
    }
  }

  isAuthenticated = () => !!this.getAccessToken();

  signinWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      axios
        .post(SIGNIN_EMAIL_PASS_URL, { email, password })
        .then((response) => {

          if (response?.data?.error) {
            reject(response?.data?.message);
          } else {
            if (response?.data?.payload) {
              this.setSession(response?.data?.payload?.token);
              this.setUserId(response?.data?.payload?.user?.userId);
              this.setUserEmail(response?.data?.payload?.user?.email);
              this.setUserMobile(response?.data?.payload?.user?.mobile);
              
            }
            resolve(response?.data?.payload?.user);
          }
        })
        .catch((error) => {
          if (error?.response) {
            reject(error?.response?.data);
          }
          reject(error);
        });
    });

    signUp = (name, mobile, email) =>
    new Promise((resolve, reject) => {
      axios
        .post(SIGNUP_URL, { name, mobile, email, customerId: CUSTOMER_ID, userType: "USER" })
        .then((response) => {
          // console.log(
          //   "signUp Mobile ===()" + name,
          //   mobile,
          //   JSON.stringify(response)
          // );


          if (response?.data?.error) {
            reject(response?.data?.message);
          } else {
            if (response?.data && response?.data?.data?.token) {
              this.setSession(response?.data?.data?.token);
              this.setUserId(response?.data?.data?.user?.userId);
              this.setUserEmail(response?.data?.data?.user?.email);
              this.setUserMobile(response?.data?.data?.user?.mobile);
              
            }}
            resolve(response?.data?.data?.user);
        })
        .catch((error) => {
          // console.log(
          //   "signUp() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          reject(error);
        });
    });



  signUpMobile = (name, mobile) =>
    new Promise((resolve, reject) => {
      axios
        .post(SIGNUP_MOBILE_URL, { name, mobile, customerId: CUSTOMER_ID })
        .then((response) => {
          // console.log(
          //   "signUp Mobile ===()" + name,
          //   mobile,
          //   JSON.stringify(response)
          // );

          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data?.message);
          }
        })
        .catch((error) => {
          // console.log(
          //   "signInSendMobileOTP() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          reject(error);
        });
    });

    signUpMobileVerifyOTP = (mobile, name, otp) =>
    new Promise((resolve, reject) => {
      //const payload = { "email": email };
      axios
        .post(SIGNUP_MOBILE_VERIFY_OTP_URL, { mobile, name, otp, customerId: CUSTOMER_ID })
        .then((response) => {
          // console.log(
          //   "signUp Mobile ===()" + mobile,
          //   otp,
          //   JSON.stringify(response)
          // );
          
          if (response?.data?.error) {
            reject({message:response?.data?.message});
          } else {
            if (response?.data?.data) {
              //  this.setSession(response?.data?.data?.token);
              // this.setUserId(response?.data?.data?.user?.userId);
              // this.setUserEmail(response?.data?.data?.user?.email);
              // this.setUserMobile(response?.data?.data?.user?.mobile);
              
            }
            // resolve(user);
            resolve(response?.data?.data);
            window.localStorage.removeItem("userExist");
          }
        })
        .catch((error) => {
          // console.log(
          //   "signInSendMobileOTP() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          reject(error);
        });
    });

    signinMobileReqOTP = (mobile) =>
    new Promise((resolve, reject) => {
      //const payload = { "email": email };

      axios
        .post(SIGNIN_MOBILE_REQ_OTP_URL, { mobile, customerId: CUSTOMER_ID })
        .then((response) => {
          // console.log("signinMobileReqOTP()" + JSON.stringify(response));

          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data?.message);
          }
        })
        .catch((error) => {
          // console.log(
          //   "signinMobileReqOTP() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          reject(error);
        });
    });

    signinMobileVerifyOTP = (mobile, otp) =>
    new Promise((resolve, reject) => {
      axios
        .post(SIGNIN_MOBILE_VERIFY_OTP_URL, { mobile, otp, customerId: CUSTOMER_ID })
        .then((response) => {


          if (response?.data?.error) {
            reject({message:response?.data?.message});
          } else {
            const payload = response?.data?.data;
            if (payload) {  
              this.setSession(payload?.token);
              this.setUserId(payload?.user?.userId);
              this.setUserEmail(payload?.user?.email);
              this.setUserMobile(payload?.user?.mobile);
              this.isUserExist(payload ? payload : '');
            }
            // resolve(user);
            resolve(payload?.user);
          }
        })
        .catch((error) => {
          // console.log(
          //   "signinMobileVerifyOTP() Service(Response Error):" +
          //     JSON.stringify(error)
          // );
          if(error?.response){
            reject(error?.response?.data)
          }
          reject(error);
        });
    });

  loginInWithToken = (userId) =>
    new Promise((resolve, reject) => {
      axios
        .get(SIGNIN_SILENT_URL, {
          params: { userId: userId },
        })
        .then((response) => {
          if (response.data) {
            const userInfo = response.data?.data?.user;

            // console.log("loginInWithToken->User: userInfo ", response.data);
            const user = new UserProfile(userInfo);
            // console.log("loginInWithToken->User: user " + JSON.stringify(user));

            resolve(user);
          } else {
            // console.log(
            //   "loginInWithToken->reject" + JSON.stringify(response.data.error)
            // );
            reject(response.data.error);
          }
        })
        .catch((error) => {
          // console.log("loginInWithToken->error" + JSON.stringify(error));
          reject(error);
        });
    });

    signOut = () => {
    new Promise((resolve, reject) => {

      // console.log("authService:signOut ");
      this.setSession(null);
      this.setUserId(null);

      axios
        .post(SIGNOUT_URL)
        .then((response) => {
          this.setSession(null);
          this.setUserId(null);
          this.setUserEmail(null);
          this.setUserMobile(null);

          // console.log(
          //   "authService:signOut: Response",
          //   response.data
          // );
          resolve();
        })
        .catch((error) => {
          if (error.response) {
            // console.log(
            //   "authService:signOut :Error2",
            //   error.response
            // );
            reject(error.response.data);
          }
          reject(error);
        });
    });
  };

  signUpResendOTP =(mobile)=>{
    new Promise((resolve, reject)=>{
      axios
      .post(SIGNUP_MOBILE_URL, {mobile, customerId: CUSTOMER_ID}).then((response)=>{
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
        
          reject(error.response.data);
        }
        reject(error);
      });
    })
  }
}

const authService = new AuthService();

export default authService;
