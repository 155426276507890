import React from "react";

import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

import { Grid, Tab } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";

import { ProductCard } from "views/Home/components/RecentProductsSection";
import Container from "@mui/material/Container";

function ProductGridView({ products }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const favourites = useSelector((state) => state.favorite?.favourites);
  const favouritesID = favourites.map((item) => item?.productId);

  const isFavourites = (productId) => favouritesID.includes(productId);

  return (
    <Container maxWidth="xl">
    <Grid container spacing={0} >
      {products?.map((item) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          xl={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <ProductCard
            image={item.baseImage}
            title={item.name}
            price={item.price?.sellPrice}
            specialPrice={item.price?.specialPrice?.price}
            fromDate={item.price?.specialPrice?.from}
            tillDate={item.price?.specialPrice?.till}
            discount={item.price?.specialPrice?.discount}
            availableStock={item?.availableStock}
            productId={item.productId}
            description={item.description}
            sku={item.sku}
            brand={item.brand}
            fav={isFavourites(item?.productId) ? true : false}
          />
        </Grid>
      ))}
      </Grid>
      </Container>
  );
}

export default ProductGridView;
