import React, { useEffect } from "react";
import {
  CardContent,
  CardMedia,
  Grid,
  Card,
  Typography,
  Button,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { getEmbeddedUrl } from "utils/transformUtils";
import { IMAGE_BASE_URL } from "config/index";
const VideoCard = ({ video }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    // console.log("VideoCard() : ",video);
  }, [video]);

  return (
    <Card sx={{ boxShadow: 8, borderRadius: "20px",height:'180px', width: isMobileView ? "260px" : "310px" }}>           
      <CardMedia sx={{objectFit:'contain', height:'180px'}} component="iframe" autoPlay={true} controls src={video} allowFullScreen={true} frameBorder={0} />
    </Card>
  );
};



export default VideoCard;
