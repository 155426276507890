import React, { useEffect } from "react";
import { Grid, Card } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Typography, Box, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { cartItemDeleteAction } from "actions/cartAction";
import { Add, Remove } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IMAGE_BASE_URL } from "config";
import { moneyFormatter } from "../../utils/formatUtils";
import { addToCartAction } from "../../actions/cartAction";
import { useSelector } from "react-redux";
import Price from "components/Price";

const MiniCartItem = ({ item, index, getCartAction, cartItems }) => {
  
  const isShowProductPrice = useSelector((state) => state.app.showProductPrice);
  const isTaxInclusivePrice = useSelector((state)=> state.app.isTaxInclusivePrice);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(item?.quantity);

  const variations = useSelector((state) => state?.catalog);
  // const CONFIGURABLE = variations?.variations?.productType;
  const IsConfigurable = variations?.variations?.product?.productType;
  // console.log("variations", variations);
  const handleClickOpen = (index) => {
    setOpen(true);
  };

  const variationsVar = useSelector(
    (state) => state?.catalog?.variationsVariable
  );
  
  const handleClose = () => {
    setOpen(false);
    setValue(item?.quantity);
  };

  const cartItemDelete = (productId) => {
    try {
      dispatch(cartItemDeleteAction(productId));
    } catch {
      console.log("error");
    }
    handleClose();
  };

  if (item?.quantity == 0) {
    setOpen(true);
    setValue(0);
  }

  //   const priseGrid = {
  //     display: "flex",
  //     alignItems: "center",
  //   };
  // const priseGridMobile ={
  //   display: "flex",
  //     alignItems: "end",
  //     marginTop:'-35px'
  //   }
  const productId = item?.productId;
  const quantity = item?.quantity;
  const description = item?.description;

  const incrementCart = () => {
    const newValue = value + 1;
    setValue(newValue);
    dispatch(addToCartAction(productId, newValue, description));
  };

  const decrementCart = () => {
    const newValue = value - 1;
    if (newValue == 0) {
      setOpen(true);
    }
    setValue(newValue);
    dispatch(addToCartAction(productId, newValue, description));
  };


  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {}, [cartItems, value, item])

  return (
    <Card sx={{ borderRadius: 2, p: 0, mb: 2 }}>
      <Grid container spacing={4} key={item?.index}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          xl={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box sx={{ width: "100%", height: isMobileView ? "220px" : "150px" }}>
            <CardMedia
              component="img"
              // sx={{ width: "100%", height: "100%" }}
              // sx={{objectFit: "contain", aspectRatio: "3/2",}}
              width="100%"
              height="100%"
              // image="/assets/general/apple.jpg"
              // image= {item.baseImageUrl}
              image={item?.baseImageUrl == '' ? "assets/defaults/mperito_defualt_product.png" : IMAGE_BASE_URL + "/" + item?.baseImageUrl}
              // image={IMAGE_BASE_URL + "/" + item?.baseImageUrl || "assets/defaults/mperito_defualt_product.png"}
              alt="product image"
            />
          </Box>
        </Grid>

        <Grid item  xs={12} sm={12} md={7} xl={7}>
          <CardContent sx={{ textAlign: "left" }} spacing={0}>
            <Typography component="div" variant="h6" sx={{ mt: 1 }}>
              {item?.brand}
            </Typography>
            {/* <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
              sx={{ mt: 1 }}
            >
              {item?.Number}
            </Typography> */}

            <Box>
              <Typography 
                variant="CardTitle"
                color="text.secondary"
                sx={{ mb: 3 }}
              >
              
                {item?.productName.slice(0, 18)} {item?.productName?.length > 18 ? "..." : ""}
              </Typography>
            </Box>
            <Box sx={{ mt: 0 }}>
              <Typography variant="CardSKU" color="text.secondary">
                {item?.productSKU}
              </Typography>
            </Box>

            {isShowProductPrice && (
              <>
                <Price
                  price={item?.priceDetail?.sellPrice}
                  specialPrice={item?.priceDetail?.specialPrice?.price}
                  fromDate={item?.priceDetail?.specialPrice?.from}
                  tillDate={item?.priceDetail?.specialPrice?.till}
                  discount={item?.priceDetail?.specialPrice?.discount}
                />
                <Typography variant="body2" gutterBottom>
                  {isTaxInclusivePrice ? "Inclusive of all taxes" : ""}
                </Typography>
              </>
            )}

            {item?.variations?.map((list)=>{
              const [key, value] = Object.entries(list)[0];
              return(
                <Box sx={{display:"flex"}}>
                <Typography variant="body2" gutterBottom sx={{fontWeight: "bold"}}>{key} :{""} </Typography>
                <Typography variant="body2" gutterBottom>{""} {value}</Typography>
                </Box>
              )
            }
            )}

            {cartItems?.map((list) => (
              <>
                {list?.variations?.map((items) => (
                  <>
                    {items[variationsVar?.mapVariable] && (
                      <>
                        {IsConfigurable ? (
                          <Box>
                            <Typography
                              variant="CardSKU"
                              color="text.secondary"
                            >
                              {variationsVar?.mapVariable} :{" "}
                              {items[variationsVar.mapVariable]}
                            </Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                    {items[variationsVar?.mapVariable2] && (
                      <>
                        {" "}
                        {IsConfigurable ? (
                          <Box>
                            <Typography
                              variant="CardSKU"
                              color="text.secondary"
                            >
                              {variationsVar?.mapVariable2} :
                              {items[variationsVar?.mapVariable2]}
                            </Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </>
                ))}
              </>
            ))}

            <Box sx={{ display: "flex", mt: 1 }}>
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ backgroundColor: "#f6f7fb" }}
                onClick={decrementCart}
              >
                <Remove fontSize="inherit" />
              </IconButton>

              <Typography sx={{ ml: 2 }}>
                <input
                  type="number"
                  value={value}
                  style={{
                    width: "60px",
                    fontWeight: "800",
                    fontSize: "16px",
                    border: "none",
                  }}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Typography>

              <IconButton
                aria-label="delete"
                size="small"
                sx={{ backgroundColor: "#f6f7fb" }}
                onClick={incrementCart}
              >
                <Add fontSize="inherit" />
              </IconButton>
            </Box>
          </CardContent>
        </Grid>

        {/* <Grid
          item
          xs={9}
          sm={9}
          md={2}
          xl={2}
          
          sx={{ display: "flex", alignItems: "center" }}
        >
          <CardContent>
            <Typography
              component="div"
              variant="body1"
              sx={{ display: "flex", alignItems: "center" }}
            >
             

              {moneyFormatter(item?.total)}
              
            </Typography>
          </CardContent>
        </Grid> */}
        <Grid
          item
          xs={7}
          sm={6}
          md={1}
          xl={1}
          sx={{ display: "flex", alignItems: "start", justifyContent: "end" }}
        >
          <IconButton aria-label="delete">
            <DeleteIcon onClick={() => handleClickOpen(index)} />
          </IconButton>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Want to Delete item from cart
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" onClick={handleClose}>
            No
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => cartItemDelete(item.productId)}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default MiniCartItem;
