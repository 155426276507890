import React, { useEffect } from "react";
import { Grid, Card, Typography, Box, CardMedia } from "@mui/material";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { moneyFormatter } from "../../utils/formatUtils";
import { useDispatch, useSelector } from "react-redux";
import Price from "components/Price";
import { IMAGE_BASE_URL } from "config";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const FavoriteList = ({ favourites, closeDrawer }) => {
  const theme = useTheme();
  const isShowProductPrice = useSelector((state) => state.app.showProductPrice);
  const isTaxInclusivePrice = useSelector((state)=> state.app.isTaxInclusivePrice);
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  //   useEffect(()=>{

  //   },[favourites])
  const navigate = useNavigate();

  const redirectToProductDetails = (sku) => {
    navigate(`/product/${sku}`);
    closeDrawer(true);
  };

  return (
    <Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          pr: 2,
          mt: 1,
          borderBottom: "0.1px solid #808080",
        }}
      >
        <Box sx={{ ml: 2 }}>
        {isMobileView ? <Typography variant="h5">Favourites List</Typography> : <Typography variant="h3">Favourites List</Typography> } 
        </Box>
      </Grid>
      {favourites?.map((item) => (
        <Grid
          container
          onClick={() => redirectToProductDetails(item.productSKU)}
        >
          <Card sx={{ p: 1, borderRadius: 3, mb: 2, mt: 2 }}>
            <Grid container sx={{ p: 0 }}>
              <Grid item xs={12} sm={12} md={3} xl={3}>
                <CardMedia
                          width="100%"
                            height="200px"
                            component="img"
                            image={item?.baseImageUrl === '' ? "assets/defaults/mperito_defualt_product.png": IMAGE_BASE_URL + "/" + item.baseImageUrl }
                            // image={IMAGE_BASE_URL + "/" + item.baseImageUrl || "assets/defaults/mperito_defualt_product.png"}
                            // image={IPHONE}
                            title=""
                            // sx={{
                            //   objectFit: "contain",
                            //   aspectRatio: "3/2",
                            // }}
                          />
              </Grid>
              <Grid item xs={12} sm={12} md={8} xl={8} sx={{ml:2}}>
                <Typography style={{ color: "black" }}>
                  <b> {item.productName}</b>
                </Typography>
                <Typography style={{ color: "black" }}>
                  {item.productSKU}
                </Typography>
                {/* <Typography style={{ color: "black" }}>
                  {moneyFormatter(item?.price)}
                </Typography> */}
                {isShowProductPrice && (
                  <>
                  <Price
                    price={item?.priceDetail?.sellPrice}
                    specialPrice={item?.priceDetail?.specialPrice?.price}
                    fromDate={item?.priceDetail?.specialPrice?.from}
                    tillDate={item?.priceDetail?.specialPrice?.till}
                    discount={item?.priceDetail?.specialPrice?.discount}
                  />
                  <Typography variant="body2" gutterBottom>
                  {isTaxInclusivePrice ? "Inclusive of all taxes" : ""}
                </Typography>
                </>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default FavoriteList;
