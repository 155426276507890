import { createTheme } from '@mui/material/styles';

const createCustomTheme = (info) => {
  return createTheme({
    palette: {
      primary: {
        main: info?.primaryColor || '#000000', // Default to a fallback color if not provided
      },
      secondary: {
        main: info?.secondaryColor || '#000000', // Default to a fallback color if not provided
      },
      text: {
        primary: info?.fontColor || '#000000',
      },
      background: {
        paper: info?.backgroundColor || '#ffffff',
        default: info?.backgroundColor || '#ffffff',
        // level2: '#f5f5f5',
        // level1: '#ffffff',
      },
      // You can add more color overrides here as needed
    },
  });
};

export default createCustomTheme;