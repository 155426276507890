import React from "react";

import { Container, Grid, Typography } from "@mui/material";
import InfoCard from "./InfoCard";

import {
    Business as BusinessIcon,
    SupportAgent as SupportAgentIcon,
    WhatsApp as WhatsAppIcon,
    Phone as PhoneIcon,

} from '@mui/icons-material';


function ContactSection({ contactDetails }) {

    return (
        <Container maxWidth >
            <Grid container spacing={3}>
                <Grid item sm={12} md={3}>
                    <InfoCard 
                    icon={(<BusinessIcon />)} 
                    title="Registered Address" 
                    content={contactDetails?.registeredAddress} />
                </Grid>
                <Grid item sm={12} md={3}>
                    <InfoCard 
                    icon={(<SupportAgentIcon />)} 
                    title="Helpline" 
                    content={contactDetails?.helpLine} />
                </Grid>
                <Grid item sm={12} md={3}>
                    <InfoCard 
                    icon={(<PhoneIcon />)} 
                    title="Contact #" 
                    content={contactDetails?.mobile} />
                </Grid>
                <Grid item sm={12} md={3}>
                    <InfoCard 
                    icon={(<WhatsAppIcon />)} 
                    title="WhatsApp" 
                    content={contactDetails?.whatsApp} />
                </Grid>
                
            </Grid>
        </Container >
    );
}

export default ContactSection;
