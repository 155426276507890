import React from "react";
import { Grid, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router";

const RecursiveCategoryList = ({ categories, setShowCard }) => {
  
  const navigate = useNavigate();

  const handleCategorySelection = (categoryId, title) => {
    navigate(`/category/${categoryId}/${title}`)
    // setShowCard(false)
  }

  const renderCategory = (category) => {
    console.log("categories11", categories)
    return (
      <>
        {categories.length > 0 && (
          <Grid container sx={{ ml: 3 }}>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} key={category._id} sx={{p:2}}>
              <Typography variant="body1" sx={{textTransform:"lowercase", color:'gray', cursor: "pointer"}} onClick={()=>handleCategorySelection(category.categoryId, category.name)} >{category?.name}</Typography>
              <Divider/>
              {category?.children && category?.children?.length > 0 && (
                <ul>
                  {category.children.map((child) => (
                    <li key={child._id} onClick={()=>handleCategorySelection(child.categoryId, child.name)}>
                      <Typography variant="caption" sx={{textTransform:"lowercase", color:'gray', cursor: "pointer", }}>{child.name}</Typography>
                      {child?.children && child?.children?.length > 0 && (
                        <RecursiveCategoryList categories={child?.children} />
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  return <div>{categories?.map((category) => renderCategory(category))}</div>;
};

export default RecursiveCategoryList;
