import React, { useState } from "react";
import PropTypes from 'prop-types';

import { CopyRight } from "components";
import { TopBar, NavBar } from './components';
import { Box, Grid } from '@mui/material';


const Dashboard = ({ children }) => {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    return (
        <Box>
            <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
            <NavBar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
            />
            <Grid container sx={{ height: 'calc(100% - 64px)', ml: { xs: '0px', sm: "0px", md: '256px' }, display: "flex", justifyContent: "flex-start" }} >
                <main>
                    {children}
                </main>
            </Grid>

            <Grid alignItems="flex-end" >
                <CopyRight />
            </Grid>
        </Box>
    )
}

Dashboard.propTypes = {
    children: PropTypes.node,
};

export default Dashboard;
