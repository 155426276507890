import React, { useEffect, useState } from "react";
import { Dashboard as DashboardLayout } from "layouts";
import Container from "@mui/material/Container";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
  ListItemText,
  List,
  ListItemAvatar,
  ListItem,
  CardHeader,
  Avatar,
} from "@mui/material";
import { AccountCircle, Call, Email, VerifiedUser } from "@mui/icons-material";
import ProfileForm from "./ProfileForm";
import AddressSection from "./AddressSection";

function Profile() {
  const [showForm, setShowForm] = useState(true);
  const { user, activeRole, businessInfo } = useSelector(state => state.auth);
  const showFormFun = () => {
    setShowForm(!showForm);
  };

//   const userData = localStorage.getItem("profile");

  const handleEdit=()=>{
    setShowForm(!showForm)
  }

  useEffect(()=>{

  },[user])
  return (
    <DashboardLayout>
    
      <Container maxWidth="md" sx={{ mt: 13, mb:5 }}>
        {/* <Box sx={{ display: "flex" }}>
          <Typography variant="h3" color="text.secondary">
            Personal Information
          </Typography>
          <Typography
             onClick={showFormFun}
            variant="body"
            color="text.secondary"
            sx={{ ml: 2, color: "blue" }}
          >
            Edit
          </Typography>
        </Box> */}
        {showForm ? (
            <Grid container spacing={4} maxWidth="xl" sx={{mb:3}}>
          <Grid item xs={12} md={12} xl={12}>
            <Card>
              <CardHeader
                title="Personal Information"
                subheader="Edit"
                onClick={handleEdit}
                sx={{cursor: "pointer"}}
              >
              
              </CardHeader>
              <Divider  />
              <CardContent>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <AccountCircle />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body"
                            color="text.primary"
                          >
                            {user?.name}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  {/* <Divider variant="inset" component="li" /> */}
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Call />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {user?.mobile}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  {/* <Divider variant="inset" component="li" /> */}
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Email />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {user?.email}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>

                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <VerifiedUser />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {user?.GSTIN || user?.GSTN}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          </Grid>
        ) : (
         
          <ProfileForm handleEdit={handleEdit} email={user?.email} name={user?.name} mobile={user.mobile} gstin={user.GSTIN || user?.GSTN} />
         
        )}
        <Grid item xs={12} md={12} xl={12}>
        <AddressSection title={"My Addresses"}/>
        </Grid>
      </Container>
    </DashboardLayout>
  );
}

export default Profile;
