/* eslint-disable no-param-reassign */
import produce from "immer";

import { GET_ADDRESS_SUCCESS, IS_ADDRESS_SELECT, DELETE_ADDRESS_SUCCESS } from "actions/addressAction";

const initialState = {
  address: [],
  addressSelected : false,
  previewOrderbtnClick : false,
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADDRESS_SUCCESS:
    case DELETE_ADDRESS_SUCCESS:
      {
        const address = action.payload        ;

        return produce(state, (draft) => {
          draft.address = address;
          // console.log("addressaddressaddressaddressaddressaddressaddress", draft.address);
        });
      }

      break;

      case IS_ADDRESS_SELECT : {
        return produce(state, (draft) => {
          draft.addressSelected = true ;
          // console.log("addressSelected redu", draft.address);
        });
      }
     
      break;

    default:
      {
        return state;
      }
      break;
  }
};

export default addressReducer;
