import React from "react";

import { Box, Container, IconButton, Grid, Typography, Card } from "@mui/material";

function InfoCard({ icon, title, content }) {

    return (

        <Card sx={{maxHeight:'200px', height:'180px'}}>
            <Grid
                container
                direction="column"
                justifyContent="space-around"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <IconButton href={"item.link"}>
                        {icon}
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" textAlign={"center"}>{content}</Typography>
                </Grid>
            </Grid>

        </Card>
    );
}

export default InfoCard;


