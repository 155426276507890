import React, { useEffect, useState } from "react";

import { Dashboard, Main as Main } from "layouts";
import { Grid, Card, Box, Typography, Button, Rating } from "@mui/material";
// import { getMyOrderDetailsAction } from "actions/orderAction";
import { useDispatch, useSelector } from "react-redux";
import { GET_ORDER_DETAILS } from "config/services";
import Container from "@mui/material/Container";
import axios from "utils/axios";
import { moneyFormatter } from "../../utils/formatUtils";
import { DataGrid } from "@mui/x-data-grid";
import { dateFormatter } from "utils/formatUtils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import Review from "./OrderReview";
import { IMAGE_BASE_URL } from "config";
import AddReviewPopup from "./AddReviewPopup";

function OrderDetails() {
  const navigate = useNavigate();
  const myAllOrders = useSelector((state) => state?.order?.myOrders);
  // const myAllOrdersGridData = useSelector((state) => state?.order?.myOrders);
  // console.log("myAllOrdersGridData", myAllOrdersGridData)

  let params = useParams();
  
  const dispatch = useDispatch();
 

  useEffect(() => {}, [myAllOrders]);

  const [orderDetails, setOrderDetails] = useState("");
  console.log("orderDetails", orderDetails?.items);
  const [productDetails, setProductDetails] = useState("");

  function fetchOrderDetail(id) {
    axios.get(`${GET_ORDER_DETAILS}/${id}`).then((response) => {
      if (response?.data?.data) {
        setOrderDetails(response.data.data);
        setProductDetails(response.data.data.items);
      } else {
        setOrderDetails([]);
      }
    });
  }



  const productId = orderDetails?.items?.map(id => id?.productId );
  const productIdAsNumber = productId ? parseInt(productId[0]) : [];
  console.log("productDetails", productDetails);
  useEffect(() => {
    fetchOrderDetail(params.orderId);
    // fetchOrderDetail("1102");
  }, []);


  const columns = [
    { field: "_id", headerName: "Order Id", width: 90 },
    {
      field: "productName",
      headerName: "Product Name",
      width: 150,
      editable: true,
    },
    {
      field: "productSKU",
      headerName: "Product SKU",
      width: 150,
      editable: true,
    },
    {
      field: "price",
      headerName: "price",
      width: 150,
      editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
      editable: true,
    },
    {
      field: "total",
      headerName: "Total",
      width: 150,
      editable: true,
    },
  ];

  const openPDF = () => {
    let orderId = params.orderId;
    navigate(`/order-pdf/${orderId}`);
  };


  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  const [title, setTitle] = useState(false)

  const handleClickOpen = () => {
    setOpen(true);
    setTitle(true)
  };
  const handleClose = () => {
    setOpen(false);
  };

  
  return (
    <Dashboard>
      <Container
        maxWidth="md"
        className="center-modal"
        sx={{
          mb: 5,
          mt: 5,
          flexDirection: "column",
          backgroundColor: "#f5f8fc",
        }}
      >
        <Grid container spacing={4} sx={{ p: 3, mt: 2 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button variant="outlined" onClick={openPDF}>
              Download PDF
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button variant="outlined" onClick={handleClickOpen}>

            <Rating
            readOnly
  name="simple-controlled"
  value={value}
  onChange={(event, newValue) => {
    setValue(newValue);
  }}
/>
              {/* {productDetails?.map((items)=>(
                <>
                  <p>{items.baseImageUrl}</p>
                  <img src={ IMAGE_BASE_URL + "/" + items.baseImageUrl}/>
                </>
              ))}  */}
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={7}>
            <Card sx={{ mb: 2, p: 4 }}>
              <Grid container spacing={1}>
                <Grid item xs={6} md={4}>
                  <Typography variant="subtitle2">Name :</Typography>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Typography>{orderDetails?.billTo?.address?.name}</Typography>
                </Grid>

                <Grid item xs={6} md={4}>
                  <Typography variant="subtitle2">Bill To :</Typography>
                </Grid>

                <Grid item xs={6} md={8}>
                  <Typography>
                    {orderDetails?.billTo?.address?.addressLine1} {", "}
                    {orderDetails?.billTo?.address?.addressLine2} {", "}
                    {orderDetails?.billTo?.address?.city} {", "}
                    {orderDetails?.billTo?.address?.country} {", "}
                    {orderDetails?.billTo?.address?.pinCode} <br />
                    {orderDetails?.billTo?.address?.email} <br />
                    {orderDetails?.billTo?.address?.mobile}
                  </Typography>
                </Grid>

                <Grid item xs={6} md={4}>
                  <Typography variant="subtitle2">Ship To :</Typography>
                </Grid>

                <Grid item xs={6} md={8}>
                  <Typography>
                    {orderDetails?.shipTo?.address?.addressLine1} {", "}
                    {orderDetails?.shipTo?.address?.addressLine2} {", "}
                    {orderDetails?.shipTo?.address?.city} {", "}
                    {orderDetails?.shipTo?.address?.country} {", "}
                    {orderDetails?.shipTo?.address?.pinCode} <br />
                    {orderDetails?.shipTo?.address?.email} <br />
                    {orderDetails?.shipTo?.address?.mobile}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Card sx={{ mb: 2, p: 4 }}>
              <Grid container spacing={1}>
                <Grid item xs={6} md={6}>
                  <Typography variant="subtitle2">OrderId :</Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography>{orderDetails?.orderId}</Typography>
                </Grid>

                <Grid item xs={6} md={6}>
                  <Typography variant="subtitle2">Order Date :</Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography>
                    {dateFormatter(orderDetails?.updatedAt)}
                  </Typography>
                </Grid>

                <Grid item xs={6} md={6}>
                  <Typography variant="subtitle2">Order Status :</Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography>{orderDetails?.status}</Typography>
                </Grid>
              </Grid>
            </Card>

            <Card sx={{ mb: 2, p: 4 }}>
              <Grid container spacing={1}>
                <Grid item xs={6} md={6}>
                  <Typography variant="subtitle2">Payment Status :</Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography>{orderDetails?.status}</Typography>
                </Grid>

                <Grid item xs={6} md={6}>
                  <Typography variant="subtitle2">Payment Method :</Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography>{orderDetails?.paymentGateway}</Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Card sx={{ p: 2 }}>
              <Box sx={{ height: 300, width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row._id}
                  rows={productDetails}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  // checkboxSelection
                  disableRowSelectionOnClick
                />
              </Box>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Card sx={{ mb: 2, p: 1, width: 400 }}>
              <Table>
                {/* <TableHd>
                  <TableRow>
                    <TableCell>Dessert (100g serving)</TableCell>
                    <TableCell align="left">Calories</TableCell>
                    <TableCell align="left">Fat&nbsp;(g)</TableCell>
                    <TableCell align="left">Carbs&nbsp;(g)</TableCell>
                    <TableCell align="left">Protein&nbsp;(g)</TableCell>
                  </TableRow>
                </TableHd> */}
                <TableBody>
                  <TableRow>
                    <TableCell align="left">Sub Total </TableCell>
                    <TableCell align="right">
                      {isNaN(orderDetails?.paymentDetails?.orderAmount)
                        ? "--"
                        : moneyFormatter(
                            orderDetails?.paymentDetails?.orderAmount
                          )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">CGST </TableCell>
                    <TableCell align="right">
                      {isNaN(orderDetails?.paymentDetails?.gst?.cgst)
                        ? "--"
                        : moneyFormatter(
                            orderDetails?.paymentDetails?.gst?.cgst
                          )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">IGST </TableCell>
                    <TableCell align="right">
                      {isNaN(orderDetails?.paymentDetails?.gst?.igst)
                        ? "--"
                        : moneyFormatter(
                            orderDetails?.paymentDetails?.gst?.igst
                          )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">SGST </TableCell>
                    <TableCell align="right">
                      {isNaN(orderDetails?.paymentDetails?.gst?.sgst)
                        ? "--"
                        : moneyFormatter(
                            orderDetails?.paymentDetails?.gst?.sgst
                          )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">Delivery Charges</TableCell>
                    <TableCell align="right">
                      {isNaN(orderDetails?.paymentDetails?.delivery)
                        ? "--"
                        : moneyFormatter(
                            orderDetails?.paymentDetails?.delivery
                          )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">
                      <b>Total Amount </b>
                    </TableCell>
                    <TableCell align="right">
                      <b>
                        {isNaN(orderDetails?.paymentDetails?.totalAmount)
                          ? "--"
                          : moneyFormatter(
                              orderDetails?.paymentDetails?.totalAmount
                            )}
                      </b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Card sx={{ mb: 2, p: 1, }}>
              <Review
                orderId={orderDetails?.orderId}
                productId={productIdAsNumber}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
      <AddReviewPopup open={open} setOpen={setOpen} handleClickOpen={handleClickOpen} handleClose={handleClose} title={title} productDetails={productDetails? productDetails : []}  orderId={orderDetails?.orderId} productId={productIdAsNumber}/>
    </Dashboard>
  );
}

export default OrderDetails;
