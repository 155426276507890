import React from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import viewsRoutes from './routes/routes';
import AuthGuard from 'components/AuthGuard';
import { GUARD } from 'config';


const Routes = () => {
  return (
    <ReactRoutes>
      {viewsRoutes.map((item, i) => {
        let routeElement;

        // <Route key={i} path={item.path} element={item.renderer()} />
        // console.log("Routes: ", item);
        switch (item?.guard) {
          case GUARD.AUTH:
            routeElement = <Route key={i} path={item.path} element={<AuthGuard component={item.renderer()} />} />
            break;

          default:
            routeElement = <Route key={i} path={item.path} element={item.renderer()} />
        }
        return routeElement;
      })}

      <Route path="*" element={<Navigate replace to="/not-found-cover" />} />
    </ReactRoutes>
  );
};

export default Routes;
