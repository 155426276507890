import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Typography,
  Grid,
  CheckBox,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card
} from "@mui/material";
import {onFacetSelection} from "../../../actions/searchAction";
import { useDispatch, useSelector} from "react-redux";
import RangeSlider from "./RangeSlider";

const useStyles = makeStyles((theme) => ({
  labelA: {
    fontSize: "8px",
    color: "grey",
    textTransform:"capitalize"
  },
}));

const listperRow = 4;
const SearchFilterCard = ({ title, facets, applyRangeFilter, priceRange, setPriceRange, minPrise, maxPrise }) => {
  const classes = useStyles();
  const filteredInitial = {};
  facets.map((item) => {
    var key = item.label;
    var object = {};
    object[key] = false;
    filteredInitial[key] = false;
  });
  const [filteredItem, setFilteredItem] = useState(filteredInitial);

  const isShowProductPrice = useSelector((state)=> state.app.showProductPrice);
  
  // console.log("filteredItem", filteredItem);
const dispatch = useDispatch()
  const handleFilterSelect = (event) => {
    setFilteredItem({
      ...filteredItem,
      [event.target.name]: event.target.checked,
    });

    dispatch(onFacetSelection(event.target.name));
    // onFacetSelection(title, event.target.name, event.target.checked);
  };

  const [next, setNext] = useState(false);
  const handleMoreList = () => {
    setNext(!next);
  };
  const getRenderedItems = () => {
    if (next) {
      return facets;
    }
    return facets.slice(0, listperRow);
  };

  const getValue = (inValue) => {
    switch (inValue) {
      case "true":
        return "Yes";
        break;
      case "false":
        return "No";

      case "PVT":
        return "PRIVATE LIMITED";

      case "PLC":
        return "PUBLIC LIMITED";

      default:
        return inValue;
    }
  };

  return (

    <Grid sx={{pl:2}}>


      <Grid sm={12} md={12} sx={{ mt: 2 }}>
        <Typography variant="searchFilterCardTitle" color="#000000">
        {isShowProductPrice &&
        <Box>
          <RangeSlider applyRangeFilter={applyRangeFilter}  priceRange={priceRange} setPriceRange={setPriceRange} minPrise={minPrise} maxPrise={maxPrise}/>
        </Box>
      }
        </Typography>
      </Grid>

      <Grid sm={12} md={12} sx={{ mt: 2 }}>
        <Typography variant="searchFilterCardTitle" color="#000000">
          {title}
        </Typography>
      </Grid>

      <Grid container sm={12} md={12} sx={{ mt: 1, ml: 2 }}>
        {getRenderedItems().map((item, index) => (
          <Grid item md={12} key={index}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                  size="small"
                    checked={filteredItem[item.label]}
                    onClick={handleFilterSelect}
                    name={item.label}
                  />
                }
                label={<Typography variant="searchFilterCardLabel"  sx={{ color:'#000000100', textTransform:"capitalize"}}>{getValue(item.label)}</Typography>}
                // label={getValue(item.label)}
                // classes={{ label: classes.labelA }}
                // sx={{fontSize:6, color:'grey', textTransform:"initial"}}
              />
            </FormGroup>
          </Grid>
        ))}
      </Grid>
      <Grid>
        {facets && (
          <Box
            onClick={handleMoreList}
            sx={{
              // borderBottom: "1px solid black",
              width: "81px",
              mb: 1,
              ml: 2,
            }}
          >
            <Typography sx={{ fontSize: "12px" }}>
              <b>{next ? "View less" : " View more"}</b>
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
   
  );
};

SearchFilterCard.propTypes = {
  title: PropTypes.string.isRequired,
  facets: PropTypes.array.isRequired,
  onFacetSelection: PropTypes.func,
};

export default SearchFilterCard;
