import React from "react";
import { Grid, Card, Typography, Button, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { moneyFormatter } from "../../utils/formatUtils";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const MainCart = ({ cart }) => {
  const cartItems = useSelector((state) => state?.cart?.items);
  const subtotal = useSelector((state) => state?.cart?.subTotal);
  const isShowProductPrice = useSelector((state) => state.app.showProductPrice);
  return (
    <Container maxWidth="xl">
      <Card sx={{ borderRadius: 2, p: 2, textAlign: "center", mt: 5 }}>
        <Grid container spacing={0}>
          {isShowProductPrice && (
            <Grid item xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell><b>Items</b></TableCell>
                      <TableCell align="right"><b>{cartItems.length}</b></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell><b>Total</b></TableCell>
                      <TableCell align="right"><b>{moneyFormatter(subtotal)}</b></TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>

              {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="CardTitle">
                  Total ({cartItems.length} Items)
                </Typography>
                <br />

                <Typography variant="CardTitle" sx={{ fontWeight: "bold" }}>
                  {moneyFormatter(subtotal)}
                </Typography>
              </Box> */}
              <Button
                fullWidth
                size="small"
                variant="contained"
                sx={{ mt: 3 }}
                component={Link}
                to="/checkout"
              >
                Proceed to Buy
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>
    </Container>
  );
};

export default MainCart;
