import React, { useEffect } from "react";
import { Card, Grid } from "@mui/material";
import { SearchCard } from ".";
// import { data } from "./data";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const SearchResults = ({ data, numFound }) => {
  const favourites = useSelector((state) => state.favorite?.favourites);
  const favouritesID = favourites.map((item) => item?.productId);

 
  const productId = data.map((item) => item?.productId);

  const isFavourites = (productId) => favouritesID.includes(productId);
  useEffect(() => {}, [data, favourites]);

  return (
    <Card sx={{ borderRadius: "10px" }} elevation={5}>
      <Grid container spacing={3}>
        {data.map((item, index) => (
    
          <Grid item xs={12} sm={4} md={4}>
            <SearchCard
              img={item?.baseImage}
              name={item?.productName}
              price={item?.price?.sellPrice}
              specialPrice={item.price?.specialPrice?.price}
              fromDate={item.price?.specialPrice?.from}
              tillDate={item.price?.specialPrice?.till}
              discount={item.price?.specialPrice?.discount}
              sku={item?.productSku}
              brand={item?.brandName}
              productId={item?.productId}
              description={item?.productName}
              availableStock={item?.availableStock}
              fav={isFavourites(item?.productId) ? true : false}
          
            />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

SearchResults.propTypes = {
  data: PropTypes.array.isRequired,
  numFound: PropTypes.number.isRequired,
};

export default SearchResults;
