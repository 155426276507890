/* eslint-disable no-param-reassign */
import produce from "immer";

import { CATEGORIES } from "config/content";

import {
  FETCH_CATEGORIES, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE,
  FETCH_RECENT_PRODUCTS, FETCH_RECENT_PRODUCTS_SUCCESS, FETCH_RECENT_PRODUCTS_FAILURE,
  GET_VARIATION_SUCCESS, GET_VARIATION_VAR_SUCCESS
} from "actions/catalogAction";

const initialState = {
  categories: [...CATEGORIES],
  latestProducts: [],
  variations:'',
  variationsVariable:'',
  bannerURLs:undefined
};

const catalogReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_CATEGORIES_SUCCESS: {
      const { categories } = action.payload;
      return produce(state, (draft) => {
        draft.categories = [...categories];
        draft.bannerURLs = action.payload.data
        
      })
    }
      break;

    case FETCH_RECENT_PRODUCTS_SUCCESS: {
      const { products } = action.payload;
      // console.log("catalogReducer() recent products", products);
      return produce(state, (draft) => {
        draft.latestProducts = [...products];
      })
    }
      break;
      
      case GET_VARIATION_SUCCESS: {
        const variationsData  = action.payload;
        console.log("variationsDat--", variationsData);
        return produce(state, (draft) => {
          draft.variations = variationsData;
          console.log("variationsData", variationsData)
        })
      }
        break;
        case GET_VARIATION_VAR_SUCCESS: {
          const variationsVariables  = action.payload;
          // console.log("variationsDat--", variationsVariables);
          return produce(state, (draft) => {
            draft.variationsVariable = variationsVariables;
            // console.log("variationsData", variationsVariables)
          })
        }
          break;

    default:
      {
        return state;
      }
      break;
  }
};

export default catalogReducer;
