import React, { useEffect } from "react";
import { paymentSuccessAction } from "../../../actions/paymentAction";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Card, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import { moneyFormatter } from "utils/formatUtils";

export default function PhonePeErrorPage()  {
  return (
    <div>
    <Container maxWidth="md" sx={{ mt: 3, mb: 3 }}>
        <Card sx={{ borderRadius: 5, width: "100%" }}>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "center", mt: 3 }}
          >
            <Card
              sx={{
                borderRadius: 3,
                width: "70%",
                mb: 3,
                p: 1,
                backgroundColor: "#f1faf5",
              }}
            >
              <Grid
                container
                spacing={1}
                sx={{ display: "flex", justifyContent: "center", p: 1 }}
              >
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <CancelIcon
                    sx={{
                      width: "100px",
                      height: "100px",
                      color: "red",
                      mb: 2,
                    }}
                  />
                </Grid>
                {/* <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  sx={{ display: "flex", justifyContent: "start", mt: 3 }}
                >
                  <Typography><b>Status: </b></Typography>
                  
                </Grid> */}
                
                {/* <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  sx={{ display: "flex", justifyContent: "start", mt: 3 }}
                >
                  
                  <Typography>Payment Id: {paymentId}</Typography>
                 
                </Grid> */}
                <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  sx={{ display: "flex", justifyContent: "start", mt: 3 }}
                >
                  
                  <Typography>Payment failed try after some time</Typography>
                        
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  sx={{ display: "flex", justifyContent: "start", mt: 3 }}
                >
                  <RouterLink to="/">
                    <Button variant="contained" >BACK</Button>
                  </RouterLink>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Card>
      </Container>
      </div>
  )
}
