import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import DeleteIcon from "@mui/icons-material/Delete";
import {Button} from "@mui/material";
const ImageUploader = ({setSelectedImages, selectedImages}) => {

  
  // Handle image drop
  const onDrop = (acceptedFiles) => {
    // Add the dropped images to the selectedImages state
    setSelectedImages(prevImages => [...prevImages, ...acceptedFiles]);
  };

  // Remove a selected image
  const removeImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

 
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop,
    multiple: true,
  });

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some images here, or click to select images</p>
      </div>
      <div style={selectedImagesContainerStyles}>
        {/* <h2>Selected Images:</h2> */}
        {selectedImages.map((image, index) => (
          <div key={index} style={selectedImageStyles}>
            <div>
            <img src={URL.createObjectURL(image)} alt={`selected-${index}`} style={imagePreviewStyles} />
            </div>
            <div style={btnDiv}>
            <Button onClick={() => removeImage(index)} ><DeleteIcon/></Button>
            </div>
          </div>
        ))}
      </div>
      {/* <button onClick={sendImagesToApi}>Upload Images</button> */}
    </div>
  );
};

// Styling for the component
const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};


const selectedImagesContainerStyles = {
  marginTop: '20px',
  display:'flex',
  alignItems:'top'
};

const selectedImageStyles = {
  display: 'flex',
  alignItems: 'top',
  marginBottom: '10px',
};

const imagePreviewStyles = {
  width: '100px',
  height: '200px',
  marginRight: '10px',
};

const btnDiv ={
  marginLeft :'-10px',
}
export default ImageUploader;
