import { GET_ALL_INVOICE_URL , GET_INVOICE_DETAILS } from "config/services";
import axios from "utils/axios";

class InvoiceService {

    getInvoice =()=>
    new Promise((resolve, reject)=>{
      axios
      .get(GET_ALL_INVOICE_URL)
      .then((response)=>{
        resolve(response?.data?.data);
        console.log("response get 1", response?.data?.data)
      }).catch((error) => {
        reject(error?.response?.payload);
      });
    })

    getInvoiceDetails =(invoiceId)=>
    new Promise((resolve, reject)=>{
      axios
      .get(`${GET_INVOICE_DETAILS}?invoiceId=${invoiceId}`)
      .then((response)=>{
        resolve(response?.data?.data?.result);
      }).catch((error) => {
        reject(error?.response?.payload);
      });
    })
}




const invoiceService = new InvoiceService();

export default invoiceService;
