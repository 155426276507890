import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Typography, Box, Divider, Checkbox,  } from "@mui/material";
import { Link } from "react-router-dom";
import { Grid, Button, Card } from "@mui/material";
import CartItems from "./CartItems";
import Container from "@mui/material/Container";
import { getCartAction } from "../../actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import { moneyFormatter } from "../../utils/formatUtils";
import { placeOrderAction } from "../../actions/subscribeAction";
import { Main as MainLayout } from "layouts";
import CartRight from "../Cart/CartRight";

const MainCart = () => {
  const dispatch = useDispatch();
  const isShowProductPrice = useSelector((state)=> state.app.showProductPrice);
  const cartItems = useSelector((state) => state?.cart?.items);

  const subtotal = useSelector((state) => state?.cart?.subTotal);
  useEffect(() => {
    try {
      dispatch(getCartAction());
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {}, [cartItems]);

  const centerContact = {
    id: 3,
    name: "SH Service Center",
    email: "center@gmail.com",
    city: "Bangalore",
    state: "Karnataka",
    region: "EAST",
  };
  const shipTo = {
    address: {
      name: "Manju P",
      addressLine1: "BTM layout",
      addressLine2: "madiwala",
      city: "Mysore",
      state: "Karnataka",
      country: "'India'",
      pinCode: "560-089",
      mobile: "1234567867",
      email: "shipTo@test.com",
    },
  };

  const billTo = {
    address: {
      name: "'Neeraj'",
      addressLine1: "'salt lake'",
      addressLine2: "Big bazar",
      city: "Kolkata",
      state: "Chandigarh",
      country: "India",
      pinCode: "560 079",
      mobile: "1231234543",
      email: "billTo@test.com",
    },
  };
  const orderDesc = "Pehala Order";

  async function subscribePlanHandl() {
    try {
      dispatch(placeOrderAction(centerContact, shipTo, billTo, orderDesc));
    } catch (error) {
      console.log("error", error.response.status);
    }
  }
  return (
    <MainLayout>
    <Container maxWidth="xl" sx={{backgroundColor:'#EAEAEA'}}>
      <Grid container sx={{mt:5}}>
        <Grid item xs={12} sm={12} md={9}>
          <Card sx={{ display: "flex", p: 4, mt:5 }} elevation={0}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  pr: 2,
                  pb:2,
                  borderBottom: "1px solid #D3D3D3",
                }}
              >
                <Box>
                  <Typography variant="h3">Shopping Cart</Typography>
                </Box>
                {/* <Box sx={{ display: "flex" }}>
            <Typography sx={{ mr: 0}}>Price</Typography>
          </Box> */}
              </Grid>

              <Grid item xs={12} sm={12} md={12} >
                {cartItems?.map((item, index) => (
                  <CartItems
                    item={item}
                    index={index}
                    getCartAction={getCartAction}
                    cartItems={cartItems}
                  />
                ))}
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{ justifyContent: "center", pr: 0, mb: 2 }}
              >
                {/* <Typography>
            Subtotal ({Cart?.length} items): <b>1,65,898.00000</b>
            Subtotal :
          </Typography> */}
                {/* <Button
            fullWidth
            component={Link}
            to="/cart"
            sx={{ mt: 5, borderRadius: "5px" }}
            variant="contained"
          >
            View Cart
          </Button> */}
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  pr: 0,
                  mb: 2,
                }}
              >
              {isShowProductPrice && 
                <Typography
                variant="CardTitle"
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <b>{moneyFormatter(subtotal)}</b>{" "}
                </Typography> }
                {/* <Button
          onClick={subscribePlanHandl}
            component={Link}
            to="/checkout"
            fullWidth
            sx={{ mt: 5, borderRadius: "5px" }}
            variant="contained"
          >
            Checkout
          </Button> */}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3} >
          <CartRight/>
        </Grid>
      </Grid>
      </Container>
    </MainLayout>
  );
};

export default MainCart;
