import { React } from "react";
import {
  CardMedia,
  Box, 
  Card,
} from "@mui/material";


const HeroSection = ({heroImage}) => {
  return (
    <Box>
      <Card sx={{ maxWidth: "100%", borderRadius: "0px" }}>
        <CardMedia
          component="image"         
          sx={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          image={heroImage || "assets/defaults/mperito_defualt_product.png"}
          alt={"hero image"}
        >          
        </CardMedia>
      </Card>
    </Box>
  );
};

export default HeroSection;
