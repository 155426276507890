import React from "react";
import { useDispatch , useSelector} from "react-redux";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";

import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";

import { signUpMobile } from "actions/authActions";

function SignUpMobileForm({ className, onSubmitSuccess, ...rest }) {
  const dispatch = useDispatch();

  const [policyChecked, setChecked] = React.useState(true);

  const handlePolicyCheck = (event) => {
    setChecked(event.target.checked);
  };
  const auth = useSelector((state) => state.auth);
  const mobile = auth.signInMobile;

  return (
    <Formik
      initialValues={{
        name: "",
        mobile: mobile,
        email: "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().min(2).max(80).required("Please enter valid name"),
        mobile: Yup.string()
          .matches(
            /^[6-9][0-9]{9}/,
            "Please enter valid 10 digit mobile number"
          )
          .min(10)
          .max(10)
          .required("Please Enter your mobile"),
        email: Yup.string()
          .email().required("Please enter valid email")
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(signUpMobile(values.name, values.mobile, values.email));
          onSubmitSuccess();
        } catch (error) {
          console.log("Form: error", error.response.data.message);

          const message = error.response.data.message || "Something went wrong";
          // console.log("message", message)
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Grid container spacing={4} maxWidth="sm">
            <Grid item xs={12}>
              <TextField
                name="name"
                id="name"
                label="Name (Full Name)"
                type="text"
                value={values.name}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ maxLength: 80 }}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="mobile"
                id="mobile"
                label="Mobile"
                value={values.mobile}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ maxLength: 10 }}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                id="email"
                label="Email"
                value={values.email}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ maxLength: 128 }}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              display={"flex"}
            >
              <Checkbox checked={policyChecked} onChange={handlePolicyCheck} inputProps={{ 'aria-label': 'controlled' }} />
              <Typography color="primary">
                I have read and accept the{" "}
                <b>Terms and Conditions and Privacy Policy</b>{" "}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ mt: 2 }}
            >
              <Button
                disabled={isSubmitting || !policyChecked}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ borderRadius: 30 }}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

SignUpMobileForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

SignUpMobileForm.default = {
  onSubmitSuccess: () => { },
};

export default SignUpMobileForm;
