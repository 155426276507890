import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Tab } from "@mui/material";
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import NewsCard from "./NewsCard";
import VideoCard from "../../VideoSection/components/VideoCard";

function NewsSlider({ newsList }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
  }, [newsList]);

  // console.log("NewsSlider: ", newsList);

  return (
    <Box >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={true}
        aria-label="news scrollable"
        allowScrollButtonsMobile={true}
        centered={true}
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 },
          },
        }}
      >
        {
          newsList?.map((item) =>
          
            <Tab label={
              
              (item.videoUrl ?
                <VideoCard video={item.videoUrl} title={item.title} /> :
                <NewsCard 
                  image={item.imageUrl}
                  title={item.title}
                  created={item.created}
                />
              )
            } />
          )
        }
      </Tabs>
    </Box>
  );
}

export default NewsSlider;
