import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
 
import {
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  Grid,
} from "@mui/material";
import { moneyFormatter } from "utils/formatUtils";
import { PAYMENT_GATEWAY } from "config";
import { GET_PRODUCT_DETAILS } from "config/services";
// import ReviewOrderCheckout from "./ReviewOrder/ReviewOrderCheckout.js"
import { Buffer } from 'buffer';
import CryptoJS from 'crypto-js';
 
import { placeOrder } from "actions/paymentAction";
import axios from "axios";
import { fetchProductDetails, fetchProductDetailsBySKU } from "actions/catalogAction";
import CCAvenueRedirectionPage from "views/Payment/CCAvenue/CCAvenueRedirectionPage";
//import PhonePe  from "views/PhonePe/PhonePe";
let redirectUrlPhonePe =  process.env.REACT_APP_PHONEPE_REDIRECT_URL || window.location.hostname;
let redirectUrlCCAvenue = "https://mperito.com/ccavenue<userId>" ;
 
// TODO_SP : Need to understand why this is needed and how it can be replaced properly


const signRequest = (payload) => {
  const shaVal = CryptoJS.SHA256(payload).toString(CryptoJS.enc.Hex);
  return shaVal.toLowerCase();
};
 
// Function to create the X-VERIFY header
    async function createXVerify(payload, secretKey) {
      
      const payloadString = JSON.stringify(payload);
      const hash = CryptoJS.SHA256(payloadString + secretKey).toString(CryptoJS.enc.Base64);
      //return hash.toLowerCase();
      console.log("hash", hash)
      return hash;
     
    };
 
  // Function to create payment URL (this should ideally be done on your server)
async function createPaymentUrlPhonePe (paymentServiceUrl, payload, secretKey) {
  console.log("paymentServiceUrl02", paymentServiceUrl)
  const encodeRequest = (payload) => {
    return Buffer.from(JSON.stringify(payload)).toString('base64');
  };

    const xVerify = await createXVerify(payload, secretKey);
 
    console.log("createPaymentUrlPhonePe: payload: ", JSON.stringify(payload));
    console.log("createPaymentUrlPhonePe: xVerify: ", xVerify+"###1");
 
    const response = await fetch(paymentServiceUrl +"/pg/v1/pay", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-VERIFY': xVerify+"###"+"1",
      },
      //body: JSON.stringify(payload),
      body: encodeRequest(payload),
    });
 
    const data = await response.json();
    return data.paymentUrl;
  };
 
 
 
   // Function to handle the payment response
  const handlePaymentResponse = async (query) => {
    // Example: Extract payment status from query params
    const paymentStatus = query.get('status');
    const paymentId = query.get('paymentId');
 
    // Here you can verify the payment status with your server if needed
 
    if (paymentStatus === 'success') {
      //setPaymentStatus('Payment successful!');
      console.log("Payment successful!");
      // You can redirect to a success page or display a success message
    } else {
      console.log("Payment failed. Please try again.");
      // setPaymentStatus('Payment failed. Please try again.');
      // You can redirect to a failure page or display a failure message
    }
  };
 
 
const centerContact = {
  id: 3,
  name: "SH Service Center",
  email: "center@gmail.com",
  city: "Bangalore",
  state: "Karnataka",
  region: "EAST",
};
 
function OrderConfirm({
  totalAmount,
  deliveryAddress,
  billingAddress,
  cart,
  cartItems,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isShowProductPrice = useSelector((state)=> state.app.showProductPrice);
  const orderDesc = "Web Order";
 
  const paymentGateway = useSelector((state) => state.payment?.paymentGateway);
  const paymentDetails = useSelector((state) => state.payment?.paymentDetails);
  console.log("1paymentDetails", paymentDetails.paymentGWPayKeySecret);
  let saltKey =  paymentDetails?.paymentGWPayKeySecret;
  let saltKeyIndex =  paymentDetails?.paymentGWPayKeyIndex;
  const [redirectData, setRedirectData] = useState(null);
 
  async function handleProceedToPayment() {
    console.log("OrderConfirm: paymentGateway=", paymentGateway);
    switch (paymentGateway) {
      case PAYMENT_GATEWAY.OFFLINE:
        {
          console.log("Payment Gateway: Offline");
          try {
            await dispatch(
              placeOrder(
                centerContact,
                deliveryAddress,
                billingAddress,
                orderDesc
              )
            );
            console.log("Order Placed Successfully");
            navigate("/offline-payment");
          } catch (error) {
            console.log("error", error.response.status);
          }
        }
        break;
      case PAYMENT_GATEWAY.RAZORPAY:
        {
          console.log("Payment Gateway: PayU");
        }
        break;
      case PAYMENT_GATEWAY.PAYU:
        {
          try {
            await dispatch(
              placeOrder(
                centerContact,
                deliveryAddress,
                billingAddress,
                orderDesc
              )
            );
            console.log("Order Placed Successfully");
            navigate("/pay-u");
          } catch (error) {
            console.log("error", error.response.status);
          }
          console.log("Payment Gateway: PayU");
        }
        break;
 
      case PAYMENT_GATEWAY.PHONEPE:
        {
         
      const order = await dispatch(placeOrder(
        centerContact,
        deliveryAddress,
        billingAddress,
        orderDesc,
        redirectUrlPhonePe
      ));
   


  const encodeRequest = (payload) => {
    return Buffer.from(JSON.stringify(payload)).toString('base64');
  };

  const generateXVerify = (encodedPayload, saltKey, saltIndex) => {
    const endpoint = '/pg/v1/pay';
    const concatenatedString = encodedPayload + endpoint + saltKey;
    const sha256Hash = CryptoJS.SHA256(concatenatedString).toString(CryptoJS.enc.Hex);
    return sha256Hash + '###' + saltIndex;
  };

  const processPayment = async () => {
    if (!order) {
      console.error("Order is not set");
      return;
    }

    const payload = {
      merchantId: paymentDetails?.paymentGWPayKeyId,
      merchantTransactionId: order?.order?.paymentOrderId,
      merchantUserId: order?.order?.paymentOrderRef?.userId,
      amount: order?.order?.paymentDetails?.totalAmount * 100,
      redirectUrl: redirectUrlPhonePe,
      redirectMode: "REDIRECT",
      callbackUrl: redirectUrlPhonePe,
      mobileNumber: order?.order?.mobile,
      paymentInstrument: {
        type: "PAY_PAGE"
      }
    };

   
  
    const encodedPayload = encodeRequest(payload);
    const saltKey = paymentDetails?.paymentGWPayKeySecret;
    const saltIndex = paymentDetails?.paymentGWPayKeyIndex;
    const xVerify = generateXVerify(encodedPayload, saltKey, saltIndex);


    const paymentServiceUrl = paymentDetails?.paymentServiceUrl // Ensure this URL is correct
    //console.log("paymentServiceUrl01", paymentServiceUrl)
    //const paymentServiceUrl = "https://api-preprod.phonepe.com/apis/pg-sandbox" // Ensure this URL is correct
    try {
      const response = await fetch(paymentServiceUrl+"/pg/v1/pay", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-VERIFY': xVerify
        },
        body: JSON.stringify({ request: encodedPayload })
      });

      //console.log("axios response", response )
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
     
      
      localStorage.setItem("merchantId", JSON.stringify(data.data.merchantId));
      localStorage.setItem("merchantTransactionId", JSON.stringify(data?.data?.merchantTransactionId))
      
      window.location.href = data?.data?.instrumentResponse?.redirectInfo?.url;
      // console.error('url0101', data);
    } catch (error) {
      navigate("/payment/phonepe/error")
      console.error('Error during payment processing:', error);
     
    }
  };
  processPayment()
 
};    
        
        break;
 
 
        // TODO: Payment Gateway for CCAvenue is pending to be implemented
        case PAYMENT_GATEWAY.CCAVENUE:
          {
            console.log("Payment Gateway: CCAvenue");
            try {
              const order = await dispatch(
                placeOrder(
                  centerContact,
                  deliveryAddress,
                  billingAddress,
                  orderDesc,
                  redirectUrlCCAvenue                  
                )
              );
 
              console.log("Order Placed Successfully, redirect to ccavenue-pay", order);
              navigate("/payment/ccavenue");
              
 
              // let paymentUrl = order?.order?.paymentOrderRef?.formbody;
              // console.log("Order Placed Successfully, redirect to ccavenue-pay URL", paymentUrl);
              // //window.location.href = paymentUrl;
 
              // const formBody = order?.order?.paymentOrderRef?.formbody;
              // const accessCode = order?.order?.paymentOrderRef?.accessCode;
              // const encodedRequest = order?.order?.paymentOrderRef?.encodedRequest;
 
              // console.log("Order CCAvenue: ", formBody, accessCode, encodedRequest);
              // setRedirectData({ formBody, accessCode, encodedRequest });
 
              // return <CCAvenueRedirectionPage formBody={formBody} accessCode={accessCode} encodedRequest={encodedRequest}/>
                
            } catch (error) {
              console.log("error", error);
            }
          }
 
 
        break;
 
      default:
        console.log("Payment Gateway is not set from server!!!");
        break;
    }
  }
 
 
 
  
  
  useEffect(() => {
    
  }, [paymentGateway]);
 
  // const orderFun=()=>{
  //   dispatch(
  //     placeOrder(
  //       centerContact,
  //       deliveryAddress,
  //       billingAddress,
  //       orderDesc,
  //       redirectUrlPhonePe
  //     )
  //   );
  // }
  const isReadyForPayment = useSelector(
    (state) => state.checkout?.isReadyForPayment
  );
 
 
  return (
    <Grid mt={2}>
      <Card sx={{ boxShadow: 4, borderRadius: 2, color: "#443A4F" }}>
        {/* <CardContent>
        <Typography fontWeight="bold" fontSize="16px" textAlign="start">
 
        </Typography>
      </CardContent>
      <Divider /> */}
 
        <CardContent>
         {isShowProductPrice &&
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              fullWidth
              disabled={!isReadyForPayment}
              size="large"
              type="submit"
              variant="contained"
              sx={{ borderRadius: 30 }}
              onClick={handleProceedToPayment}
            >
              Proceed to Payment
            </Button>
            {/* <Button onClick={orderFun}>order</Button> */}
            {/* <ReviewOrderCheckout/> */}
          </Grid> }
        </CardContent>
        {/* <PhonePe  centerContact={centerContact}
                deliveryAddress={deliveryAddress}
                billingAddress={billingAddress}
                orderDesc={orderDesc}
                paymentDetails={paymentDetails}
                /> */}
      </Card>
    </Grid>
  );
}
 
export default OrderConfirm;