import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Minimal from "layouts/Minimal";
import { Helmet } from "react-helmet";
import Logo from "components/Logo";

import { signOut } from "actions/authActions";

function SignOut() {
  const theme = useTheme();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignout = async () => {
    try {
      await dispatch(signOut());
      navigate("/");
    } catch (error) {

    }
  };

  const handleCancelSignout = () => {
    navigate(-1);
  };

  return (
    <Minimal>
      <Helmet>
        <title>Sign Out</title>
      </Helmet>

      <Grid
        container
        direction="row"
        mt={3}
      >
        <Grid item container xs={12} md={6} mt={4} >

        </Grid>
        <Grid item container xs={12} md={6} justifyContent="center"
          alignItems="center" alignContent="center">
          <Card sx={{ maxWidth: "sm", borderRadius: 15, boxShadow: 4 }}>
            <CardContent>
              <Grid item xs={12} mb={6}>
                <Logo width="120px" />
                <Typography variant="h5" color={"primary.main"} fontWeight="bold">
                  {" "}
                  Please confirm if you want to Sign Out?
                </Typography>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} md={6} >
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSignout}
                    sx={{ borderRadius: 30, spacing:2 }}
                  >
                    Confirm
                  </Button>


                </Grid>
                <Grid item xs={12} md={6} >
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    onClick={handleCancelSignout}
                    variant="contained"
                    sx={{ borderRadius: 30, spacing:2  }}
                  >
                    Cancel
                  </Button>
                </Grid>
                </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </Minimal>
  );
}

export default SignOut;
