
// export const HOME_CAROUSEL_ITEMS = [
//     {
//       label: "First Picture",
//       imgPath: "/assets/home/hero1.png",
//     },
//     {
//       label: "Second Picture",
//       imgPath: "/assets/home/hero2.png",
//     },
//     {
//       label: "Third Picture",
//       imgPath: "/assets/home/hero3.png",
//     },
//     {
//       label: "Third Picture",
//       imgPath: "/assets/home/hero4.png",
//     },
//     {
//       label: "Third Picture",
//       imgPath: "/assets/home/hero5.png",
//     },
//   ];

import { DEFAULT_IMAGES } from "config";

  

export const HOME_CAROUSEL_ITEMS = [
  {
    label: "Default Image",
    imgPath: DEFAULT_IMAGES.HERO,
  }
];


  export const CATEGORIES = [
    {
      name: "default",
      imageUrl: DEFAULT_IMAGES.CARD_ITEM
    },
    // {
    //   name: "electrical tools",
    //   imageUrl: "/assets/home/cookware.png",
    // },
    // {
    //   name: "cookware",
    //   imageUrl: "/assets/home/electricaltools.png",
    // },
    // {
    //   name: "personal",
    //   imageUrl: "/assets/home/personalcare.png",
    // },
    // {
    //   name: "furniture",
    //   imageUrl: "/assets/home/furniture.png",
    // },
    // {
    //   name: "personal",
    //   imageUrl: "/assets/home/personalcare.png",
    // },
    // {
    //   name: "electronics",
    //   imageUrl: "/assets/home/electricaltools.png",
    // },
    // {
    //   name: "lamps",
    //   imageUrl: "/assets/home/furniture.png",
    // },
  ];

  export const NEWS = [
    // {
    //   title: "DS Group forays into chocolate segment; acquires Luvit Chocolate & Confectionery",
    //   imageUrl: "/assets/home/news.png",
    //   created:'15 Minutes ago'
    // },
    // // {
    //   title: "DS Group forays into chocolate segment; acquires Luvit Chocolate & Confectionery",
    //   imageUrl: "/assets/home/news.png",
    //   created:'15 Minutes ago'
    // },
    // {
    //   title: "DS Group forays into chocolate segment; acquires Luvit Chocolate & Confectionery",
    //   imageUrl: "/assets/home/news.png",
    //   created:'15 Minutes ago'
    // },
    // {
    //   title: "DS Group forays into chocolate segment; acquires Luvit Chocolate & Confectionery",
    //   imageUrl: "/assets/home/news.png",
    //   created:'15 Minutes ago'
    // },
    // {
    //   title: "DS Group forays into chocolate segment; acquires Luvit Chocolate & Confectionery",
    //   imageUrl: "/assets/home/news.png",
    //   created:'15 Minutes ago'
    // },
  ];
  

  export const VIDEOS = [
    // {
    //     title: "1",
    //     videoPath: "/assets/home/video1.mp4",
    // },
    // {
    //     title: "2",
    //     videoPath: "/assets/home/video2.mp4",
    // },
    // {
    //     title: "3",
    //     videoPath: "/assets/home/video3.mp4",
    // },
    // {
    //     title: "4",
    //     videoPath: "/assets/home/video4.mp4",
    // },
];
