import React, { useEffect } from "react";
import { Dashboard as DashboardLayout } from "layouts";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuotationListAction , getQuoteSuccessFalseAction} from "../../actions/cartAction";
import Tooltip from "@mui/material/Tooltip";
import PreviewIcon from "@mui/icons-material/Preview";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Card, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { useNavigate,  } from "react-router";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const QuotationList = () => {
  const navigate = useNavigate();

  const quotationList = useSelector((state) => state?.cart?.quoteList?.data);
  console.log("quotationList", quotationList)
  const dispatch = useDispatch();
  const fetchQuotationList = () => {
    dispatch(fetchQuotationListAction());
  };

  const getQuoteSuccessFalse =()=>{
    dispatch(getQuoteSuccessFalseAction())
  }
  useEffect(() => {
    fetchQuotationList();
    getQuoteSuccessFalse();
  }, []);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const columns = [
    { field: "quotationId", headerName: "Quotation Id", width: 100 },
    {
      field: "businessName",
      valueGetter: ({ row }) => row.businessName,
      headerName: "Business Name",
      width: 120,
      editable: true,
    },
    { field: "customerId", headerName: "Customer Id", width: 100 },
    {
      field: "name",
      headerName: "Name",
      valueGetter: ({ row }) => row.name,
      // type: 'number',
      width: 120,
      editable: true,
    },
    // {
    //   field: "totalAmount",
    //   headerName: "Total Amount",
    //   valueGetter: ({ row }) => row.price?.sellPrice,
    //   // type: 'number',
    //   width: 100,
    //   editable: true,
    // },
    // {
    //   field: "totalAmount",
    //   headerName: "Amount",
    //   // valueGetter: ({ row }) => row.price?.sellPrice,
    //   valueGetter: ({ row }) => quotationList.map((list)=>(list.items.map((data)=>(data.price.sellPrice))))
    //   // valueGetter: ({ row }) => prisedata
    //     // quotationList.map((list) => {
    //     //   const firstItem = list.items[0];
    //     //   {console.log("firstItem.price.sellPrice", firstItem.price.sellPrice)}
    //     //   return firstItem ? firstItem.price.sellPrice : null;
        
    //     // })
    //   ,
    //   // type: 'number',
    //   width: 100,
    //   editable: true,
    // },
    
    
    {
      field: "requestedBy",
      headerName: "Requested By",
      //   type: "number",
      width: 150,
      editable: true,
      // valueGetter: (params) => dateFormatter(params.row.updatedAt),
    },
    // {
    //   field: "status",
    //   headerName: "status",
    //   //   type: "number",
    //   width: 100,
    //   editable: true,
    // },

    {
      field: "",
      disableClickEventBubbling: true,
      headerName: "Action",
      renderCell: (params) => {
        const onClick = () => {
          const quotationId = params.row.quotationId;
          //   dispatch(getInvoiceByIdAction(quotationId));
          //   dispatch(storeInvoiceId(invoiceId));
           navigate(`/quotation-pdf/${quotationId}`);
        };
        return (
          <Tooltip title="View Quotation details">
            <PreviewIcon onClick={onClick} />
          </Tooltip>
        );
      },
      width: 70,
      // onClick: (params) => {
      //   alert( params.row.updatedAt);
      // }
    },
    // {
    //   field: 'orderAmount',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  const showQuotation = useSelector((state)=> state.app.showQuotation);
  
  return (
    <DashboardLayout>
    
      <Container maxWidth="md" sx={{mt:10, mb:30, ml: isMobileView ? 0:10}}>
          <Grid items xs={12} sx={{ height: 600, width: 800 }}>
          <Typography variant="h3" sx={{mt:2, mb:2}}>Quotation List</Typography>
          {showQuotation &&
            <DataGrid
              getRowId={(row) => row.quotationId}
              rows={quotationList ? quotationList : []}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              // checkboxSelection
              disableRowSelectionOnClick
            />
          }
          </Grid>
       
      </Container>
    </DashboardLayout>
  );
};
export default QuotationList;
