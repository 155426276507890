import React , {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import FavoriteList from "./FavoriteList";
import { useSelector } from "react-redux";
import axios from "utils/axios";
import { GET_FAVORITE_URL } from "config/services";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useDispatch} from "react-redux"
import FavoriteIcon from "@mui/icons-material/Favorite";
import Badge from "@mui/material/Badge";
import {getFavorite} from "../../actions/favoriteAction"
export default function CartDrawer() {

  const favourites = useSelector((state) => state.favorite?.favourites);
  console.log("favorite22", favourites)
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.up('sm'));
  const [state, setState] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const dispatch = useDispatch()
  console.log("FavoriteList", favourites)
  useEffect(()=>{
    dispatch(getFavorite())  
  },[])

  useEffect(()=>{
      
  },[favourites])

  return (
    
<div>
  {["right"].map((anchor) => (
    <React.Fragment key={anchor}>
      <Badge
        badgeContent={favourites?.length}
        color="secondary"
        onClick={toggleDrawer(anchor, true)}
      >
        <FavoriteIcon />
      </Badge>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: (isMobileView ? 550 : 280),
            overflowY: "scroll",
          },
        }}
      >
        <FavoriteList favourites={favourites} closeDrawer={setState}/>
        
      </Drawer>
    </React.Fragment>
  ))}
</div>
  );
}
