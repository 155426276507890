import React, { useState } from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Container,
  CardMedia,
  IconButton,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { cartItemDeleteAction } from "actions/cartAction";
import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Add, Remove } from "@mui/icons-material";

import PropTypes from "prop-types";
import { IMAGE_BASE_URL } from "config";
import Price from "components/Price";

const CartItemCard = ({ itemDetails }) => {
  const [count, setCount] = useState(itemDetails?.quantity);
  const isShowProductPrice = useSelector((state)=> state.app.showProductPrice);
  const dispatch = useDispatch();
  const handleClickAdd = () => {
    setCount(count + 1);
  };


  const handleClickRemove = () => {
    setCount(count - 1);
  };

  let handleChange = (e) => {
    setCount(e.target.value);
  }

  const formik = useFormik({
    initialValues: {
      quantity: "",
    },
    validationSchema: Yup.object({
      quantity: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState(0);   //React.useState(productlist?.quantity);
  const handleClickOpen = (index) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //setValue(productlist?.quantity)
  };

  const cartItemDelete = (productId) => {
    try {
      dispatch(cartItemDeleteAction(productId));
    } catch {
      console.log("error");
    }
    handleClose();
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} key={itemDetails.index}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <Grid container spacing={4}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Card sx={{ boxShadow: 0, maxWidth: 200 }} raised>
                        <CardMedia
                          height="112"
                          component="img"
                          image={IMAGE_BASE_URL + "/" + itemDetails?.baseImageUrl}
                          // image={IPHONE}
                          title="green iguana"
                          sx={{
                            objectFit: "contain",
                          }}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <form onSubmit={formik.handleSubmit}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-around"
                          alignItems="center"
                        >
                          <Grid item xs={2}>
                            <IconButton
                              disabled
                              onClick={handleClickAdd}
                              size="small"
                              sx={{ boxShadow: 2 }}
                            >
                              <Add />
                            </IconButton>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              disabled
                              id="quantity"
                              name="quantity"
                              type="tel"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={count}
                              onChange={handleChange}
                              size="small"
                              variant="outlined"
                              inputProps={{
                                sx: { textAlign: "center" },
                              }}
                              contentEditable
                              sx={{
                                "& .MuiInput-input": {
                                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                  {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                  },
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              disabled
                              size="small"
                              onClick={handleClickRemove}
                              sx={{ boxShadow: 2 }}
                            >
                              <Remove />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={5} sx={{ color: "#443A4F" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography>
                        {itemDetails.productName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        SKU : {itemDetails?.productSKU}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    { isShowProductPrice && 
                    <Price
          price={itemDetails?.priceDetail?.sellPrice}
          specialPrice={itemDetails?.priceDetail?.specialPrice?.price}
          fromDate={ itemDetails?.priceDetail?.specialPrice?.from}
          tillDate={ itemDetails?.priceDetail?.specialPrice?.till}
          discount={itemDetails?.priceDetail?.specialPrice?.discount}
        />
        }
                      {/* <Typography
                        sx={{
                          display: "inline-block",
                          textDecorationLine: "line-through",
                        }}
                      >
                        {moneyFormatter(itemDetails?.price)}
                      </Typography> */}
                      {/* <Typography
                        ml={"4px"}
                        sx={{ display: "inline-block", color: "#006BEA" }}
                      >
                        {moneyFormatter(itemDetails?.priceDetail?.sellPrice)}
                      </Typography> */}

                      {/* <Typography
                        fontWeight="bold"
                        fontSize="16px"
                        ml={"4px"}
                        sx={{ display: "inline-block" }}
                      >
                        ₹{itemDetails.total}
                      </Typography> */}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Button variant="contained" onClick={() => handleClickOpen(itemDetails.index)}>Remove</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Want to Delete item from cart
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" onClick={handleClose}>
            No
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => cartItemDelete(itemDetails.productId)}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

CartItemCard.propTypes = {
  itemDetails: PropTypes.object,
};

export default CartItemCard;
