import { ADD_ADDRESS_URL, GET_ADDRESS_URL, EDIT_PROFILE_URL, DELETE_ADDRESS_URL } from "config/services";
import axios from "utils/axios";

class AddressService {

  addAddress = (address) =>
  new Promise((resolve, reject) => {
    axios
      .post(ADD_ADDRESS_URL, { address })
      .then((response) => {
        resolve(response?.data?.data?.addresses);
      })
      .catch((error) => {
        console.log(error);
        reject(error?.response?.payload);
      });
  });

  fetchMyAddresses = () =>
  new Promise((resolve, reject) => {
    axios
      .get(GET_ADDRESS_URL)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error?.response?.payload);
      });
  });


  getAddress= () =>
  new Promise((resolve, reject) => {
    axios
      .get(GET_ADDRESS_URL)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error?.response?.payload);
      });
  });

  editProfile = (values) =>
  new Promise((resolve, reject) => {
    const profileData = new FormData();
    profileData.append('profileData', JSON.stringify(values));
   
    axios
      .post(EDIT_PROFILE_URL, profileData)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error?.response?.payload);
      });
  });

  deleteAddress= (addressIndex) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${DELETE_ADDRESS_URL}/${addressIndex}`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      console.log("addressIndexaddressIndex", addressIndex)
      .catch((error) => {
        console.log(error);
        reject(error?.response?.payload);
      });
  });
}



const addressService = new AddressService();

export default addressService;
