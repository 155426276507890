class UserProfile {
    constructor(userInfo) {
      if (userInfo) {
        this.userId = userInfo.userId;
        this.name = userInfo.name;
        this.email = userInfo.email;
        this.mobile = userInfo.mobile;
        this.userType = userInfo.userType;
        this.userCategory = userInfo.userCategory;
        this.isActive = userInfo.isActive;   // TODO_SP : Check if needed
        this.isEmailValidated = userInfo.isEmailValidated;
        this.isMobileValidated = userInfo.isMobileValidated;
        this.customerId = userInfo.customerId;
        this.GSTIN = userInfo.GSTN;
        this.profileImage = userInfo.profileImage;
        this.lastLoggedIn = userInfo.lastLoggedIn;
      }
    }
  }

  export default { UserProfile };
  export { UserProfile }
