import React from "react";
import {
  CardMedia,
  Card,
  Grid,
  IconButton,
  Typography,
  Box,
} from "@mui/material";

import {
  Instagram as InstagramIcon,
  WhatsApp as WhatsAppIcon,
  Twitter as TwitterIcon,
  FacebookOutlined as FacebookOutlinedIcon,
} from "@mui/icons-material";
import { Logo } from "components";
import { useSelector } from "react-redux";

const KeepTouch = ({ socialIconList, logoUrl }) => {
  const appURL = useSelector((state) => state.app?.appURL);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ width: "250px", backgroundColor: "#dde7f5" }} elevation={0}>
          <CardMedia
            height="70"
            component="img"
            image={logoUrl}
            title="no image"
            sx={{
              objectFit: "contain",
            }}
          />
        </Card>
        {/* <Logo width="80%" logoUrl={logoUrl} /> */}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              color={"primary.main"}
              sx={{
                fontSize: "16x",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Stay connected over Social Channels
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: "20px",
              }}
            >
              {socialIconList?.map((item) => (
                <IconButton href={item.target} target="_blank">
                  {item.icon}
                </IconButton>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              color={"primary.main"}
              sx={{
                fontSize: "16x",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              EXPERIENCE IT ON MOBILE
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: "20px",
              }}
            >
              <Grid container justifyContent="center">
                {appURL?.playStoreUrl && appURL?.playStoreUrl.trim() !== "" ? (
                  <a
                    href={appURL?.playStoreUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      component="img"
                      image={"/assets/home/googleplay.png"}
                      sx={{ height: "42px", width: "142px" }}
                    />
                  </a>
                ) : (
                  <CardMedia
                    component="img"
                    image={"/assets/home/googleplay.png"}
                    sx={{ height: "42px", width: "142px" }}
                  />
                )}
              </Grid>
              <Grid container justifyContent="center">
                {appURL?.appStoreUrl && appURL?.appStoreUrl.trim() !== "" ? (
                  <a
                    href={appURL?.appStoreUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      component="img"
                      image={"/assets/home/appstore.png"}
                      sx={{ height: "42px", width: "142px" }}
                    />
                  </a>
                ) : (
                  <CardMedia
                    component="img"
                    image={"/assets/home/appstore.png"}
                    sx={{ height: "42px", width: "142px" }}
                  />
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KeepTouch;
