import React, { useEffect } from "react";
import { PDFViewer, Image } from "@react-pdf/renderer";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { Grid } from "@mui/material";
import { Dashboard as Dashboard } from "layouts";
import { dateFormatter } from "utils/formatUtils";
import { moneyFormatter } from "../../utils/formatUtils";
import { moneyFormatterPDF } from "../../utils/formatUtils";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getQuotationWithIdAction } from "../../actions/cartAction";
// Create styles
const styles = StyleSheet.create({
  page: {
    // fontFamily: "Cambria Regular",
    fontSize: 12,
    backgroundColor: "white",
    height: "500px",
    width: "800px",
  },
  thankU: {
    fontSize: 12,
    color: "#1078bd",
  },
  text: {
    marginTop: "3px",
  },
  section: {
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 5,
    borderBottom: "0.2px solid gray",
    backgroundColor: "white",
    paddingTop: "10px",
  },
  OrderIdSection: {
    padding: "10px",
    // height: "100px",
    width: "80%",
    height: "auto",
    flexGrow: 1,
    borderBottom: "0.2px solid gray",
    backgroundColor: "white",
    paddingTop: "5px",
    flexDirection: "end",
  },
  addressSection: {
    flexDirection: "row",
    marginTop: "20px",
    padding: "5px",
  },
  subTotalSection: {
    flexDirection: "row",
    justifyContent: "flex-end",
    // height: "100px",
    height: "auto",
    flexGrow: 1,
    border: "0.2px solid gray",
    backgroundColor: "white",
  },
  subTotalInnerSection: {
    padding: 15,
  },
  title: {
    fontSize: 11,
    fontWeight: 900,
  },
  itemListHead: {
    flexDirection: "row",
    backgroundColor: "#1078bd",
    color: "white",
  },
  itemList: {
    flexDirection: "row",
  },
  id: {
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
    width: "10%",
  },
  name: {
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
    width: "30%",
  },
  sku: {
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
    width: "30%",
  },
  prise: {
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
    width: "20%",
  },
  quantity: {
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
    width: "20%",
  },
  total: {
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
    width: "20%",
  },
  table: {
    
    backgroundColor: "white",
    marginTop: "20px",
  },
  thankyou: {
    flexDirection: "row",
    justifyContent: "center",
    borderTop: "1px solid gray",
    paddingTop: "5px",
    marginTop: "10px",
  },
  invoiceTitle: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#1078bd",
    padding: "5px",
  },
  invoiceT: {
    fontSize: "18px",
    color: "white",
    letterSpacing: "5px",
    marginTop: "10px",
  },
  image: {
    width: "50%",
  },
  imageView: {
    width: "50%",
    height: "20%",
    padding: "0px",
  },
  nameText: {
    marginTop: "10px",
  },
  addressBillShipTo: {
    flexDirection: "row",
  },
  bill: {
    width: "50%",
  },
  ship: {
    width: "50%",
  },

  title: {
    backgroundColor: "#1078bd",
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    letterSpacing: "5px",
    fontSize: "18px",
    textTransform: "uppercase",
    color: "white",
    flexDirection: "row",
  },
  main: {
    flexDirection: "row",
    marginTop: "10px",
  },
  main1: {
    width: "70%",
  },
  main2: {
    width: "30%",
  },
  businessView: {
    width: "100%",
    marginTop: "35px",
    borderBottom: "1px solid gray",
    paddingBottom: "10px",
  },
  mainPage: {
    padding: "10px",
  },
  subtotal:{
    flexDirection: "row",
    marginTop: "40px",
    width:'100%'
  },
  subtotal1:{
    width: "60%",
  }
  ,
  subtotal2:{
    width: "40%",
  },
  subTname:{
    width:'100%',
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
  }
});




const QuoteListPdf = () => {
  let params = useParams();
  const dispatch = useDispatch();

const getQuotationWithId = (quotationId) => {
  dispatch(getQuotationWithIdAction(quotationId));
};

  const app = useSelector((state) => state.app);
  const appLogo = app.logoUrl;
  const myImage = appLogo;

  useEffect(() => {
    getQuotationWithId(params.quotationId)
  }, []);

  const columns = [
    { field: "_id", headerName: "Order Id", width: 90 },
    {
      field: "productName",
      headerName: "Item",
      width: 150,
      editable: true,
    },
    {
      field: "productSKU",
      headerName: "HSN",
      width: 150,
      editable: true,
    },
    {
      field: "price",
      headerName: "price",
      width: 150,
      editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
      editable: true,
    },
    {
      field: "total",
      headerName: "Total",
      width: 150,
      editable: true,
    },
    
  ];

//   const invoiceDetails = useSelector((state) => state.invoice.invoicesList);
  const quoteByIdData = useSelector((state) => state?.cart?.quoteById);

  console.log("quoteById", quoteByIdData);
  const businessName = useSelector((state) => state.app);
  const businessAddress = useSelector((state) => state.cms);

  useEffect(() => {}, [quoteByIdData]);

  const logoImg = "/assets/home/Printer.png";
  const MyDocument = () => (
    <Document>
      {quoteByIdData?.map((items) => (
        <Page size="A4" style={styles.page}>
          <View style={styles.mainPage}>
            <View style={styles.title}>
              <Text style={styles.textTitle}>Quotation</Text>
            </View>

            <View style={styles.main}>
              <View style={styles.main1}>
                <Image src={myImage} style={styles.image} />
              </View>
              <View style={styles.main2}>
                <Text style={styles.text}>Quotation Id : {items?.quotationId}</Text>
                <Text style={styles.text}>
                Quotation Date : {dateFormatter(items?.createdAt)}
                </Text>
                {/* <Text style={styles.text}>
              Order State : {items?.status}
            </Text> */}

                {/* <Text style={{ marginTop: "15px" }}>
              Payment Status : {items?.status}
            </Text> */}
                <Text style={styles.text}>
                  Payment Method : {items?.paymentGateway}
                </Text>
              </View>
            </View>

            <View style={styles.businessView}>
              <Text style={{ fontWeight: "800px", fontSize: "12px" }}>
                {businessName?.appName},
              </Text>
              <Text style={styles.text}>
                {businessAddress?.contact?.registeredAddress},
              </Text>
              <Text style={styles.text}>
                {businessAddress?.contact?.mobile},
              </Text>
              <Text style={styles.text}>{businessName?.gstin}</Text>
            </View>

            <View style={styles.main}>
              <View style={styles.bill}>
                
                <Text
                  style={{
                    marginTop: "5px",
                    fontWeight: "800px",
                    fontSize: "12px",
                  }}
                >
                  Bill To :
                </Text>
                <Text  style={styles.text}>
                   {items?.billingAddress?.address?.name}
                </Text>
                <Text style={{ marginTop: "5px" }}>
                  {items?.billingAddress?.address?.addressLine1}
                </Text>

                <Text style={styles.text}>
                  {items?.billingAddress?.address?.addressLine2},{" "}
                  {items?.billingAddress?.address?.city},{" "}
                  {items?.billingAddress?.address?.country}
                </Text>

                <Text style={styles.text}>
                  {items?.billingAddress?.address?.pinCode}
                </Text>
                <Text style={styles.text}>{items?.billingAddress?.address?.email}</Text>
                <Text style={styles.text}>
                  {items?.billingAddress?.address?.mobile}
                </Text>
              </View>
              <View style={styles.ship}>
                <Text
                  style={{
                    marginTop: "5px",
                    fontWeight: "800px",
                    fontSize: "12px",
                  }}
                >
                  Ship To :
                </Text>
                <Text style={{ marginTop: "5px" }}>
                  {items?.shippingAddress?.address?.addressLine1}{" "}
                </Text>

                <Text style={styles.text}>
                  {items?.shippingAddress?.address?.addressLine2},{" "}
                  {items?.shippingAddress?.address?.city},{" "}
                  {items?.shippingAddress?.address?.country}
                </Text>

                <Text style={styles.text}>
                  {items?.shippingAddress?.address?.pinCode}{" "}
                </Text>
                <Text style={styles.text}>
                  {items?.shippingAddress?.address?.email}{" "}
                </Text>
                <Text style={styles.text}>
                  {items?.shippingAddress?.address?.mobile}
                </Text>
              </View>
            </View>

            <View style={styles.table}>
              <View style={styles.itemListHead}>
                <Text style={styles.id}>ID</Text>
                <Text style={styles.name}>PRODUCT</Text>
                {/* <Text style={styles.name}>HSN</Text> */}
                <Text style={styles.prise}>PRICE</Text>
                <Text style={styles.quantity}> QUANTITY</Text>
                <Text style={styles.quantity}> CGST</Text>
                <Text style={styles.quantity}> IGST</Text>
                <Text style={styles.quantity}> SGST</Text>
                <Text style={styles.total}>TOTAL</Text>
              </View>

              {items?.items?.map((dataList, index) => (
                <View style={styles.itemList}>
                  <View style={styles.id}>
                    <Text style={styles.text}>{index + 1}</Text>
                  </View>
                  <Text style={styles.name}>{dataList.productName}</Text>
                  {/* <Text style={styles.name}>{dataList.productSKU}</Text> */}
                  <Text style={styles.prise}>
                    Rs.{moneyFormatterPDF(dataList.price?.sellPrice)}
                  </Text>
                  <Text style={styles.quantity}>{dataList.quantity}</Text>


                  <Text style={styles.quantity}>{items?.gst?.cgst}</Text>
                  <Text style={styles.quantity}>{items?.gst?.igst}</Text>
                  <Text style={styles.quantity}>{items?.gst?.sgst}</Text>

                  <Text style={styles.total}>
                    Rs.{moneyFormatterPDF(dataList.price?.sellPrice)}
                  </Text>
                </View>
              ))}
            </View>


            {/* <View style={styles.thankyou}>
              <Text style={styles.thankU}>Thank you for your Order</Text>
            </View> */}
          </View>
        </Page>
      ))}
    </Document>
  );

  return (
    <Dashboard>
      <Grid container sx={{ display: "flex", justifyContent: "center" , mt:15, ml:10, mb:10}}>
        <PDFViewer style={styles.page}>
          <MyDocument />
        </PDFViewer>
      </Grid>
    </Dashboard>
  );
};

export default QuoteListPdf;
