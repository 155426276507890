import React from "react";
import { CardMedia, Grid, Typography, Box, Button } from "@mui/material";

const linksData = [
  {
    id: 1,
    name: "About Us",
    link: "/aboutus",
  },
];

const UsefullLink = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
         color={"primary.main"}  
        sx={{
            fontSize: "16x",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          Useful Links
        </Typography>
      </Grid>
      {linksData.map((item) => (
        <Grid item xs={12}>
          <Button variant="text" 
            href={item.link}
            sx={{
              textTransform:'capitalize',
              textAlign:'start',
              color:'#000000'}}>{item.name}</Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default UsefullLink;
