import React from "react";
import { Grid ,Card, CardMedia} from "@mui/material";
// import ReactImageZoom from "react-image-zoom";
import ReactImageMagnify from "react-image-magnify";
import { IMAGE_BASE_URL } from "config/index";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from "react-redux";
const ImageZoom = ({ product, img, sliderMaxWidth }) => {
 
  const ipadView = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const pinchZoom = useSelector((state)=> state.app.pinchZoom)
  // console.log("pinchZoom", pinchZoom);
  // console.log(" product.baseImage",  product.baseImage);
  // console.log("IMAGE_BASE_URL",  IMAGE_BASE_URL);
  
  const formatURL = (url) => {
    if (typeof url !== 'string' || !url.startsWith(IMAGE_BASE_URL)) {
      return `${IMAGE_BASE_URL}/${url}`;
    }
    return url;
  };
  
  const imageUrl = img == undefined ? "/assets/defaults/mperito_defualt_product.png" : formatURL(img);

  return (
    // <Grid container >

    //   <ReactImageMagnify
    //   style={{boxShadow:'2px 2px 15px #888888',
    //   borderRadius:'15px'}}
    //     {...{
          
    //       smallImage:{
    //         alt: "image not found",
    //         isFluidWidth: false,
    //         // src: img  == product.baseImage ?  img :  IMAGE_BASE_URL + "/" + img,
    //         //  src: img == "http://52.151.197.88" ?  '/assets/noImg.png' ,
    //         //src: img == "http://52.151.197.88/"  ?  '/assets/defaults/mperito_defualt_product.png' :  (img == product.baseImage ? img : IMAGE_BASE_URL + "/" + img),
    //         src: img == undefined ? "/assets/defaults/mperito_defualt_product.png" : formatURL(img),
    //         width: ipadView ? 350 : 300,
    //         height: ipadView ? 350 : 300,
            
    //       },
          
    //       largeImage: {
    //         src: img == undefined ? "/assets/defaults/mperito_defualt_product.png" : formatURL(img),
    //         // src: img == "http://52.151.197.88/"  ?  '/assets/defaults/mperito_defualt_product.png' :  (img == product.baseImage ? img : IMAGE_BASE_URL + "/" + img),
    //         //src: img  == product.baseImage ?  img :  IMAGE_BASE_URL + "/" + img,
    //         width: 500,
    //         height: 500,
    //       },
    //     }}
    //   />
    // </Grid>

     <Grid container>
      {pinchZoom === true ? (
        <ReactImageMagnify
          style={{ boxShadow: '2px 2px 15px #888888', borderRadius: '15px' }}
          {...{
            smallImage: {
              alt: "image not found",
              isFluidWidth: false,
              src: imageUrl,
              width: ipadView ? 350 : 300,
              height: ipadView ? 350 : 300,
            },
            largeImage: {
              src: imageUrl,
              width: 500,
              height: 500,
            },
          }}
        />
      ) : (
        <img
          style={{ boxShadow: '2px 2px 15px #888888', borderRadius: '15px' }}
          src={imageUrl}
          alt="image not found"
          width={ipadView ? 350 : 300}
          height={ipadView ? 350 : 300}
        />
      )}
    </Grid>
  );
};

export default ImageZoom;
