import React, { useState, useRef } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Grid } from "@mui/material";
import { IMAGE_BASE_URL } from "config/index";
import "./ImagesViewer.css";
import ImageZoom from "./ImageZoom";

const ImagesViewer = ({
  product,
  thambnailImages,
  imgStyles,
  sliderMaxWidth,
}) => {

   const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const thumbnailRef = useRef(null);
  const img = product.baseImage;
 

  
  const baseImg = [img];
  const thambnail = [...thambnailImages];
  const images = [...baseImg, ...thambnail];
  // console.log("product img viv",images);

  const formatURL = (url) => {
    if (typeof url !== 'string' || !url.startsWith(IMAGE_BASE_URL)) {
      return `${IMAGE_BASE_URL}/${url}`;
    }
    return url;
  };

  // const images = [
  //   img,
  //   tImg,
  //   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROz1TB8jFcd6Rn1epI05pdoeHkZ0IMm5xvMJF4f7Syv-cTMyqPUC3O5XQumYYk5j9xILs&usqp=CAU",
  //   "https://img.freepik.com/free-photo/phone-screen-with-abstract-marble-aesthetic_53876-145553.jpg",
  //   "https://images.unsplash.com/photo-1567581935884-3349723552ca?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bW9iaWxlfGVufDB8fDB8fHww&w=1000&q=80",
  //   "https://img.freepik.com/free-psd/smartphone-mockup_1310-812.jpg",
  //   "https://img.lovepik.com/free-png/20210928/lovepik-mobile-phone-png-image_401663651_wh1200.png",
  //   "https://img.freepik.com/free-photo/phone-screen-with-abstract-marble-aesthetic_53876-145553.jpg",
  // ];

 

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleScroll = (direction) => {
    const container = thumbnailRef.current;
    if (container) {
      const increment = direction === "left" ? -1 : 1;
      const newIndex = selectedImageIndex + increment;
      if (newIndex >= 0 && newIndex < images?.length) {
        setSelectedImageIndex(newIndex);
        container.scrollLeft += increment * 500; // Adjust scroll distance as needed
      }
    }
  };

  return (
    <Grid container>
      <div className="thumbnail-gallery">
        <div className="main-image">
          <ImageZoom
            product={product}
            // img={images[selectedImageIndex] || "/assets/noImg.png"}
            img={images[selectedImageIndex]}
          />
        </div>
        
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
        >
          <ArrowBackIosIcon
            sx={{ fontSize: "30px" }}
            onClick={() => handleScroll("left")}
          />
          <div className="thumbnails" ref={thumbnailRef} style={sliderMaxWidth}>
            {images?.map((image, index) => (
              <div
                key={index}
                className={`thumbnail ${
                  index === selectedImageIndex ? "active" : ""
                }`}
                onClick={() => handleThumbnailClick(index)}
                // onMouseEnter={() => handleThumbnailClick(index)} TO DO hover effect not working ========
              >
           

                <img
                src= {image == undefined ? '/assets/defaults/mperito_defualt_product.png' : formatURL(image) } 
                  //src = {image == "http://52.151.197.88/"  ?  '/assets/defaults/mperito_defualt_product.png' :  (image == product.baseImage ? image : IMAGE_BASE_URL + "/" + image)}
                  // src={image == product.baseImage ?  image :  IMAGE_BASE_URL + "/" + image}
                  alt={`Thumbnail ${index}`}
                  style={{
                    boxShadow: "2px 2px 10px #888888",
                    borderRadius: "5px",
                  }}
                />
               
                
              </div>
            ))}
          </div>

          <ArrowForwardIosIcon
            sx={{ fontSize: "30px", marginLeft: "10px" }}
            onClick={() => handleScroll("right")}
          />
        </div>
      </div>
    </Grid>
  );
};

export default ImagesViewer;
