import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function AuthGuard({ component }) {
  const auth = useSelector((state) => state.auth);

  if (!auth?.user) {
    return <Navigate to="/signin" />;
  }

  return component;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
