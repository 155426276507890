import React, { useEffect, useState } from "react";
import { Dashboard as DashboardLayout } from "layouts";
import Container from "@mui/material/Container";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Main as MainLayout } from "layouts";
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  FormHelperText,
  TextField,
  Grid,
  Button,
  CardHeader,
  Divider,
  CardContent,
  Typography,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import { Line } from "@react-pdf/renderer";

function OfflinePaymentStatus() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);
  console.log("userData", userData);

  return (
<MainLayout>
    <Container maxWidth="sm" sx={{ mt: 13, mb: 5 }}>
      <Grid item xs={12} md={12} xl={12}>
        <Card sx={{ width: 1 }}>
          <CardHeader
            title="OFFLINE PAYMENT INFORMATION"
            
          ></CardHeader>
          <Divider />
          <CardContent>
            <Typography sx={{textTransform:'uppercase', color:'green'}}> Please Make Sure Payment to UPI ID</Typography>

            <Typography sx={{mt:2}}>Share The Transaction Details On <b> WhatsApp : 953 564 4443</b></Typography>
            
            
            <Grid sx={{display:'flex', justifyContent:'space-evenly', mt:5}}>
              <Button component={RouterLink} to="/" variant="contained">BACK TO HOME</Button> <Button component={RouterLink} to="/my-orders"variant="contained">VIEW ORDERS</Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Container>
    </MainLayout>
  );
}

export default OfflinePaymentStatus;
