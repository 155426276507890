import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import { Button, Badge, IconButton, Alert } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSelector } from "react-redux";
import { moneyFormatter } from "utils/formatUtils";
import ImagesViewer from "./ImagesViewer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { addToCartAction } from "../../actions/cartAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Variations from "./Variations";

export default function BRANDProductBaseInfo({
  product,
  getProductDetails,
  sku,
  variationsList,
}) {
  const isShowProductPrice = useSelector((state) => state.app.showProductPrice);
  const isTaxInclusivePrice = useSelector(
    (state) => state.app.isTaxInclusivePrice
  );
  const brandName = useSelector((state) => state.app.brandName);
  const mobileView = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const account = useSelector((state) => state.auth);
  const isAuthorized = account.isAuthorized;
  const [isAdded, setIsAdded] = useState(false);
  // const [cartItems, setCartItems] = useState(products);
  const [cartCount, setCartCount] = useState(0);
  // console.log("cartItems",cartItems )
  const cartItems = useSelector((state) => state.cart.cartItems);
  const showAlert = useSelector((state) => state.cart.showAlert);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedProductVariantId, setSelectedProductVariantId] = useState("");
  //console.log("variationsList2", variationsList)
  // const  [thambnail, setthambnail] = useState([]);

  const navigate = useNavigate();

  const buy = (productId, quantity, description) => {
    try {
      if (isAuthorized == true) {
        dispatch(addToCartAction(productId, quantity, description));
        navigate("/checkout");
      } else {
        navigate("/signin");
      }
    } catch (error) {
      console.log(error);
    }
    navigate("/checkout");
    // dispatch(placeOrderAction(centerContact, shipTo, billTo, orderDesc));
  };

  // console.log("thambnail", thambnail)
  function closeSuccessAlert() {
    setIsAdded(false);
  }
  const handleAddToCart = () => {
    // dispatch(addToCart(item));
    if (showAlert == true) {
      setIsAdded(true);
    } else {
      setIsAdded(true);
    }
    setTimeout(closeSuccessAlert, 5000);
  };

  // const addToCart = (item) => {
  //   const index = cartItems.findIndex((i) => i.title === item.title);
  //   if (index === -1) {
  //     // setCartItems([...cartItems, item]);
  //   } else {
  //     const updatedItems = [...cartItems];
  //     updatedItems[index].quantity += item.quantity;
  //     // setCartItems(updatedItems);
  //   }
  // };
  const handlecounter = () => {
    setCartCount(cartCount + 1);
  };
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  let productId = product.productId;
  let description = product.name;
  const variations = useSelector((state) => state?.catalog);
  const [productVariantId, setProductVariantId] = useState();

  const addToCart = async (
    productId,
    quantity,
    description,
    productVariantId,
    variations
  ) => {
    try {
      await dispatch(
        addToCartAction(
          productId,
          quantity,
          description,
          productVariantId,
          variations
        )
      );
      handleAddToCart();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {}, [product, showAlert]);
  // console.log("product img", product.baseImage)

  const imgStyles = {
    width: "300px",
    height: "300px",
  };

  const sliderMaxWidth = {
    width: mobileView ? "250px" : "350px",
  };

  const price = product.price?.sellPrice;
  const fromDate = product?.price?.specialPrice?.from;
  const tillDate = product?.price?.specialPrice?.till;
  const specialPrice = product?.price?.specialPrice?.price;
  // specialPrice={productDetails.price?.specialPrice?.price}
  // fromDate={productDetails.price?.specialPrice?.from}
  // tillDate={productDetails.price?.specialPrice?.till}

  const specialPriceStartDate = new Date(fromDate);
  const specialPriceEndDate = new Date(tillDate);
  const newSpecialPrice = specialPrice;
  // const isConfigurable = variations?.variations?.productType;
  const isConfigurable = variations?.variations?.product?.productType;
  const today = new Date();
  const isSpecialPriceApplicable =
    today >= specialPriceStartDate && today <= specialPriceEndDate;

  const regularPrice = price;
  const addStyle = {
    display: "inline-block",
    textDecorationLine: "line-through",
    marginLeft: "5px",
  };

  const NoStyle = {
    marginLeft: "5px",
  };

  const myTimeout = setTimeout(closeAlert, 8000);

  function closeAlert() {
    setselectVarition(false);
  }
  const [selectVarition, setselectVarition] = useState(false);
  const checkAuth = () => {
    if (isAuthorized == true) {
      if (selectedSize === "") {
        setselectVarition(true);
        clearTimeout(myTimeout);
      } else if (selectedColor === "") {
        setselectVarition(true);
        clearTimeout(myTimeout);
      } else {
        addToCart(
          productId,
          quantity,
          description,
          productVariantId,
          variationsList
        );
      }
    } else {
      navigate("/signin");
    }
  };

  const checkAuth2 = () => {
    if (isAuthorized == true) {
      addToCart(
        productId,
        quantity,
        description,
        productVariantId,
        variationsList
      );
    } else {
      navigate("/signin");
    }
  };

  const showSKU = useSelector((state) => state.app.showSKU);
  const showBrand = useSelector((state) => state.app.showBrand);
  
  console.log("showBrand",showBrand)
  return (
    <>
      {isAdded && (
        <div>
          {cartItems?.map((item) => (
            <div key={item.name}>
              <p>
                {item.title} - {item.price} x {item.quantity}
              </p>
            </div>
          ))}
        </div>
      )}

      <Grid container spacing={2}>
        <Grid item md={5} sm={6} xs={12}>
          <ImagesViewer
            product={product}
            baseImages={product?.baseImage}
            thambnailImages={product?.images ? product?.images : []}
            imgStyles={imgStyles}
            sliderMaxWidth={sliderMaxWidth}
          />
          {/* <ButtonBase>
                {/* <img
                  style={{
                    margin: "auto",
                    display: "block",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  alt="complex"
                  src={product?.baseImage}
                /> */}

          {/* </ButtonBase> */}
        </Grid>

        <Grid item md={7} sm={6} xs={12} container sx={{ ml: 0 }}>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs m={2} p={2}>
              <Typography gutterBottom variant="subtitle1" component="div">
                <b>{product?.title}</b>
              </Typography>
              {showBrand && 
              <Typography variant="body2" gutterBottom>
                <b>BRAND:</b> {product?.brand}
              </Typography>
              }
              {showSKU && (
                <Typography variant="body2" gutterBottom>
                  <b>SKU :</b> {product?.sku}
                </Typography>
              )}
              {/* <Typography variant="body2" gutterBottom>
                <b style={{ color: "#7c2e3c" }}>
                  {moneyFormatter(product?.price)}
                  {price}
                </b>
              </Typography> */}
              {isShowProductPrice && (
                <Grid>
                  {isSpecialPriceApplicable ? (
                    <Typography sx={{ display: "flex" }}>
                      <Typography>{moneyFormatter(specialPrice)}</Typography>{" "}
                      <Typography
                        style={isSpecialPriceApplicable ? addStyle : NoStyle}
                      >
                        {moneyFormatter(regularPrice)}
                      </Typography>{" "}
                    </Typography>
                  ) : (
                    <Typography
                      style={isSpecialPriceApplicable ? addStyle : NoStyle}
                    >
                      {moneyFormatter(regularPrice)}
                    </Typography> /*Regular Price: $ */
                  )}

                  <Typography variant="body2" gutterBottom>
                    {isTaxInclusivePrice ? "Inclusive of all taxes" : ""}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          {/* <Grid item md={12} sm={12} sx={{ ml: 2, mt: -15 }}>
            <Variations
              productId={product?.productId}
              setSelectedSize={setSelectedSize}
              selectedSize={selectedSize}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              setSelectedType={setSelectedType}
              setProductVariantId={setProductVariantId}
              productVariantId={productVariantId}   
              getProductDetails={getProductDetails}  
              sku={sku}
            />
            {selectVarition ? (
              <Alert severity="error">Please Select Items</Alert>
            ) : (
              ""
            )}
          </Grid> */}

          {product.availableStock == 0 ? (
            <Grid item>
              <Typography variant="h5" sx={{ color: "red", ml: 3 }}>
                Out of Stock
              </Typography>
            </Grid>
          ) : (
            <Grid item>
              <Grid item>
                <Button
                  onClick={() => {
                    // addToCart(productId, quantity, description);
                    isConfigurable ? checkAuth() : checkAuth2();
                    // handleAddToCart();
                    handlecounter();
                  }}
                  sx={{
                    color: "white",
                    m: 2,
                  }}
                  variant="contained"
                >
                  Add To Cart
                </Button>

                <Button
                  onClick={() => buy(productId, quantity, description)}
                  sx={{
                    color: "white",
                  }}
                  variant="contained"
                >
                  Buy Now
                </Button>
                {isAdded && (
                  <Alert severity={showAlert ? "success" : "error"}>
                    {showAlert
                      ? "Product Added Successfully To Cart"
                      : "Something went wrong please try after some time"}
                  </Alert>
                )}
              </Grid>
            </Grid>
          )}

          <Grid item>
            {/* <Button variant="text" sx={{ backgroundColor: "#ffff" }}>
              <FavoriteBorderIcon />
            </Button> */}
          </Grid>
          <Grid item>
            {/* <IconButton aria-label="cart">
              <Badge badgeContent={cartCount} color="primary">
                <ShoppingCartIcon />
              </Badge>
            </IconButton> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
