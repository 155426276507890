import searchService from "services/searchService";

export const SEARCH = "@search/search";
export const SEARCH_SUCCESS = "@search/search-success";
export const SEARCH_FAILURE = "@search/search-failure";

export const FACET_SEARCH = "@facet-search/facet-search";
export const FACET_SEARCH_SUCCESS = "@facet-search/facet-search-success";
export const FACET_SEARCH_FAILURE = "@facet-search/facet-search-failure";
export const SET_PRICE_FAILURE = "@set-price/set-price-failure";
export const SET_PRICE = "@set-price/set-price";

export function searchAction(searchKeyword) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SEARCH,
        payload: {
          searchKeyword,
        },
      });
      const payload = await searchService.search(searchKeyword);

      console.log("searchAction() ...payload,", payload);
      dispatch({
        type: SEARCH_SUCCESS,
        payload: payload,
      });
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: SEARCH_FAILURE });
      throw error;
    }
  };
}

export function onFacetSelection(productName, from, to) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SEARCH,
        payload: {
          productName,
          price_range: {
            from,
            to,
          },
        },
      });
      const payload = await searchService.search(productName, from, to);

      console.log("searchAction() ...payload,", payload);
      dispatch({
        type: SEARCH_SUCCESS,
        payload: payload,
      });
    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: SEARCH_FAILURE });
      throw error;
    }
  };
}

export function setPriceInReducer(priceValue) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_PRICE,
        payload: priceValue,
      });
    } catch (error) {
      dispatch({ type: SET_PRICE_FAILURE });
    }
  };
}
