import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Minimal from "layouts/Minimal";
import { Helmet } from "react-helmet";
import Logo from "components/Logo";
import SignInMobileForm from "./SignInMobileForm";


function SignInMobile() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });

  const auth = useSelector((state) => state.auth);
  // const mobile = auth.signinMobile;

  // console.log("SignInEmail: auth", auth, " mobile: ", mobile)
  // useEffect(() => {}, [mobile]);

  const navigate = useNavigate();

  const handleSubmitSuccess = () => {
    navigate("/signin-verify-otp");
  };
 

  const removeUserExist=()=>{
    window.localStorage.removeItem('userExist');
  }
  return (
    <Minimal>
      <Helmet>
        <title>Sign In Mobile</title>
      </Helmet>

      <Grid
        container
        direction="row"
        mt={3}
      >
        <Grid item container xs={12} md={6} mt={4} >

        </Grid>
        <Grid item container xs={12} md={6} justifyContent="center"
          alignItems="center" alignContent="center">
          <Card sx={{ maxWidth: "sm", borderRadius: 15, boxShadow: 4 }}>
            <CardContent>
              <Grid item xs={12} mb={6}>
                <Logo width="120px" />
                <Typography variant="h5" color={"primary.main"} fontWeight="bold">
                  {" "}
                  Sign In
                </Typography>
              </Grid>

              <Grid item container xs={12} maxWidth="sm">

              </Grid>
              <Grid item xs={12}>
                <SignInMobileForm
                  onSubmitSuccess={handleSubmitSuccess}
                />
              </Grid>
              <Grid
                item
                xs={12}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ mt: 2 }}
              >
                <Typography color="primary" onClick={removeUserExist}>
                  Don't have an Account ?{" "}
                  <span style={{ color: "blue" }}>
                    <Link to={"/signup"}> Sign Up </Link>
                  </span>{" "}
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </Minimal>
  );
}

export default SignInMobile;
