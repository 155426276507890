import addressService from "services/addressService";
import { Address, ProfileEdit } from "interface/addressIf";

export const ADDRESS_ADD = "@address/address-add";
export const ADDRESS_ADD_SUCCESS = "@address/address-add-success";
export const ADDRESS_FAILURE = "@address/address-failure";


export const MY_ADDRESS_FETCH = "@address/address-fetch";
export const MY_ADDRESS_FETCH_SUCCESS = "@address/address-fetch-success";
export const MY_ADDRESS_FETCH_FAILURE = "@address/address-fetch-failure";

export const ADDRESS_SELECTED = "@address/address-selected";

export const GET_ADDRESS = "@address/get-address";
export const GET_ADDRESS_SUCCESS = "@address/get-address-success";
export const GET_ADDRESS_FAILURE = "@address/get-address-failure";

export const CHOOSE_ADDRESS = "@address/choose-address";
export const CHOOSE_ADDRESS_NEW = "@address/choose-address-new";

export const IS_ADDRESS_SELECT ="@address/select";

export const EDIT_PROFILE_FAILURE ="@edit/edit-profile-failure";
export const EDIT_PROFILE_SUCCESS ="@edit/edit-profile-success";
export const EDIT_PROFILE ="@edit/edit-profile";

export const DELETE_ADDRESS ="@edit/delete-address";
export const DELETE_ADDRESS_SUCCESS ="@edit/delete-address-success";
export const DELETE_ADDRESS_FAILURE ="@edit/delete-address-failure";




export function addAddress(addressType, address) {
  return async (dispatch) => {
    try {

      const newAddress = new Address(address);

      dispatch({
        type: ADDRESS_ADD,
        payload: {
          addressType: addressType,
          address: newAddress
        },
      });
      const addresses = await addressService.addAddress(newAddress);

      console.log("ADDRESS() ...payload,", addresses);
      dispatch({
        type: ADDRESS_ADD_SUCCESS,
        addressType: addressType,
        payload: addresses,
      });

    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: ADDRESS_FAILURE });
      throw error;
    }
  };
}


export function fetchMyAddresses() {
  return async (dispatch) => {
    try {
      dispatch({
        type: MY_ADDRESS_FETCH,
        payload: {},
      });

      const respData = await addressService.fetchMyAddresses();
      console.log("fetchMyAddresses() ...respData,", respData);
      let addresses = [];

      if (respData.length) {
        addresses = respData[0]?.addresses;

        dispatch({
          type: MY_ADDRESS_FETCH_SUCCESS,
          payload: {addresses},
        });

      }
      return addresses;


    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      console.log("fetchMyAddresses() :", error);
      dispatch({ type: MY_ADDRESS_FETCH_FAILURE });
      throw error;
      return null;
    }
  };
}

export function updateSelectedAddress(addressType, address) {
  return async (dispatch) => {
    console.log("updateSelectedAddress: ", addressType, address);
    dispatch({
      type: ADDRESS_SELECTED,
      payload: { addressType, address },
    });
  }
}

export function chooseAddress(addressType) {
  return async (dispatch) => {
    console.log("chooseAddress: ", addressType);
    dispatch({
      type: CHOOSE_ADDRESS,
      payload: { addressType },
    });
  }
}

export function chooseAddressNew(addressType) {
  return async (dispatch) => {
    console.log("chooseAddressNew: ", addressType);
    dispatch({
      type: CHOOSE_ADDRESS_NEW,
      payload: { addressType },
    });
  }
}


export function getAddressAction() {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_ADDRESS,
        payload: {
          GET_ADDRESS,
        },
      });
      const payload = await addressService.getAddress();
      //   console.log("ADDRESS() ...payload,", payload);
      dispatch({
        type: GET_ADDRESS_SUCCESS,
        payload: payload,
      });

    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: GET_ADDRESS_FAILURE });
      throw error;
    }
  };
}


export function setIsAddressSelectAction() {
  return async (dispatch) => {
    console.log("isAddressSelect reducer: ", );
    dispatch({
      type: IS_ADDRESS_SELECT,
      // payload: isAddressSelect ,
    });
  }
}


export function editProfileAction(values) {
  return async (dispatch) => {
    
    const profileData = new ProfileEdit(values);
    
    try {
      dispatch({
        type: EDIT_PROFILE,
        payload: {
          values
        },
      });
      const payload = await addressService.editProfile(profileData);
      dispatch({
        type: EDIT_PROFILE_SUCCESS,
        payload: payload,
      });

    } catch (err) {
      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: EDIT_PROFILE_FAILURE });
      throw error;
    }
  };
}


export function deleteAddressAction(addressIndex){
    return async (dispatch)=>{
      try{
        dispatch({
          type: DELETE_ADDRESS,
          payload: {
            DELETE_ADDRESS
          }});

          const payload = await addressService.deleteAddress(addressIndex);
          fetchMyAddresses()
          dispatch({
            type: DELETE_ADDRESS_SUCCESS,
            payload: payload,
          });
          

      }catch(err){
        let errMsg = err?.response?.data?.message;
        const error = { message: errMsg };

      dispatch({ type: DELETE_ADDRESS_FAILURE });
      throw error;
      }
    }
}