import React from 'react';

export const dateFormatter = dbFormatDate => {
    let formatedDate = dbFormatDate;
    const options = {
      // weekday: 'short',
      year: '2-digit',
      month: 'short',
      day: '2-digit',
      // hour: 'numeric',
      // minute: 'numeric',
      // timeZone: 'Asia/Kolkata',
    };
    if (typeof dbFormatDate === 'string') {
      formatedDate = new Date(dbFormatDate).toLocaleString('en-IN', options);
    }
  
    return formatedDate;
  };
  
  
  export const moneyFormatter = amount => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
    });
  
    return formatter.format(amount);
  };

  export const moneyFormatterPDF = (amount) => {
    const formatter = new Intl.NumberFormat("en-IN", {
      // style: 'currency',
      currency: "INR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
   
    return formatter.format(amount);
  };

  export function generateAvatarName(name) {
    const nameArray = name.split(' '); // Split the name into an array of words
    let initials = '';
  
    // Extract the first character of each word as initials
    nameArray.forEach((word) => {
      if (word) {
        initials += word[0].toUpperCase(); // Append the first character (capitalized)
      }
    });
  
    return initials;
  }