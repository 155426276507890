import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./TestimonialSlider.css"; // Add your styles here
import Container from "@mui/material/Container";
import {useDispatch, useSelector} from "react-redux";
import {getTestimonialsAction} from "../../actions/testimonials";
import {Box} from "@mui/material";
import { IMAGE_BASE_URL } from "config/index";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Grid
} from "@mui/material";
const testimonials = [
  {
    id: 1,
    name: "Jai Manhotra",
    position: "CEO, Company Inc.",
    content: "I would highly recommend Mindsclik Digital Solution to anyone looking for a reliable and innovative web application solution. It has truly transformed the way I work, and I look forward to continued success with this outstanding tool.",
    image:
      "https://www.freecodecamp.org/news/content/images/2021/08/imgTag.png",
  },
  {
    id: 1,
    name: "Raj Tiwari",
    position: "CEO, Company Inc.",
    content: "I am incredibly impressed with the web application developed by Mindsclik Digital Solution. From the moment I started using it, I could feel the dedication to user experience and the attention to detail that went into its design. The interface is intuitive, making navigation seamless and enjoyable.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStk3PKGsyvW3sV1EoPJvW301S5b9F1HuhYXw&usqp=CAU",
  },
  {
    id: 1,
    name: "vishal Chopda",
    position: "CEO, Company Inc.",
    content: "I would highly recommend Mindsclik Digital Solution to anyone looking for a reliable and innovative web application solution. It has truly transformed the way I work, and I look forward to continued success with this outstanding tool.",
    image:
      "https://www.freecodecamp.org/news/content/images/2021/08/imgTag.png",
  },
  {
    id: 1,
    name: "Aadil Roy",
    position: "CEO, Company Inc.",
    content: "The support from the Mindsclik Digital Solution team has been outstanding. They are responsive, knowledgeable, and go above and beyond to ensure a positive experience. It's clear that customer satisfaction is a top priority for them.",
    image:
      "https://www.freecodecamp.org/news/content/images/2021/08/imgTag.png",
  },
  {
    id: 1,
    name: "Sunil sharma",
    position: "CEO, Company Inc.",
    content: "What sets Mindsclik Digital Solution apart is not just the functionality, but the speed and efficiency it brings to my workflow. Tasks that used to take considerable time are now completed with ease, thanks to the well-thought-out features and smooth performance",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStk3PKGsyvW3sV1EoPJvW301S5b9F1HuhYXw&usqp=CAU",
  },
  {
    id: 1,
    name: "Hritik Roy",
    position: "CEO, Company Inc.",
    content: "The support from the Mindsclik Digital Solution team has been outstanding. They are responsive, knowledgeable, and go above and beyond to ensure a positive experience. It's clear that customer satisfaction is a top priority for them.",
    image:
      "https://www.freecodecamp.org/news/content/images/2021/08/imgTag.png",
  },
  // Add more testimonials as needed
];


const TestimonialSlider = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const list =  useSelector((state)=> state.testimonial?.testimonialsList?.testimonials?.testimonies)
  console.log("list1", list)
  const settings = {
    dots: isMobileView ? false : true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 375, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 414, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 390, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      
      {
        breakpoint: 540, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 280, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1200, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

const dispatch = useDispatch()

const getTestimonials=()=>{
  dispatch(getTestimonialsAction())
}

useEffect(()=>{
  getTestimonials()
},[])

  return (
    <>
    {list?.length > 0 ?
    <Container maxWidth="xl" className="mainContainer" sx={{backgroundColor: '#f2f3f5', pt:4, pb:4, pr:2, pl:2}} >
     
     <Grid item xs={12} sm={12} md={12} sx={{display:'flex', justifyContent:'center', color:'white', mb:3}}>
        {/* <Typography variant="h3" color="primary.main"></Typography> */}
        <Typography variant="h3" color="#323334" sx={{fontWeight: 600}}>Testimonials</Typography>
      </Grid>
     
        <Grid container>
          <Grid item xs={12} sm={12} md={12} sx={{p:2}}>
          <Slider {...settings}>
            {list?.map((testimonial) => (
              <div key={testimonial.id}>
              
                <Card sx={{ maxWidth: 280, height: isMobileView ? 350 : 400 , P:3, ml:2, mr:2}} elevation={8}>
                  <CardContent sx={{p:5}}>
                    <Box sx={{height:220}}>
                    <Typography variant="body2"  sx={{textAlign:'justify'}}>
                      {testimonial.description}
                    </Typography>
                    </Box>
                    <Box sx={{display:'flex', justifyContent:"center"}}> 
                    {!testimonial.image == "" ? <img src={ IMAGE_BASE_URL + "/" + testimonial.image} alt="no-img" className="testimonialImg"/> : <Box sx={{height:'70px'}}/>}
                      
                     
                    </Box>
                    <Box sx={{display:'flex', justifyContent:"center"}}> 
                     
                      <Typography gutterBottom variant="h5" component="div" sx={{mt:2}} >
                      - {testimonial.userName}
                    </Typography>
                    </Box>
                  </CardContent>
                  
                </Card>
              
              </div>
            ))}
          </Slider>
          </Grid>
        </Grid>
          
    </Container> : <Box></Box>
    }
    </>
  );
};

export default TestimonialSlider;
