import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardContent, Divider, Typography, Grid, CardHeader } from "@mui/material";
import AddressInfo from "./AddressInfo";
import AddressLine from "./AddressLine";
import AddressForm from "./AddressForm";
import AddressSelect from "./AddressSelect";
import { ADDRESS_STATUS } from "config";
import { chooseAddress } from "actions/addressAction";


function AddressSection({ addressType, title, addresses, address, addressState }) {
  const dispatch = useDispatch();

  console.log("AddressSection ", addressType, title, addresses, address, addressState);

  const handleSelectAddress = async () => {
    await dispatch(chooseAddress(addressType));   
  }

  useEffect(() => { }, [addressType, title, addresses, address, addressState]);

  return (
    <Card sx={{ boxShadow: 4, borderRadius: 2, color: "#443A4F", p:2 }}>

      <CardContent>
        <Typography fontWeight="bold" fontSize="16px" textAlign="start">
          {title}
        </Typography>
      </CardContent>
      <Divider sx={{mb:2}}/>
      <CardContent>
        <Grid container spacing={2}>
          {(addressState === ADDRESS_STATUS.INFO) && <AddressLine address={address} selectAddress={handleSelectAddress}/>}
          {(addressState === ADDRESS_STATUS.NEW) && <AddressForm addressType={addressType}/>}          
          {(addressState === ADDRESS_STATUS.SELECT) && <AddressSelect addressType={addressType} addresses={addresses} />}
        </Grid>

        {(addressState === ADDRESS_STATUS.INFO) && (
          <Grid mt={1}>
            <Button variant="contained" onClick={handleSelectAddress}>Change Address</Button>
          </Grid>
        )}
      </CardContent>

      <Divider />
    </Card>
  );
};

export default AddressSection;
