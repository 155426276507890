import favouriteService from "services/FavouriteService";

export const ADD_FAVORITE = "@favorite/add-favorite";
export const ADD_FAVORITE_SUCCESS = "@favorite/add-favorite-success";
export const ADD_FAVORITE_FAILURE = "@favorite/add-favorite-failure";

export const REMOVE_FAVORITE = "@favorite/remove-favorite";
export const REMOVE_FAVORITE_SUCCESS = "@favorite/remove-favorite-success";
export const REMOVE_FAVORITE_FAILURE = "@favorite/remove-favorite-failure";

export const GET_FAVORITE = "@favorite/get-favorite";
export const GET_FAVORITE_SUCCESS = "@favorite/get-favorite-success";
export const GET_FAVORITE_FAILURE = "@favorite/get-favorite-failure";



export function addToFavorite(productId, description) {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_FAVORITE });

      // Fetch Data from Server without sign in (eg master data)
      const payload = await favouriteService.addFavorite(productId, description);

      dispatch({
          type: ADD_FAVORITE_SUCCESS, payload
      });

  } catch (error) {
      dispatch({
          type: ADD_FAVORITE_FAILURE
      });
      console.log("Initialize Error:", error);
  }
  };
}


export function removeToFavorite(sku) {
    return async (dispatch) => {

      try {
        dispatch({ type: REMOVE_FAVORITE });
        const payload = await favouriteService.removeToFavorite(sku);
        dispatch({
            type: REMOVE_FAVORITE_SUCCESS, payload
        });
        
    } catch (error) {
        dispatch({
            type: REMOVE_FAVORITE_FAILURE
        });
        console.log("Initialize Error:", error);
    }
    };
  }


  export function getFavorite() {
    return async (dispatch) => {
      try {
        dispatch({ type: GET_FAVORITE });
  
        // Fetch Data from Server without sign in (eg master data)
        const payload = await favouriteService.getFavorite();
  
        dispatch({
            type: GET_FAVORITE_SUCCESS, payload
        });
        
    } catch (error) {
        dispatch({
            type: GET_FAVORITE_FAILURE
        });
        console.log("Initialize Error:", error);
    }
    };
  }
  