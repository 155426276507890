import React from "react";
import { Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import OrderSummary from "./OrderSummary";
import ReviewOrderCheckout from "./ReviewOrderCheckout";
import { Main as MainLayout } from "layouts";

const ReviewOrder = () => {
  return (
    <MainLayout>
      {/* <Container maxWidth="lg"> */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
          <OrderSummary />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            
          <ReviewOrderCheckout />
          </Grid>
        </Grid>
      {/* </Container> */}
    </MainLayout>
  );
};

export default ReviewOrder;
