import React from "react";
import { Helmet } from "react-helmet";

import { Dashboard as DashboardLayout } from "layouts";

import { Grid, Typography } from "@mui/material";


function Dashboard() {
    return (
        <DashboardLayout>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Grid sx={{ height: "96vh" }}>
                <Typography>
                    Welcome to Dashboard
                </Typography>

            </Grid>

        </DashboardLayout>
    );
}

export default Dashboard;