import React, { useState , useEffect} from 'react';
import { Button, Grid } from '@mui/material';

import AddressInfo from 'views/Checkout/components/AddressInfo';


function AddressList({ addressType, addresses, handleNewAddress }) {

    useEffect(()=>{

    },[addresses])
    return (

        <Grid item container spacing={3}>
            {addresses?.map((address, index) => (
                <Grid item xs={12} sm={4} md={4} >
                    <AddressInfo address={address} addressIndex={address?.addressIndex}/>
                </Grid>
            ))}
            <Grid item xs={12} sm={4} md={4} >
                <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ borderRadius: 30 }}
                    onClick={handleNewAddress}
                >
                    Add New Address
                </Button>
            </Grid>
        </Grid>
    );
};

export default AddressList;
