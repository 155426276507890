import AuthGuard from "components/AuthGuard";
import { GUARD } from "config";
import React from "react";

import {
  Home as HomeView,
  SignUpMobile as SignUpMobileView,
  SignUpMobileOTPVerify as SignUpMobileOTPVerifyView,
  SignInMobile as SignInMobileView,
  SignInMobileOTPVerify as SignInMobileOTPVerifyView,
  SignOut as SignOutView,
  PageNotFound as PageNotFoundPage,
  Dashboard as DashboardPage,
  PDP as PDPPage,
  CLP,
  ThemePage,
  Search as SearchPage,
  CartPage,
  Checkout,
  ReviewOrder,
  PaymentSuccess,
  MyOrders,
  OrderDetails,
  PayU,
  PrivacyPolicy,
  TermsAndConditions,
  AboutUs,
  ViewCart,
  PhonePePaymentStatus,
  Profile,
  OfflinePaymentStatus,
  Invoice,
  InvoiceList,
  InvoiceDetails,
  OrderPDF,
  Favourites,
  GetQuote,
  QuotationList,
  QuotationPdf,
  GooglePay,
  RefundPolicy,
  ReturnPolicy,
  ShippingPolicy,
  // PhonePe,
  PhonePeErrorPage
} from "views";
import PaymentStatus from "views/Payment/PhonePe/PaymentStatus";

import PayCCAvenue from "views/Payment/CCAvenue/PaymentForm";
import CCAvenueRedirectionPage from "views/Payment/CCAvenue/CCAvenueRedirectionPage";


const routes = [
  {
    path: "/",
    renderer: (params = {}) => <HomeView {...params} />,
  },
  {
    path: "/signup",
    renderer: (params = {}) => <SignUpMobileView {...params} />,
  },
  {
    path: "/signup-mobile-otp-verify",
    renderer: (params = {}) => <SignUpMobileOTPVerifyView {...params} />,
  },
  {
    path: "/signin",
    renderer: (params = {}) => <SignInMobileView {...params} />,
  },
  {
    path: "/signout",
    renderer: (params = {}) => <SignOutView {...params} />,
  },
  {
    path: "/signin-verify-otp",
    renderer: (params = {}) => <SignInMobileOTPVerifyView {...params} />,
  },
  {
    path: "/dashboard",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <DashboardPage {...params} />,
  },

  {
    path: "/not-found-cover",
    renderer: (params = {}) => <PageNotFoundPage {...params} />,
  },
  {
    path: "/theme",
    renderer: (params = {}) => <ThemePage {...params} />,
  },
  {
    path: "/product/:sku",
    renderer: (params = {}) => <PDPPage {...params} />,
  },
  {
    path: "/category/:categoryId/:categoryName",
    renderer: (params = {}) => <CLP {...params} />,
  },
  {
    path: "/search-results",
    renderer: (params = {}) => <SearchPage {...params} />,
  },

  {
    path: "/cart",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <CartPage {...params} />,
  },

  {
    path: "/checkout",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <Checkout {...params} />,
  },
  {
    path: "/review-order",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <ReviewOrder {...params} />,
  },

  {
    path: "/payment-success",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <PaymentSuccess {...params} />,
  },
  
  {
    path: "/my-orders",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <MyOrders {...params} />,
  },

  {
    path: "/order-details/:orderId",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <OrderDetails {...params} />,
  },
  {
    path: "/pay-u",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <PayU {...params} />,
  },
  {
    path: "/payment/ccavenue",
    guard: GUARD.AUTH,
    // renderer: (params = {}) => <PayCCAvenue {...params} />,
    renderer: (params = {}) => <CCAvenueRedirectionPage {...params} />,
  },
  // {
  //   path: "/phonePe",
  //   // guard: GUARD.AUTH,
  //   renderer: (params = {}) => <PhonePe {...params} />,
  // },
  {
    path: "/payment/phonepe/error",
    // guard: GUARD.AUTH,
    renderer: (params = {}) => <PhonePeErrorPage {...params} />,
  },
  
  {
    path: "/payment/phonepe/status",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <PhonePePaymentStatus {...params} />,
  },
  {
    path: "/privacy-policy",
    renderer: (params = {}) => <PrivacyPolicy {...params} />
  },

  {
    path: "/refund-policy",
    renderer: (params = {}) => <RefundPolicy {...params} />
  },

  {
    path: "/return-policy",
    renderer: (params = {}) => <ReturnPolicy {...params} />
  },

  {
    path: "/shipping-policy",
    renderer: (params = {}) => <ShippingPolicy {...params} />
  },


  {
    path: "/terms-and-conditions",
    renderer: (params = {}) => <TermsAndConditions {...params} />
  },
  {
    path: "/aboutus",
    renderer: (params = {}) => <AboutUs {...params} />
  },
  {
    path: "/view-cart",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <ViewCart {...params} />
  },
  {
    path: "/profile",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <Profile {...params} />
  },
  {
    path: "/offline-payment",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <OfflinePaymentStatus {...params} />
  },
  {
    path: "/invoice-pdf",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <Invoice {...params} />
  },
  {
    path: "/invoice-list",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <InvoiceList {...params} />
  },
  {
    path: "/invoice-details",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <InvoiceDetails {...params} />
  },
  {
    path: "/order-pdf/:orderId",
    guard: GUARD.AUTH,
    renderer: (params = {}) => <OrderPDF {...params} />
  },
  {
    path: "/favourite",
    renderer: (params = {}) => <Favourites {...params} />
  },
  {
    path: "/get-quote",
    renderer: (params = {}) => <GetQuote {...params} />
  },
  {
    path: "/quotation-list",
    renderer: (params = {}) => <QuotationList {...params} />
  },
  {
    path: "/quotation-pdf/:quotationId",
    renderer: (params = {}) => <QuotationPdf {...params} />
  },
  {
    path: "/google-pay",
    renderer: (params = {}) => <GooglePay {...params} />
  },
  
];

export default routes;
