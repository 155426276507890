import produce from "immer";
import {
  GET_CART_SUCCESS,
  GET_CART,
  DELETE_SUCCESS,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  GET_QUOTE_LiST_SUCCESS,
  GET_QUOTE_BY_ID_SUCCESS,
  GET_QUOTE_SUCCESS,
  GET_QUOTE_FAILURE,
  GET_QUOTE_FALSE
} from "../actions/cartAction";
const initialState = {
  cartId: undefined,
  items: [],
  subTotal: 0.0,
  totalAmount: 0.0,
  showAlert: false,
  quoteList: undefined,
  quoteById: undefined,
  getQuoteSuccess: false,
  getQuoteFailure: false,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_PRODUCT":
      {
        const { items } = action.payload;
        return produce(state, (draft) => {
          draft.items = items;
        });
      }
      break;

    case "UPDATE_PRODUCT":
      return {
        ...state,
        products: state.products.map((product) => {
          if (product.id === action.payload.id) {
            return {
              ...product,
              ...action.payload,
            };
          }
          return product;
        }),
      };

    case "DELETE_PRODUCT":
      return {
        ...state,
        products: state.products.filter(
          (product) => product.id !== action.payload.id
        ),
      };
      break;

    case GET_CART:
      {
        return produce(state, (draft) => {
          draft.items = [];
          draft.cartId = undefined;
          draft.subTotal = 0.0;
          draft.totalAmount = 0.0;
        });
      }
      break;

    case GET_CART_SUCCESS:
    case DELETE_SUCCESS:
    case ADD_TO_CART_SUCCESS:
      {
        const { items, cartId, total } = action.payload;

        return produce(state, (draft) => {
          draft.items = items;
          draft.subTotal = total;
          draft.totalAmount = total;
          draft.showAlert = true;
        });
      }
      break;

      case GET_QUOTE_FALSE:{
        return produce(state, (draft) => {
          draft.getQuoteSuccess = false;
          
        });
      }
      break;
  case GET_QUOTE_SUCCESS:{
    return produce(state, (draft) => {
      draft.getQuoteSuccess = true;
      
    });
  }
  break;
  case GET_QUOTE_FAILURE:{
    return produce(state, (draft) => {
      draft.getQuoteFailure = true;
      
    });
  }
  break;
    case ADD_TO_CART_FAILURE: {
      return produce(state, (draft) => {
        draft.showAlert = false;
      });
    }
    break;
    case GET_QUOTE_LiST_SUCCESS :{
      return produce(state, (draft) => {
        draft.quoteList = action.payload;
        console.log("list_quote", draft.quoteList)
      });
    }
    break;
    case GET_QUOTE_BY_ID_SUCCESS:{
      return produce(state, (draft) => {
        draft.quoteById = action.payload;
        // console.log("list_quote", draft.quoteById)
      });
    }
    default:
      return state;
  }
};
export default cartReducer;
