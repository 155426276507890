import initService from "services/initService";
import { fetchAboutUs, fetchBanners, fetchCustomerDetails, fetchGreetings, fetchNews, fetchPolicy } from "./contentAction";
import { fetchCategories, fetchRecentProducts } from "./catalogAction";
import { fetchPaymentDetails } from "./paymentAction";

export const INITIALIZE = "@init/initialize";
export const INITIALIZE_SUCCESS = "@init/initialize-success";
export const INITIALIZE_FAILURE = "@init/initialize-failure";

export const INITIALIZE_AUTH = "@init/initialize-auth";
export const INITIALIZE_AUTH_SUCCESS = "@init/initialize-auth-success";
export const INITIALIZE_AUTH_FAILURE = "@init/initialize-auth-failure";


export function initialize() {
    return async (dispatch) => {
        try {
            dispatch({ type: INITIALIZE });
            dispatch(fetchCustomerDetails());
            dispatch(fetchPaymentDetails());
            dispatch(fetchBanners());
            dispatch(fetchPolicy());
            dispatch(fetchNews());
            dispatch(fetchGreetings());
            dispatch(fetchAboutUs());
            dispatch(fetchCategories());
            dispatch(fetchRecentProducts());

            // Fetch Data from Server without sign in (eg master data)
            const payload = await initService.initialize();

            dispatch({
                type: INITIALIZE_SUCCESS, payload
            });
        } catch (error) {
            dispatch({
                type: INITIALIZE_AUTH_FAILURE
            });

        }
    }
}


export function initializeAuth() {
    return async (dispatch) => {
        try {

            // All the reducers to listen to this event and re-initialize local data
            dispatch({ type: INITIALIZE_AUTH });

            // Initialize User Information on successful login
            //dispatch(fetchUserProfile());

            // dispatch({
            //     type: INITIALIZE_AUTH_SUCCESS, payload
            // });
        } catch (error) {
            dispatch({
                type: INITIALIZE_AUTH_FAILURE
            });
            console.log("Initialize Error:", error);
        }


    }
}
