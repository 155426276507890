import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Paper,
} from "@mui/material";
import AdditionalFeatures from "./AdditionalFeatures";

function ProductDetails({ product, setAdditionalFeatures, additionalFeatures, setVariationsList }) {
  return (
    <>
      {product?.additionalFeatures?.length > 0 ? (
        <Grid container spacing={2} key={product?.title} sx={{ mb: 3 }}>
          <Grid item md={12} sm={12}>
            <Typography gutterBottom variant="subtitle1" sx={{ ml: 2 }}>
              <b>Additional Features</b>
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              ml: 2,
            }}
          >
           
            <AdditionalFeatures product={product} setAdditionalFeatures={setAdditionalFeatures} setVariationsList={setVariationsList}/>
          </Grid>

         
        </Grid>
      ) : (
        ""
      )}
    </>
  );
}
export default ProductDetails;
