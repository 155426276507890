import { IMAGE_BASE_URL } from "config";

class Category {
    constructor(info) {
        if (info) {
            this.name = info.name;
            this.categoryId = info.categoryId;
            this.parentId = info.parentId;
            this.imageUrl = info.categoryImage ? IMAGE_BASE_URL + "/" + info.categoryImage : undefined;
            this.children = info.children; 
        }
    }
}

class CategoryList {
    constructor(info) {
        this.children = [];
        info.map((item) => {
            const category = new Category(item);
            this.children.push(category);
        });
    }
};

class ProductDetails {
    constructor(info) {

        if (info) {

            this.productId = info.productId;
            this.name = info.productName;
            this.title = info.header;
            this.sku = info.productSKU;
            this.brand = info.brandName;
            this.make = info.make;

            this.price = info.price;
            this.specialPrice = info.price;

            this.metrics = info.metrics;
            this.availableStock = info.availableStock;

            this.baseImage = info.baseImageUrl ? IMAGE_BASE_URL + "/" + info.baseImageUrl : undefined;
            this.images = info.images; // TODO_SP : add the image path to each item 
            this.categoryIds = info.categoryId;
            this.categoryNames = info.categoryNames;

            this.additionalFeatures = info.additionalFeatures;

            // "updatedAt": "2023-06-22T09:53:07.962Z", TODO_SP should this be used for latest or will it have flag
        }
    }
}


class ProductList {
    constructor(info) {
        this.list = [];
        info.map((item) => {
            const product = new ProductDetails(item);
            this.list.push(product);
        });
    }
};




export default { Category };
export { Category, CategoryList, ProductDetails, ProductList }