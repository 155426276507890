import React, { useState, useCallback, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux"
import { Helmet } from "react-helmet";

import { Main as MainLayout } from "layouts";
import { HeroSection } from "./components";
import { CategorySection } from "views/Home/components";

import { ProductGridView } from "./components";

import { fetchCategoryProducts , fetchCategories} from "actions/catalogAction";
import { IMAGE_BASE_URL } from "config";

function CLP() {
    let { categoryId, categoryName } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [categoryProducts, setCategoryProducts] = useState(null);
    const [categoryImage, setCategoryImage] = useState(null);
    const [subCategories, setSubCategories] = useState(null);

    // console.log("CLP: ",categoryName, categoryId);
    if(!categoryName) {
        navigate("home");
    }

    const getCategoryProducts = useCallback(async () => {
        const products = await dispatch(fetchCategoryProducts(categoryName));
        setCategoryProducts(products);
        // console.log("getCategoryProducts: ", categoryProducts);
        if(products?.length){
            setCategoryImage( products[0].baseImage)
        }
      });

      const getCategories = useCallback(async () => {
        const subCategories = await dispatch(fetchCategories(categoryId));
        setSubCategories(subCategories?.children);
        // console.log("subCategories", subCategories);
        
      });


    useEffect(() => {

    }, [categoryProducts, subCategories]);

    useEffect(()=>{
        // console.log("CLP: categoryName ", categoryName);  
        getCategories();
        getCategoryProducts();
    },[categoryName]);



    return (
        <MainLayout>
            <Helmet>
                <title>CLP</title>
            </Helmet>
            <Grid container>
                <Grid item md={12} sm={12}>
                    <HeroSection title={categoryName} heroImage={categoryImage} />
                </Grid>
                <Grid item xs={12} mt={2}>
                    <CategorySection categories={subCategories} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12} mt={3} mb={3}>
                    <ProductGridView products={categoryProducts} />
                </Grid>

                
            </Grid>
        </MainLayout>

    );
}

export default CLP;
