export const light = {
  alternate: {
    main: '#f7faff',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',
  primary: {
    main: '#1078BD',    //'#377dff',
    light: '#FED8E9',
    dark: '#9D064A',    // TODO_SP : Need to check if it is needed
    contrastText: '#fff',
  },
  secondary: {
    light: '#7209b7', //'#ffb74d',
    main: '#DEB1FB', //'#f9b934',
    dark: '#480675',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#0000000', // Black Text
    secondary: '#707070', // Grey Text
    black: '#000000',
    grey: '#707070',
  },
  button: {
    primary: '#3963AB', // Black Text
    secondary: '#47B59E', // Grey Text
  },
  background: {
    paper: '#ffffff',
    default: '#ffffff',
    level2: '#f5f5f5',
    level1: '#ffffff',
  },
};

export const dark = {
  alternate: {
    main: '#560BAD',
    dark: '#151a30',
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: '#fff',
  },
  mode: 'dark',
  primary: {
    main: '#1078BD',
    light: '#2196f3',
    dark: '#0d47a1',
    contrastText: '#fff',
  },
  secondary: {
    light: '#FFEA41',
    main: '#FFE102',
    dark: '#DBBE01',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#560BAD',
    secondary: '#AEB0B4',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#222B45',
    default: '#222B45',
    level2: '#333',
    level1: '#2D3748',
  },
};
