import CartService from "services/cartService";

export const DELETE = "@init/delete";
export const DELETE_SUCCESS = "@init/delete-success";
export const DELETE_FAILURE = "@init/delete-failure";



export const ADD_TO_CART = "@cart/add-to-cart";
export const ADD_TO_CART_SUCCESS = "@cart/add-to-cart-success";
export const ADD_TO_CART_FAILURE = "@cart/add-to-cart-failure";

export const GET_CART = "@cart/get-cart";
export const GET_CART_SUCCESS = "@cart/get-cart-success";
export const GET_CART_FAILURE = "@cart/get-cart-failure";

export const GET_QUOTE = "@quote/get-quote";
export const GET_QUOTE_SUCCESS = "@quote/get-quote-success";
export const GET_QUOTE_FAILURE = "@quote/get-quote-failure";

export const GET_QUOTE_LiST = "@quote/get-quote-list";
export const GET_QUOTE_LiST_SUCCESS = "@quote/get-quote-list-success";
export const GET_QUOTE_LiST_FAILURE = "@quote/get-quote-list-failure";

export const GET_QUOTE_BY_ID = "@quote/get-quote-by-id";
export const GET_QUOTE_BY_ID_SUCCESS = "@quote/get-quote-by-id-success";
export const GET_QUOTE_BY_ID_FAILURE = "@quote/get-quote-by-id-failure";

export const GET_QUOTE_FALSE = "@quote/get-quote-false";

export function addToCartAction(productId, quantity, description, productVariantId, variations) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ADD_TO_CART,
      });

      const cart = await CartService.addToCart(
        productId, quantity, description, productVariantId, variations
      );

      dispatch({
        type: ADD_TO_CART_SUCCESS,
        payload: cart,
      });
    } catch (error) {
      dispatch({
        type: ADD_TO_CART_FAILURE,
      });
      console.log(error);
    }
  };
}


export function getCartAction() {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CART });

      const payload = await CartService.getCart();

      dispatch({
          type: GET_CART_SUCCESS,
          payload : payload
      });
      console.log("get card action",payload)
  } catch (error) {
      dispatch({
          type: GET_CART_FAILURE
      });
      console.log("Initialize Error:", error);
  }
  };
}

export function cartItemDeleteAction(productId) {

  return async (dispatch) => {
    try {
      dispatch({ type: DELETE });

      // Fetch Data from Server without sign in (eg master data)
      const payload = await CartService.deleteCart(productId);

      dispatch({
          type: DELETE_SUCCESS, payload
      });
      getCartAction()
  } catch (error) {
      dispatch({
          type: DELETE_FAILURE
      });
      console.log("Initialize Error:", error);
  }
  };
}

export function getQuotationByUser(items,
  shippingAddress,
  billingAddress,
  businessName,
  gstin) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_QUOTE });

      const payload = await CartService.getQuote(items,
        shippingAddress,
        billingAddress,
        businessName,
        gstin);

      dispatch({
          type: GET_QUOTE_SUCCESS, 
          payload : payload
      });

  } catch (error) {
      dispatch({
          type: GET_QUOTE_FAILURE
      });
      console.log("Initialize Error:", error);
  }
  };
}

  
export function fetchQuotationListAction() {
  
  return async (dispatch) => {
    try {
      dispatch({ type: GET_QUOTE_LiST });

      const payload = await CartService.getQuoteList();

      dispatch({
          type: GET_QUOTE_LiST_SUCCESS, 
          payload : payload
      });

  } catch (error) {
      dispatch({
          type: GET_QUOTE_LiST_FAILURE
      });
      console.log("Initialize Error:", error);
  }
  };
}

export function getQuotationWithIdAction(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_QUOTE_BY_ID });

      const payload = await CartService.getQuoteById(id);

      dispatch({
          type: GET_QUOTE_BY_ID_SUCCESS, 
          payload : payload
      });

  } catch (error) {
      dispatch({
          type: GET_QUOTE_BY_ID_FAILURE
      });
      console.log("Initialize Error:", error);
  }
  };
}


export function getQuoteSuccessFalseAction(){
  return async (dispatch) =>{
    try{
      dispatch({ type: GET_QUOTE_FALSE });
    }catch(error){

    }
  }
}