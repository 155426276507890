import produce from "immer";
import {
  INSTANT_PAYMENT_SUCCESS,
  MY_ORDER_SUCCESS
} from "../actions/subscribeAction";

import {
  PLACE_ORDER_SUCCESS
} from "../actions/paymentAction";



import {
  FETCH_CUSTOMER_DETAILS_SUCCESS,
} from "actions/contentAction";
import { FETCH_PAYMENT_DETAILS_SUCCESS, PAYMENT_STATUS_SUCCESS } from "actions/paymentAction";
const initialState = {

  paymentGateway: undefined,
  paymentMode: undefined,
  paymentDetails: undefined,

  ccavenueData: undefined,

  orderData: [],
  redirectOnPaymentSuccess: false,
  myOrders: [],
  phonePeSuccess:{}
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_CUSTOMER_DETAILS_SUCCESS: {
      const { paymentGateway } = action.payload?.customerDetails;
      console.log("paymentReducer: paymentGateway", paymentGateway);
      return produce(state, (draft) => {
        // draft.paymentGateway = paymentGateway;
      });
    }
      break;
  
    case FETCH_PAYMENT_DETAILS_SUCCESS: {
      const { paymentDetails } = action.payload;

      console.log("paymentReducer: payload on details", action.payload, paymentDetails);

      
      localStorage.setItem("gatewayPayKeyId", paymentDetails?.paymentGWPayKeyId);
      localStorage.setItem("signature", paymentDetails?.paymentGWPayKeySecret)
      localStorage.setItem("paymentServiceUrl", paymentDetails?.paymentServiceUrl)


      return produce(state, (draft) => {
        draft.paymentGateway = paymentDetails?.paymentGateway;     
        draft.paymentMode = paymentDetails?.paymentMode;
        draft.paymentDetails = paymentDetails;
      });


    }




    case INSTANT_PAYMENT_SUCCESS:
      {
        return produce(state, (draft) => {
          draft.redirectOnPaymentSuccess = true;
        });
      }
      break;

    case PLACE_ORDER_SUCCESS:
      {
        
        const orderData = action.payload;
        console.log("paymentReducer: PLACE_ORDER_SUCCESS", orderData);

        return produce(state, (draft) => {
          draft.orderData = orderData;
          // console.log("orderData=======>>>>>>>>", items.data.order.paymentOrderRef.X_VERIFY);
          // console.log("orderData=======>>>>>>>>", items.data.order.paymentOrderRef.id);
          sessionStorage.setItem("X_VERIFY", JSON.stringify(orderData?.data?.order?.paymentOrderRef?.X_VERIFY));
          sessionStorage.setItem("PhonePe-ID", JSON.stringify(orderData?.data?.order?.paymentOrderRef?.id));

          draft.ccavenueData = {
            formbody : orderData?.order?.paymentOrderRef?.formbody,
            accessCode: orderData?.order?.paymentOrderRef?.accessCode,
            encodedRequest: orderData?.order?.paymentOrderRef?.encodedRequest,
          };



        });
      }
      break;

    case MY_ORDER_SUCCESS:
      {
        const items = action.payload;

        return produce(state, (draft) => {
          draft.myOrders = items ? items : [];
          console.log("my orders/////111111/", draft.myOrders);
        });
      }
      break;
    case PAYMENT_STATUS_SUCCESS : {
      const phonePeSuccessData = action.payload;
      return produce(state, (draft) => {
        draft.phonePeSuccess = phonePeSuccessData ;
        //console.log("phonePeSuccessData", phonePeSuccessData);
      });
    }
break;
    default:
      return state;
  }
};
export default paymentReducer;
