import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Box,
  Button,
  Typography,
  Grid,
  CheckBox,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
} from "@mui/material";
import { Tune, Refresh } from "@mui/icons-material";
import SearchFilterCard from "./SearchFilterCard";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  onFacetSelection,
  setPriceInReducer,
} from "../../../actions/searchAction";
import { useDispatch, useSelector } from "react-redux";

const SearchFilter = ({
  facets,
  facetSelection,
  onClearAll,
  onCloseFilter,
  minPrise,
  maxPrise,
}) => {
  // console.log("SearchFilter : facets", facets);
  const price = useSelector((state) => state?.search?.rangeSliderPrice);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  const [priceRange, setPriceRange] = useState([price[0], price[1]]);

  const dispatch = useDispatch();

  const applyRangeFilter = () => {
    dispatch(onFacetSelection("", priceRange[0], priceRange[1]));
  };
  return (
    <Card>
      <Grid sm={12} md={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            alignItems: "center",
            //   boxShadow: "0 2px 2px 0",
            borderBottom: "3px solid #3963AB",
          }}
        >
          <Box>
            {isMobileView ? (
              <Box onClick={onCloseFilter}>
              <Button
                variant="contained"
                // onClick={onCloseFilter}
                onClick={
                  applyRangeFilter
                  }
                // sx={{ color: "black" }}
                // onClick={filterResult}
                startIcon={<Tune  />}
              >
                Apply Filter 
              </Button>
              </Box>
            ) : (
              <Button
                variant="contained"
                size="small"
                onClick={applyRangeFilter}
              >
                Apply Filter
              </Button>
            )}
          </Box>

          <Box>
            <Button
              variant="contained"
              onClick={onClearAll}
              // sx={{ color: "black" }}
              // onClick={resetFilter}
              endIcon={<Refresh />}
            >
              Reset Filters
            </Button>
          </Box>
        </Box>
        {facets?.map((item) => (
          <>
            <Box
              sx={{
                borderBottom: "2px solid #3963AB",
                // ml: -4,
              }}
            >
              <SearchFilterCard
                title={item.title}
                facets={item.facets}
                onFacetSelection={facetSelection}
                applyRangeFilter={applyRangeFilter}
                priceRange={priceRange}
                setPriceRange={setPriceRange}
                minPrise={minPrise}
                maxPrise={maxPrise}
              />
            </Box>
          </>
        ))}
      </Grid>
    </Card>
  );
};

SearchFilter.propType = {
  facets: PropTypes.array,
  facetSelection: PropTypes.func,
  onClearAll: PropTypes.func,
  onCloseFilter: PropTypes.func,
};

export default SearchFilter;
