import {
  IMAGE_BASE_URL,
} from "config";



export function processSearchResults(inSearchList) {
  const results = [];

  if (inSearchList) {
    inSearchList.map((item) => {
      const info = item?._source;

      let searchItem = {
        productId: info?.productId,
        productName: info?.productName,
        productSku: info?.productSKU,
        price: info?.price,
        uom: info?.metrics,
        images: info?.images,
        customerId: info?.customerId,
        categoryNames: info?.categoryNames,
        categoryId: info?.categoryId,
        brandName: info?.brandName,
        baseImage: IMAGE_BASE_URL + '/' +info?.baseImageUrl,
        availableStock: info?.availableStock,
        features: info?.additionalFeatures, 
      };
      results.push(searchItem);
    });
  }

  return results;
}

function processFacets(inFacets) {
  const facets = [];

  inFacets.map((item) => {
    facets.push({label: item.key, value: item.doc_count});
  })

  // console.log("processFacets: ", facets);

  return facets;
}

export function processSearchFacets(inFacets) {
  const facets = [];

  if (inFacets) {

    if(inFacets?.brandName){
      facets.push({title: "Brand Name", facets: processFacets(inFacets?.brandName?.buckets)})
    }

    if(inFacets?.color){
      facets.push({title: "Colour", facets: processFacets(inFacets?.color?.buckets)})
    }

  }

  return facets;

}
