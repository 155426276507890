import React, { useEffect, useState } from "react";
import { PDFViewer, Image } from "@react-pdf/renderer";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import axios from "utils/axios";
import { Grid } from "@mui/material";
import { GET_ORDER_DETAILS } from "config/services";
import { Main as Main } from "layouts";
import { dateFormatter } from "utils/formatUtils";
import {moneyFormatterPDF } from "../../utils/formatUtils";
import { useSelector } from "react-redux";
// Create styles
const styles = StyleSheet.create({
  page: {
    // fontFamily: "Cambria Regular",
    fontSize: 12,
    backgroundColor: "white",
    height: "500px",
    width: "800px",
  },
  thankU: {
    fontSize: 12,
    color: "#1078bd",
  },
  text: {
    marginTop: "3px",
  },
  section: {
    padding: "10px",
    // height: "100px",
    height: "auto",
    flexGrow: 5,
    borderBottom: "0.2px solid gray",
    backgroundColor: "white",
    paddingTop: "10px",
  },
  OrderIdSection: {
    padding: "10px",
    // height: "100px",
    width: "80%",
    height: "auto",
    flexGrow: 1,
    borderBottom: "0.2px solid gray",
    backgroundColor: "white",
    paddingTop: "5px",
    flexDirection: "end",
  },
  addressSection: {
    flexDirection: "row",
    marginTop: "20px",
    padding: "5px",
  },
  subTotalSection: {
    flexDirection: "row",
    justifyContent: "flex-end",
    // height: "100px",
    height: "auto",
    flexGrow: 1,
    border: "0.2px solid gray",
    backgroundColor: "white",
  },
  subTotalInnerSection: {
    padding: 15,
  },
  title: {
    fontSize: 11,
    fontWeight: 900,
  },
  itemListHead: {
    flexDirection: "row",
    backgroundColor: "#1078bd",
    color: "white",
  },
  itemList: {
    flexDirection: "row",
  },
  id: {
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
    width: "10%",
  },
  name: {
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
    width: "30%",
  },
  sku: {
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
    width: "20%",
  },
  prise: {
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
    width: "20%",
  },
  quantity: {
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
    width: "20%",
  },
  total: {
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
    width: "20%",
  },
  table: {
    backgroundColor: "white",
    marginTop: "20px",
  },
  thankyou: {
    flexDirection: "row",
    justifyContent: "center",
    borderTop: "1px solid gray",
    paddingTop: "5px",
    marginTop: "10px",
  },
  invoiceTitle: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#1078bd",
    padding: "5px",
  },
  invoiceT: {
    fontSize: "18px",
    color: "white",
    letterSpacing: "5px",
    marginTop: "10px",
  },
  image: {
    width: "50%",
  },
  imageView: {
    width: "50%",
    height: "20%",
    padding: "0px",
  },
  nameText: {
    marginTop: "10px",
  },
  addressBillShipTo: {
    flexDirection: "row",
  },
  bill: {
    width: "50%",
  },
  ship: {
    width: "50%",
  },

  title: {
    backgroundColor: "#1078bd",
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    letterSpacing: "5px",
    fontSize: "18px",
    textTransform: "uppercase",
    color: "white",
    flexDirection: "row",
  },
  main: {
    flexDirection: "row",
    marginTop: "10px",
  },
  main1: {
    width: "70%",
  },
  main2: {
    width: "30%",
  },
  businessView: {
    width: "100%",
    marginTop: "35px",
    borderBottom: "1px solid gray",
    paddingBottom: "10px",
  },
  mainPage: {
    padding: "10px",
  },
  subtotal:{
    flexDirection: "row",
    marginTop: "40px",
    width:'100%'
  },
  subtotal1:{
    width: "60%",
  }
  ,
  subtotal2:{
    width: "40%",
  },
  subTname:{
    width:'100%',
    flexDirection: "row",
    padding: 10,
    border: "0.2px solid black",
  }
});

function OrderPDF () {
  let params = useParams();

  useEffect(() => {}, []);

  const columns = [
    { field: "_id", headerName: "Order Id", width: 90 },
    {
      field: "productName",
      headerName: "Product Name",
      width: 150,
      editable: true,
    },
    {
      field: "productSKU",
      headerName: "Product SKU",
      width: 150,
      editable: true,
    },
    {
      field: "price",
      headerName: "price",
      width: 150,
      editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
      editable: true,
    },
    {
      field: "total",
      headerName: "Total",
      width: 150,
      editable: true,
    },
  ];

  // const orderDetails = useSelector((state) => state.invoice.invoicesList);
  const app = useSelector((state) => state.app);
  const appLogo = app.logoUrl;
  const myImage = appLogo;
  const [orderDetails, setOrderDetails] = useState("");
  const [productDetails, setProductDetails] = useState("");
  const productDetailsData = productDetails ? productDetails : [];

  //console.log("productDetailsData", orderDetails?.billTo?.address?.name);

  const businessName = useSelector((state) => state.app);
  const businessAddress = useSelector((state) => state.cms);
  console.log("businessAddress", businessAddress)
  

  function fetchOrderDetail(orderId) {
    axios.get(`${GET_ORDER_DETAILS}/${orderId}`).then((response) => {
      if (response?.data?.data) {
        setOrderDetails(response?.data?.data);
        setProductDetails(response?.data?.data?.items);
      } else {
        setOrderDetails([]);
      }
    });
  }

  useEffect(()=>{
    fetchOrderDetail(params.orderId)
  },[])
  const MyDocument = () => (
    <Document>
      {/* <Page size="A4" style={styles.page}>
        <View style={styles.invoiceTitle}>
          <Text style={styles.invoiceT}>ORDER</Text>
        </View>

        <View style={styles.addressSection}>
          <View style={styles.section}>
            <Text style={styles.text}>
              Business Name : {businessName?.appName}
            </Text>
            <Text style={styles.text}>
              Registered Address : {businessAddress?.contact?.registeredAddress}
            </Text>
            <Text style={styles.text}>
              Mobile : {businessAddress?.contact?.mobile}
            </Text>
            <Text style={styles.text}>GSTIN : {businessName?.gstin}</Text>

            <Text style={styles.text}>
              Name : {orderDetails?.billTo?.address?.name}
            </Text>

            <Text style={{ marginTop: "15px" }}>
              Bill To : {orderDetails?.billTo?.address?.addressLine1}
            </Text>

            <Text style={styles.text}>
              {orderDetails?.billTo?.address?.addressLine2},{" "}
              {orderDetails?.billTo?.address?.city},{" "}
              {orderDetails?.billTo?.address?.country}
            </Text>

            <Text style={styles.text}>
              {orderDetails?.billTo?.address?.pinCode}
            </Text>
            <Text style={styles.text}>
              {orderDetails?.billTo?.address?.email}
            </Text>
            <Text style={styles.text}>
              {orderDetails?.billTo?.address?.mobile}
            </Text>

            <Text style={{ marginTop: "15px" }}>
              Ship To : {orderDetails?.shipTo?.address?.addressLine1}{" "}
            </Text>

            <Text style={styles.text}>
              {orderDetails?.shipTo?.address?.addressLine2},{" "}
              {orderDetails?.shipTo?.address?.city},{" "}
              {orderDetails?.shipTo?.address?.country}
            </Text>

            <Text style={styles.text}>
              {orderDetails?.shipTo?.address?.pinCode}{" "}
            </Text>
            <Text style={styles.text}>
              {orderDetails?.shipTo?.address?.email}{" "}
            </Text>
            <Text style={styles.text}>
              {orderDetails?.shipTo?.address?.mobile}
            </Text>
          </View>

          <View style={styles.OrderIdSection}>
            <Text style={styles.text}>OrderId : {orderDetails?.orderId}</Text>
            <Text style={styles.text}>
              Order Date : {dateFormatter(orderDetails?.updatedAt)}
            </Text>
            <Text style={styles.text}>
              Order State : {orderDetails?.status}
            </Text>

            <Text style={{ marginTop: "15px" }}>
              Payment Status : {orderDetails?.status}
            </Text>
            <Text style={styles.text}>
              Payment Method : {orderDetails?.paymentGateway}
            </Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.itemListHead}>
            <Text style={styles.id}>id</Text>
            <Text style={styles.name}>Product Name</Text>
            <Text style={styles.sku}>Product SKU</Text>
            <Text style={styles.prise}>Price</Text>
            <Text style={styles.quantity}>Quantity</Text>
            <Text style={styles.total}>Total</Text>
          </View>
          {productDetailsData?.map((dataList) => (
            <View style={styles.itemList}>
              <View style={styles.id}>
                <Text style={styles.text}>{dataList._id.slice(0, 5)}...</Text>
              </View>
              <Text style={styles.name}>{dataList.productName}</Text>
              <Text style={styles.sku}>{dataList.productSKU}</Text>
              <Text style={styles.prise}>Rs.{moneyFormatterPDF(dataList.price)}</Text>
              <Text style={styles.quantity}>{dataList.quantity}</Text>
              <Text style={styles.total}>Rs.{moneyFormatterPDF(dataList.total)}</Text>
            </View>
          ))}
        </View>

        <View style={styles.addressSection}>
          <View style={styles.section}></View>

          <View style={styles.OrderIdSection}>
            <Text style={styles.text}>
              Sub Total : Rs.{moneyFormatterPDF(orderDetails?.orderAmount)}
            </Text>
            <Text style={styles.text}>
              CGST : Rs.{moneyFormatterPDF(orderDetails?.gst?.cgst)}
            </Text>
            <Text style={styles.text}>
              IGST : Rs.{moneyFormatterPDF(orderDetails?.gst?.igst)}
            </Text>

            <Text style={styles.text}>SGST : {orderDetails?.gst?.sgst}</Text>
            <Text style={styles.text}>
              Total Amount : Rs.{moneyFormatterPDF(orderDetails?.totalAmount)}
            </Text>
          </View>
        </View>

        <View style={styles.thankyou}>
          <Text style={styles.thankU}>Thank you for your Order</Text>
        </View>
      </Page> */}



      <Page size="A4" style={styles.page}>
          <View style={styles.mainPage}>
            <View style={styles.title}>
              <Text style={styles.textTitle}>ORDER</Text>
            </View>

            <View style={styles.main}>
              <View style={styles.main1}>
                <Image src={myImage} style={styles.image} />
              </View>
              <View style={styles.main2}>
                <Text style={styles.text}>OrderId : {orderDetails?.orderId}</Text>
                <Text style={styles.text}>
                  Order Date : {dateFormatter(orderDetails?.updatedAt)}
                </Text>
                {/* <Text style={styles.text}>
              Order State : {items?.status}
            </Text> */}

                {/* <Text style={{ marginTop: "15px" }}>
              Payment Status : {items?.status}
            </Text> */}
                <Text style={styles.text}>
                Payment Method : {orderDetails?.paymentGateway}
                </Text>
              </View>
            </View>

            <View style={styles.businessView}>
              <Text style={{ fontWeight: "800px", fontSize: "12px" }}>
                {businessName?.appName},
              </Text>
              <Text style={styles.text}>
                {businessAddress?.contact?.registeredAddress},
              </Text>
              <Text style={styles.text}>
                {businessAddress?.contact?.mobile},
              </Text>
              <Text style={styles.text}>{businessName?.gstin}</Text>
            </View>

            <View style={styles.main}>
              <View style={styles.bill}>
                
                <Text
                  style={{
                    marginTop: "5px",
                    fontWeight: "800px",
                    fontSize: "12px",
                  }}
                >
                  Bill To :
                </Text>
                <Text  style={styles.text}>
                   {orderDetails?.billTo?.address?.name}
                </Text>
                <Text style={{ marginTop: "5px" }}>
                  {orderDetails?.billTo?.address?.addressLine1}
                </Text>

                <Text style={styles.text}>
                  {orderDetails?.billTo?.address?.addressLine2},{" "}
                  {orderDetails?.billTo?.address?.city},{" "}
                  {orderDetails?.billTo?.address?.country}
                </Text>

                <Text style={styles.text}>
                  {orderDetails?.billTo?.address?.pinCode}
                </Text>
                <Text style={styles.text}>{orderDetails?.billTo?.address?.email}</Text>
                <Text style={styles.text}>
                  {orderDetails?.billTo?.address?.mobile}
                </Text>
              </View>
              <View style={styles.ship}>
                <Text
                  style={{
                    marginTop: "5px",
                    fontWeight: "800px",
                    fontSize: "12px",
                  }}
                >
                  Ship To :
                </Text>
                <Text style={{ marginTop: "5px" }}>
                  {orderDetails?.shipTo?.address?.addressLine1}{" "}
                </Text>

                <Text style={styles.text}>
                  {orderDetails?.shipTo?.address?.addressLine2},{" "}
                  {orderDetails?.shipTo?.address?.city},{" "}
                  {orderDetails?.shipTo?.address?.country}
                </Text>

                <Text style={styles.text}>
                  {orderDetails?.shipTo?.address?.pinCode}{" "}
                </Text>
                <Text style={styles.text}>
                  {orderDetails?.shipTo?.address?.email}{" "}
                </Text>
                <Text style={styles.text}>
                  {orderDetails?.shipTo?.address?.mobile}
                </Text>
              </View>
            </View>

            <View style={styles.table}>
              <View style={styles.itemListHead}>
                <Text style={styles.id}>ID</Text>
                <Text style={styles.name}>ITEM</Text>
                <Text style={styles.sku}>HSN</Text>
                <Text style={styles.prise}>PRICE</Text>
                <Text style={styles.quantity}> QUANTITY</Text>
                <Text style={styles.total}>TOTAL</Text>
              </View>

              {orderDetails?.items?.map((dataList, index) => (
                <View style={styles.itemList}>
                  <View style={styles.id}>
                    <Text style={styles.text}>{index + 1}</Text>
                  </View>

                  <Text style={styles.name}>{dataList?.productName}</Text>
                  <Text style={styles.sku}>{dataList?.productSKU}</Text>
                  <Text style={styles.prise}>
                    Rs.{moneyFormatterPDF(dataList?.price)}
                  </Text>
                  <Text style={styles.quantity}>{dataList?.quantity}</Text>
                  <Text style={styles.total}>
                    Rs.{moneyFormatterPDF(dataList?.total)}
                  </Text>
                </View>
              ))}
            </View>

            <View style={styles.subtotal}>
              <View style={styles.subtotal1}></View>
              <View style={styles.subtotal2}>
                
                  <View style={styles.itemList}>
                    <Text style={styles.subTname}>Sub Total</Text>
                    <Text style={styles.subTname}>
                      Rs.{moneyFormatterPDF(orderDetails?.paymentDetails?.orderAmount)}
                    </Text>
                  </View>
                  <View style={styles.itemList}>
                    <Text style={styles.subTname}>CGST : </Text>
                    <Text style={styles.subTname}>
                      Rs.{moneyFormatterPDF(orderDetails?.paymentDetails?.gst?.cgst)}
                    </Text>
                  </View>
                  <View style={styles.itemList}>
                    <Text style={styles.subTname}>IGST : </Text>
                    <Text style={styles.subTname}>
                      Rs.{moneyFormatterPDF(orderDetails?.paymentDetails?.gst?.igst)}
                    </Text>
                  </View>
                  <View style={styles.itemList}>
                    <Text style={styles.subTname}>SGST : </Text>
                    <Text style={styles.subTname}>
                      Rs.{moneyFormatterPDF(orderDetails?.paymentDetails?.gst?.sgst)}
                    </Text>
                  </View>
                  <View style={styles.itemList}>
                    <Text style={styles.subTname}>Total Amount : </Text>
                    <Text style={styles.subTname}>
                      Rs.{moneyFormatterPDF(orderDetails?.paymentDetails?.totalAmount)}
                    </Text>
                  </View>
                
              </View>
            </View>

            <View style={styles.thankyou}>
              <Text style={styles.thankU}>Thank you for your Order</Text>
            </View>
          </View>
        </Page>
    </Document>
  );

  return (
    <Main>
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <PDFViewer style={styles.page}>
          <MyDocument />
        </PDFViewer>
      </Grid>
    </Main>
  );
};

export default OrderPDF;
