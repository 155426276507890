import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Typography, Box, Divider, Checkbox } from "@mui/material";
import { Link } from "react-router-dom";
import { Grid, Button, Card } from "@mui/material";
import MiniCartItem from "./MiniCartItem";

import { getCartAction } from "../../actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import {moneyFormatter} from "../../utils/formatUtils";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  placeOrderAction,
} from "../../actions/subscribeAction";
import GetQuote from "views/GetQuote/GetQuote";

const MainCart = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const showQuotation = useSelector((state)=> state.app.showQuotation);
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const cartItems = useSelector((state) => state?.cart?.items);
  console.log("cartItems0", cartItems)
  const subtotal = useSelector((state) => state?.cart?.subTotal);
  const isShowProductPrice = useSelector((state)=> state.app.showProductPrice);
  useEffect(() => {
    try {
      dispatch(getCartAction());
    } catch (error) {
      console.log(error);
    }
  }, []);
  

  useEffect(() => {
    
  }, [cartItems]);

  const centerContact = {
    id: 3,
    name: "SH Service Center",
    email: "center@gmail.com",
    city: "Bangalore",
    state: "Karnataka",
    region: "EAST",
  };
  const shipTo = {
    address: {
      name: "Manju P",
      addressLine1: "BTM layout",
      addressLine2: "madiwala",
      city: "Mysore",
      state: "Karnataka",
      country: "'India'",
      pinCode: "560-089",
      mobile: "1234567867",
      email: "shipTo@test.com",
    },
  };

  const billTo = {
    address: {
      name: "'Neeraj'",
      addressLine1: "'salt lake'",
      addressLine2: "Big bazar",
      city: "Kolkata",
      state: "Chandigarh",
      country: "India",
      pinCode: "560 079",
      mobile: "1231234543",
      email: "billTo@test.com",
    },
  };
  const orderDesc = "Pehala Order";

//  const redirectUrl =  "http://localhost:3000/payment/phonepe/status"
 const redirectUrl =  "http://52.151.197.88:4004/payment/phonepe/status"

  async function handleCheckout() {
    
    try {

      // TODO_SP: to be called once the confirmation to place order

      // dispatch(placeOrderAction(centerContact, shipTo, billTo, orderDesc, redirectUrl));
    } catch (error) {
      console.log("error", error.response.status);
    }
  }
  return (
    <Card sx={{ display: "flex", p: 2 }} elevation={0}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pr: 2,
            borderBottom: "0.1px solid #808080",
          }}
        >
          <Box>
          {isMobileView ? <Typography variant="h5">Shopping Cart</Typography> : <Typography variant="h3">Shopping Cart</Typography> } 
          </Box>
          {/* <Box sx={{ display: "flex" }}>
            <Typography sx={{ mr: 0}}>Price</Typography>
          </Box> */}
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          {cartItems?.map((item, index) => (
            <MiniCartItem item={item} index={index} getCartAction={getCartAction} cartItems={cartItems}/>
          ))}
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{ justifyContent: "center", pr: 0, mb: 2 }}
        >
         {isShowProductPrice && 
          <Typography>
            {/* Subtotal ({Cart?.length} items): <b>1,65,898.00000</b> */}
            Subtotal :
          </Typography>}
          <Button
            fullWidth
            component={Link}
            to="/view-cart"
            sx={{ mt: 5, borderRadius: "5px" }}
            variant="contained"
          >
            View Cart
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            pr: 0,
            mb: 2,
          }}
        >
        {isShowProductPrice && 
          <Typography
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <b>{moneyFormatter(subtotal)}</b>         
             </Typography>}
            <Button
          onClick={handleCheckout}
            component={Link}
            to="/checkout"
            fullWidth
            sx={{ mt: 5, borderRadius: "5px" }}
            variant="contained"
          >
            Checkout
          </Button>
          {showQuotation && 
          <>
          {cartItems == 0 ? "" :
          <Button
          
            component={Link}
            to="/get-quote"
            fullWidth
            sx={{ mt: 5, borderRadius: "5px" }}
            variant="contained"
          >
            Get Quote
          </Button>}
          </>
          }
          
        </Grid>
      </Grid>
    </Card>
  );
};

export default MainCart;
